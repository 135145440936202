import {
  Card,
  CardProps,
  Divider,
  Group,
  Title,
  TitleProps,
} from "@mantine/core";
import React, { PropsWithChildren, ReactNode } from "react";

type Props = {
  title: {
    titleProps?: TitleProps;
    text: string;
  };
  leftSection?: ReactNode;
};

export const TitledCard = ({
  children,
  title,
  leftSection,
  ...cardProps
}: PropsWithChildren<Props> & CardProps) => {
  return (
    <Card withBorder {...cardProps}>
      <Group>
        <Title {...title.titleProps}>{title.text}</Title>
        {leftSection}
      </Group>
      <Divider mb={"md"} />
      {children}
    </Card>
  );
};
