import { useState } from 'react'
import { GetAllPotentialCustomersRequest, GetAllPotentialCustomersResponse } from '../../../Services/PotentialCustomers/potentialCustomerContracts'
import { getAllPotentialCustomers as rest } from '../../../Services/PotentialCustomers/potentialCustomerRest'
import { notifier } from '../../../Utilities/notifier'
import { useSelector } from 'react-redux'
import { RootState } from '../../../Redux/store'
import { NOT_LOGGED_IN_ERROR_RESPONSE } from '../../../Services/Commons/commonResponses'

export const useGetAllPotentialCustomers = () : [
  (request: Omit<GetAllPotentialCustomersRequest, "userToken">) => Promise<void>,
  boolean,
  GetAllPotentialCustomersResponse?,
] => {
  const user = useSelector((state: RootState) => state.userSlice)
  const [response, setResponse] = useState<GetAllPotentialCustomersResponse>()
  const [loading, setLoading] = useState<boolean>(false)

  const getAllPotentialCustomers = async (request: Omit<GetAllPotentialCustomersRequest, "userToken">) => {
    setLoading(true);
    setResponse(undefined);

    if(!user.token)
    {
      setResponse(NOT_LOGGED_IN_ERROR_RESPONSE)
      return
    }

    const response = await rest({
      ...request,
      userToken: user.token
    });
    
    if(response.errors)
      notifier.notifyErrors(response.errors)

    setLoading(false)
    setResponse(response);
  }

  return [getAllPotentialCustomers, loading, response]
}
