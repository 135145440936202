export const BaseRoute = "/";
export const LandingPageRoute = "";
export const LoginPageRoute = "login";
export const AdministrationLoginPageRoute = "administration-login";
export const CustomerAdministrationPageRoute = "CustomerAdministration";
export const AdministrationPageRoute = "administration";
export const EditCardPageRoute = "EditCard";
export const DigitalCardsPageRoute = "digital-cards";
export const DigitalCardsPaymentsPageRoute = "digital-cards-payments";
export const MarketingPageRoute = "Marketing";
export const ErrorPageRoute = "errors";
export const TermsOfUsePageRoute = "terms-of-conditions";
export const PrivacyPolicyPageRoute = "privacy-policy";
export const AboutUsPageRoute = "about-us";
export const ViewCardPageRoute = "@/:company/:card";