import { MantineProvider } from "@mantine/core";
import { PropsWithChildren } from "react";
import { Notifications } from "@mantine/notifications";
import { FontProvider } from "./FontProvider";
import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/carousel/styles.css";
import "@mantine/charts/styles.css";

type Props = {
  theme: any;
};

export const ThemingContext = ({
  theme,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <FontProvider>
      <MantineProvider theme={theme}>
        <Notifications />
        {children}
      </MantineProvider>
    </FontProvider>
  );
};
