import { Box, Button, Flex, Group, HoverCard, Stack, Title } from "@mantine/core";
import { headerLinks } from "../../Organizem/App/headerLinks";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LoginLinks } from "../../Molecules/Links/LoginLinks";
import { Logo } from "../../Atoms/Logo";
import { LandingPageRoute } from "../../App/Routes";
import { ButtonGroupLanguages } from "../../Molecules/Language/ButtonGroupLanguages";
import { useDispatch } from "react-redux";
import { translationActions } from "../../../Redux/features/Translation/translationSlice";

type Props = {};

export const LaptopHeader = (props: Props) => {
  const { t } = useTranslation();
  const dispatcher = useDispatch();
  const logoWidth = 100;

  return (
    <Flex h={"100%"} align={"center"} justify={"space-between"}>
      <Group justify="center">
        <Button
          component={Link}
          to={`/${LandingPageRoute}`}
          h={"5em"}
          variant="transparent"
        >
          <Logo style={{ width: `${logoWidth}px`, height: "auto" }} />
        </Button>
      </Group>
      <Group w={"100%"} justify="center">
        {headerLinks.map((link) => {
          if (link.children)
            return (
              <HoverCard key={link.value}>
                <HoverCard.Target>
                  <Button variant="subtle" component={Link} to={link.value}>
                    <Group wrap="nowrap">{t(link.label as string)}</Group>
                  </Button>
                </HoverCard.Target>
                <HoverCard.Dropdown>
                  <Stack>
                    {link.children.map((l) => (
                      <Button
                        key={l.value}
                        variant="subtle"
                        component={Link}
                        to={l.value}
                      >
                        <Group wrap="nowrap">{t(l.label as string)}</Group>
                      </Button>
                    ))}
                  </Stack>
                </HoverCard.Dropdown>
              </HoverCard>
            );

          return link.label === "logo" ? undefined : (
            <Button
              key={link.value}
              variant="subtle"
              component={Link}
              to={link.value}
            >
              <Group wrap="nowrap">{t(link.label as string)}</Group>
            </Button>
          );
        })}
      </Group>

      <Group justify="center" wrap="nowrap">
        <LoginLinks/>
        <HoverCard>
          <HoverCard.Target>
            <Box>
              <Button variant="transparent">{t("lng")}</Button>
            </Box>
          </HoverCard.Target>
          <HoverCard.Dropdown>
            <Box>
              <ButtonGroupLanguages
                onClick={(key) =>
                  dispatcher(
                    translationActions.changeLanguage({
                      language: key,
                    })
                  )
                }
              />
            </Box>
          </HoverCard.Dropdown>
        </HoverCard>
      </Group>
    </Flex>
  );
};
