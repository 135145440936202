import { PropsWithChildren } from "react";
import { AppShell, useMantineTheme } from "@mantine/core";
import { Header } from "../Organizem/App/Header";
import { Sidebar } from "../Organizem/App/Sidebar";
import { useDisclosure, useHeadroom } from "@mantine/hooks";
import { Footer } from "../Organizem/App/Footer";
import { useLaptopAndAbove } from "../../Hooks/Layout/useTabletAndAbove";

export const PageWithHeader = ({ children }: PropsWithChildren) => {
  const pinned = useHeadroom({ fixedAt: 120 });
  const theme = useMantineTheme();
  const isLaptop = useLaptopAndAbove();
  const [opened, { toggle, close }] = useDisclosure();

  return (
    <AppShell header={{ height: 68, collapsed: !pinned, offset: true }}>
      <AppShell.Header withBorder={false} bg={theme.colors.dark[8]}>
        <Header opened={opened} close={close} toggle={toggle} />
      </AppShell.Header>
      <AppShell.Main>{children}</AppShell.Main>
      <AppShell.Navbar withBorder={false} bg={"#0000"}>
        {!isLaptop && <Sidebar opened={opened} toggle={toggle} />}
      </AppShell.Navbar>
      <AppShell.Footer pos={"static"} bg={theme.colors.dark[9]}>
        <Footer />
      </AppShell.Footer>
    </AppShell>
  );
};
