import {
  Box,
  Card,
  Container,
  Grid,
  LoadingOverlay,
  Tabs,
  Title,
} from "@mantine/core";
import React, { ReactNode, useState } from "react";
import { StatisticsPanel } from "../../Molecules/Panels/StatisticsPanel";
import { ContactsPanel } from "../../Molecules/Panels/ContactsPanel";
import { PaymentTierBody } from "../../Molecules/Panels/PaymentTiers.tsx/PaymentTierBody";
import { CardAdministration } from "../../Organizem/CardAdministration";
import { PotentialCustomerAdministration } from "../../Organizem/PotentialCustomerAdministration";
import { ViewerInteractionDto } from "../../../Services/Application/contracts";
import {
  CustomerDto,
  PaymentTierTypes,
} from "../../../Services/Customers/customerContracts";
import { ClientInteractionTypesEnum } from "../../../Services/Application/sonsoApplicationRest";
import { useTranslation } from "react-i18next";
import { useScrollIntoView } from "@mantine/hooks";
import { PaymentTiersFactory } from "../../Molecules/Panels/PaymentTiers.tsx/PaymentTiersFactory";

const DIGITAL_CARDS_TAB = "digital cards";
const POTENTIAL_CUSTOMER_TAB = "potential customers";

type Props = {
  interactions: ViewerInteractionDto[] | undefined;
  interactionsIsLoading: boolean;
  customer: CustomerDto | undefined;
  potentialCustomerAdministration?: ReactNode
  customerIsLoading: boolean;
};

export const TabletCustomerAdministration = ({
  customer,
  customerIsLoading,
  interactions,
  potentialCustomerAdministration,
  interactionsIsLoading,
}: Props) => {
  const { t } = useTranslation();
  const [shownTab, setShownTab] = useState(DIGITAL_CARDS_TAB);
  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>();

  const customerInteractions = customer?.cards
    .flatMap((c) => c.contacts)
    .map((c) => ({
      ...c,
      sellingStage: {
        name: c.name,
        color: "green",
        value: c.sellingStage,
      },
    }));

  return (
    <Container>
      <LoadingOverlay visible={customerIsLoading} />
      <Title order={2}>{t("customer administration")}</Title>
      <Box mt={"lg"}>
        <StatisticsPanel
          viewersCount={
            interactions?.filter(
              (v) => v.type.value === ClientInteractionTypesEnum.VIEW
            ).length
          }
          interactionsCount={
            interactions?.filter(
              (v) => v.type.value !== ClientInteractionTypesEnum.VIEW
            ).length
          }
        />
      </Box>

      <Box m={"lg"}>
        <PaymentTiersFactory paymentTier={customer?.paymentTier} />
      </Box>

      {/* Body */}
      <Tabs
        variant="default"
        ref={targetRef}
        onChange={(val) => val && setShownTab(val)}
        defaultValue={shownTab}
        value={shownTab}
      >
        <Tabs.List>
          <Tabs.Tab p={"xs"} value={DIGITAL_CARDS_TAB}>
            {t("digital cards")}
          </Tabs.Tab>
          <Tabs.Tab p={"xs"} value={POTENTIAL_CUSTOMER_TAB}>
            {t("potential customers")}
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="digital cards">
          <CardAdministration
            p={0}
            mt={"lg"}
            isLoading={customerIsLoading}
            customer={customer}
            as="customer"
          />
        </Tabs.Panel>

        <Tabs.Panel value="potential customers">
          {potentialCustomerAdministration}
        </Tabs.Panel>
      </Tabs>
    </Container>
  );
};
