import React from "react";
import {
  CardPaymentTierDto,
  PaymentTierTypes,
} from "../../../../Services/Customers/customerContracts";
import { LoadingPaymentTier } from "./Core/LoadingPaymentTier";
import { PaymentTierBody } from "./PaymentTierBody";
import { PaymentTierBadge } from "./Core/PaymentTierBadge";
import { useTranslation } from "react-i18next";
import { Button, Group, Stack, useMantineTheme } from "@mantine/core";
import { Link } from "react-router-dom";
import { DigitalCardsPageRoute, DigitalCardsPaymentsPageRoute } from "../../../App/Routes";

type Props = {
  paymentTier?: CardPaymentTierDto;
};

export const PaymentTiersFactory = ({ paymentTier }: Props) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();

  let element = <></>;
  if (!paymentTier) return <LoadingPaymentTier />;

  switch (paymentTier.value) {
    case PaymentTierTypes.Free:
      element = (
        <PaymentTierBadge
          name={paymentTier.name}
          cardsAllowed={paymentTier.cardsAllowed}
          changesAllowed={paymentTier.changesAllowed}
          gradient1={theme.colors.gray[3]}
        />
      );
      break;
    case PaymentTierTypes.Standard:
      element = (
        <PaymentTierBadge
          name={paymentTier.name}
          gradient1={theme.colors.blue[2]}
          cardsAllowed={paymentTier.cardsAllowed}
          changesAllowed={paymentTier.changesAllowed}
        />
      );
      break;
    case PaymentTierTypes.StandardPlus:
      element = (
        <PaymentTierBadge
          name={paymentTier.name}
          gradient1={theme.colors.blue[7]}
          cardsAllowed={paymentTier.cardsAllowed}
          changesAllowed={paymentTier.changesAllowed}
        />
      );
      break;
    case PaymentTierTypes.Premium:
      element = (
        <PaymentTierBadge
          name={paymentTier.name}
          gradient1={theme.colors.yellow[3]}
          cardsAllowed={paymentTier.cardsAllowed}
          changesAllowed={paymentTier.changesAllowed}
        />
      );
      break;
    case PaymentTierTypes.Deluxe:
      element = (
        <PaymentTierBadge
          name={paymentTier.name}
          gradient1={"violet"}
          changesAllowed={t("unlimited")}
          cardsAllowed={t("unlimited")}
        />
      );
      break;
    default:
      element = <LoadingPaymentTier />;
      break;
  }

  return (
    <Stack gap={0}>
      <Group>
        <Button
          component={Link}
          to={`/${DigitalCardsPageRoute}`}
          variant="subtle"
          p='xs'
        >
          {t("upgrade")} {">"}
        </Button>
      </Group>
      {element}
    </Stack>
  );
};
