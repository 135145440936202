import { useState } from "react";
import {
  GetCustomerByEmailRequest,
  GetCustomerResponse,
} from "../../../Services/Customers/customerContracts";
import { getCustomerByEmail } from "../../../Services/Customers/customerRest";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { NOT_LOGGED_IN_ERROR_RESPONSE } from "../../../Services/Commons/commonResponses";
import { useCustomerSelector } from "../../../Redux/Selectors/useCustomerSelector";

export const useGetCustomerByEmail = (
  as: "customer" | "user"
): [
  (request: Omit<GetCustomerByEmailRequest, 'userToken' | 'customerToken'>) => Promise<void>,
  boolean,
  GetCustomerResponse?
] => {
  const user = useSelector((state: RootState) => state.userSlice);
  const customer = useCustomerSelector();
  const [response, setResponse] = useState<GetCustomerResponse>();
  const [loading, setLoading] = useState<boolean>(false);

  const fetch = async (request: GetCustomerByEmailRequest) => {
    if (as === "user" && !user.token) {
      setResponse(NOT_LOGGED_IN_ERROR_RESPONSE);
      return;
    }

    if (as === "customer" && !customer.token) {
      setResponse(NOT_LOGGED_IN_ERROR_RESPONSE);
      return;
    }

    setLoading(true);
    setResponse(undefined);

    const response = await getCustomerByEmail({
      ...request,
      customerToken: customer.token,
      userToken: user.token
    });

    setLoading(false);
    setResponse(response);
  };

  return [fetch, loading, response];
};
