const english = /^[A-Za-z0-9]*$/;
const email = /^\S+@\S+$/;

export const utils = {
  common: {
    isUndefined: (val?: any) => val === undefined,
    isInRange: (val: number, min: number, max: number) =>
      val >= min && val < max,
    isGreaterThan: (val: number, other: number) => val > other,
    isNumber: (val: number | string) => Number.isInteger(val),
    isLessThan: (val: number, other: number) => val < other,
    containSpecialCharacters: (val: string) =>
      /[$&+,:;=?@#|'<>.^*()%!-]/.test(val),
    isOnlyEnglish: (val: string) => english.test(val),
    isColor: (val: string) => {
      const s = new Option().style;
      s.color = val;
      return s.color !== '';
    },
    isEmail: (val: string) => email.test(val)
  },
};

export const predigates = {
  isLessThan: (other: number) => (val: number) =>
    utils.common.isLessThan(val, other),
  isGreaterThan: (other: number) => (val: number) =>
    utils.common.isGreaterThan(val, other),
};