import {
  Box,
  Button,
  Group,
  MantineColor,
  MantineTheme,
  rem,
  Text,
} from "@mantine/core";
import { SocialLinksProp } from "../../../CardProps";
import {
  FaGlobe,
  FaInstagram,
  FaMap,
  FaPhone,
  FaWhatsapp,
} from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  onLinkClicked?: (val: string) => void
  socialLinks: SocialLinksProp;
  color: MantineColor;
};

export const ShlomiSocialLinks = (props: Props) => {
  const { t } = useTranslation();
  const ButtonLink = (props: { icon: ReactNode; title: string }) => (
    <Box>
      <Button m={0} p={0} w={46} h={46} radius={"50%"} fz={16}>
        {props.icon}
      </Button>
      <Text ta={'center'}>{props.title}</Text>
    </Box>
  );

  return (
    <Group justify="center">
      <ButtonLink icon={<FaInstagram />} title={t("instagram")} />

      <ButtonLink icon={<FaWhatsapp />} title={t("whatsapp")} />

      <ButtonLink icon={<FaGlobe />} title={t("website")} />

      <ButtonLink icon={<IoMdMail />} title={t("email")} />

      <ButtonLink icon={<FaPhone />} title={t("phone number")} />

      <ButtonLink icon={<FaMap />} title={t("location")} />
    </Group>
  );
};
