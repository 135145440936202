import { useState } from 'react'
import { GetAllRecommendationsRequest, GetAllRecommendationsResponse } from '../../../Services/Application/contracts'
import { sonsoApplicationRest } from '../../../Services/Application/sonsoApplicationRest'

export const useGetApplicationRecommendations = () : [
  (request: GetAllRecommendationsRequest) => Promise<void>,
  boolean,
  GetAllRecommendationsResponse?,
] => {
  const [response, setResponse] = useState<GetAllRecommendationsResponse>()
  const [loading, setLoading] = useState<boolean>(false)

  const fetch = async (request: GetAllRecommendationsRequest) => {
    setLoading(true);
    setResponse(undefined);

    const customer = await sonsoApplicationRest.getAllRecommendations(request);

    setLoading(false)
    setResponse(customer);
  }

  return [fetch, loading, response]
}