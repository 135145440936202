import { useLaptopAndAbove } from "../../../Hooks/Layout/useTabletAndAbove";
import { TabletHeader } from "../../Layouts/Header/TabletHeader";
import { LaptopHeader } from "../../Layouts/Header/LaptopHeader";
import { Box } from "@mantine/core";

type Props = {
  opened: boolean;
  toggle: () => void;
  close: () => void;
};

export const Header = (props: Props) => {
  const matches = useLaptopAndAbove();

  return <Box ps={'md'} pe={'md'} h={'100%'}>{matches ? <LaptopHeader /> : <TabletHeader {...props} />}</Box>;
};
