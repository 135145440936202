import { ReactNode, useRef } from "react";
import { CardProps } from "../../../CardProps";
import {
  Box,
  Card,
  Center,
  Container,
  Flex,
  Image,
  Overlay,
  Space,
} from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import Autoplay from "embla-carousel-autoplay";

type Props = {
  card: CardProps;
  metadata?: ReactNode;
  questionsAndAnswers?: ReactNode;
  informativeSections?: ReactNode;
  recommendations?: ReactNode;
  contactForm?: ReactNode;
  mah?: number;
  space?: number;
};

export const DigitalCardTemplate1 = ({ card, space, mah, ...props }: Props) => {
  const autoplay = useRef(Autoplay({ delay: 10_000 }));

  return (
    <Card
      shadow="lg"
      miw={320}
      maw={450}
      mah={mah}
      padding={"sm"}
    >
      {/* Profile Image */}
      <Card.Section>
        <Flex pos={"relative"} align={"center"} justify={"center"} mih={400}>
          <Carousel
            maw={450}
            w={'100%'}
            plugins={[autoplay.current]}
            onMouseEnter={autoplay.current.stop}
            onMouseLeave={autoplay.current.reset}
            withControls={false}
            dir="ltr"
          >
            {card.company?.map((c) => (
              <Carousel.Slide key={c.id}>
                <Image src={c.id}  />
              </Carousel.Slide>
            ))}
          </Carousel>
          <Overlay backgroundOpacity={0.2}>
            <Center pt={100} h={"100%"}>
              <Box
                style={{
                  width: "250px",
                  height: "250px",
                  overflow: "hidden",
                  borderRadius: "50%",
                }}
              >
                <Image src={card.profile.id} />
              </Box>
            </Center>
          </Overlay>
        </Flex>
      </Card.Section>

      <Container>
        {/* Metadata Image */}
        {props.metadata}

        <Space h={"lg"} />

        {/* Questions And Answers Section */}
        {props.questionsAndAnswers}

        <Space h={"lg"} />

        {/* Information Section */}
        {props.informativeSections}

        <Space h={"lg"} />

        {/* Recommendations */}
        {props.recommendations}

        <Space h={"lg"} />

        {props.contactForm}
      </Container>
    </Card>
  );
};
