import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  LoginCustomerResponse,
  UpdateCardResponse,
} from "../../../Services/Customers/customerContracts";

const initialState: LoginCustomerResponse = {
  customer: undefined,
  errors: undefined,
  token: undefined,
  loginAttemptTimeInUtc: undefined
};

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    loginCustomer: (state, action: PayloadAction<LoginCustomerResponse>) => {
      state.customer = action.payload.customer;
      state.loginAttemptTimeInUtc = new Date().toUTCString()
      state.errors = action.payload.errors;
      state.token = action.payload.token;
    },
    logoutCustomer: (state) => {
      state.customer = undefined;
      state.errors = undefined;
      state.token = undefined;
    },
    updateCard: (state, action: PayloadAction<UpdateCardResponse>) => {
      if (action.payload.card === undefined) return;
      if(!state.customer)
        return

      state.customer!.cards = state.customer!.cards.map(c => {
        if(c.id !== action.payload.card?.id)
          return c
        else
        return action.payload.card
      })
    },
  },
});

export const sessionCustomerSlice = createSlice({
  name: "session-customer",
  initialState,
  reducers: {
    loginCustomer: (state, action: PayloadAction<LoginCustomerResponse>) => {
      state.customer = action.payload.customer;
      state.loginAttemptTimeInUtc = new Date().toUTCString()
      state.errors = action.payload.errors;
      state.token = action.payload.token;
    },
    logoutCustomer: (state) => {
      state.customer = undefined;
      state.errors = undefined;
      state.token = undefined;
    },
    updateCard: (state, action: PayloadAction<UpdateCardResponse>) => {
      if (action.payload.card === undefined) return;

      state.customer!.cards = state.customer!.cards.map(c => {
        if(c.id !== action.payload.card?.id)
          return c
        else
        return action.payload.card
      })
    },
  },
});

// Action creators are generated for each case reducer function
export const customerActions = customerSlice.actions;
export const customerReducers = customerSlice.reducer;

export const sessionCustomerActions = sessionCustomerSlice.actions;
export const sessionCustomerReducers = sessionCustomerSlice.reducer;
