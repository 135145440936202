import { Box, BoxProps, LoadingOverlay, Stack } from "@mantine/core";
import { DigitalCardFactory } from "./DigitalCards/Templates/Factories/DigitalCardFactory";
import { sonsoImageProvider } from "../../Services/ImageProvider/Sonso/sonsoImageProvider";
import { CustomerDto } from "../../Services/Customers/customerContracts";

type Props = {
  as: "customer" | "user";
  isLoading: boolean
  customer?: CustomerDto
  error?: string 
};

export const CardAdministration = ({isLoading, as, customer, error,...boxProps}: Props & BoxProps) => {

  return (
    <Box {...boxProps}>
      <LoadingOverlay visible={isLoading} />
      {customer?.cards &&
        customer.cards?.map((card) => (
          <DigitalCardFactory
            companyName={customer.companyName}
            key={card.id}
            imageProvider={sonsoImageProvider}
            card={card}
            editable
          />
        ))}
    </Box>
  );
};
