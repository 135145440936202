import { useState } from "react";
import {
  GetCustomerCardRequest,
  GetCustomerCardResponse,
} from "../../../Services/Customers/customerContracts";
import { getCustomerCard } from "../../../Services/Customers/customerRest";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { useCustomerSelector } from "../../../Redux/Selectors/useCustomerSelector";

export const useGetCustomerCard = (): [
  (
    request: Omit<GetCustomerCardRequest, "customerToken" | "userToken">
  ) => Promise<void>,
  boolean,
  GetCustomerCardResponse?
] => {
  const user = useSelector((state: RootState) => state.userSlice);
  const customer = useCustomerSelector();
  const [response, setResponse] = useState<GetCustomerCardResponse>();
  const [loading, setLoading] = useState<boolean>(false);

  const fetch = async (
    request: Omit<GetCustomerCardRequest, "customerToken" | "userToken">
  ) => {
    setLoading(true);
    setResponse(undefined);

    const response = await getCustomerCard({
      customerToken: customer.token,
      userToken: user.token,
      ...request,
    });

    setLoading(false);
    setResponse(response);
  };

  return [fetch, loading, response];
};
