import axios from "axios";
import {
  ChangePaymentTierRequest,
  ChangePaymentTierResponse,
  ExampleCardsRequest,
  GetExampleCardsResponse,
  GetCustomerByEmailRequest,
  GetCustomerResponse,
  GetCustomerCardRequest,
  GetCustomerCardResponse,
  GiveContactInformationRequest,
  GiveContactInformationResponse,
  LoginCustomerRequest,
  LoginCustomerResponse,
  PublishNewCardRequest,
  PublishNewCardResponse,
  RegisterCustomerRequest,
  RegisterCustomerResponse,
  UpdateCardRequest,
  UpdateCardResponse,
  DeleteCardRequest,
  DeleteCardResponse,
  AvailableCardPaymentRequest,
  AvailableCardPaymentResponse,
  ViewCardRequest,
  ViewCardResponse,
  AddCardInteractionsRequest,
  AddCardInteractionsResponse,
  GetAllInteractionsRequest,
  GetAllInteractionsResponse,
  RecommendationDto,
  InformativeCardsDto,
  SearchCustomersCardsRequest,
} from "./customerContracts";
import {
  UNAUTHORIZED_ERROR_RESPONSE,
  UNDEFINED_ERROR_RESPONSE,
} from "../Commons/commonResponses";
import {
  api,
  CUSTOMER_SCHEME_NAME,
  unauthorizedApi,
  USER_SCHEME_NAME,
} from "../Rest/sonsoAxios";

const GET_EXAMPLE_CARDS_QUERY = "/api/Customer/example-cards";
const GET_CUSTOMER_BY_EMAIL_QUERY = "/api/Customer/get-customer-by-email";
const GET_CUSTOMER_CARD_QUERY = "/api/Customer/get-customer-cards";
const VIEW_CARD_QUERY = "/api/DigitalCard/view-card";
const REGISTER_CUSTOMER_QUERY = "/api/Customer/register-customer";
const PUBLISH_NEW_CARD_QUERY = "/api/DigitalCard/publish-new-card";
const SEARCH_CUSTOMERS_CARDS_QUERY = "/api/Customer/search-customers-cards";
const UPDATE_CARD_QUERY = "/api/DigitalCard/update-card";
const LOGIN_CUSTOMER_QUERY = "/api/Customer/login-customer";
const CHANGE_PAYMENT_TIER_QUERY = "/api/Customer/change-payment-tier";
const GIVE_CONTACT_INFORMATION_QUERY =
  "/api/DigitalCard/give-contact-information";
const GET_AVAILABLE_CARD_PAYMENTS_QUERY =
  "/api/DigitalCard/get-available-card-payments";
const DELETE_CARD_QUERY = "/api/DigitalCard/delete-card";
const ADD_CARD_INTERACTIONS_QUERY = "/api/DigitalCard/add-card-interactions";
const GET_ALL_INTERACTIONS_QUERY = "/api/Customer/get-all-interactions";

export const getAllInteractions = async (
  request: GetAllInteractionsRequest
): Promise<GetAllInteractionsResponse> => {
  try {
    const response = await api.get(GET_ALL_INTERACTIONS_QUERY, {
      headers: {
        [USER_SCHEME_NAME]: request.userToken,
        [CUSTOMER_SCHEME_NAME]: request.customerToken,
      },
      params: request,
    });

    return await response.data;
  } catch (error) {
    return UNDEFINED_ERROR_RESPONSE;
  }
};

export const addCardInteractions = async (
  request: AddCardInteractionsRequest
): Promise<AddCardInteractionsResponse> => {
  try {
    const response = await api.post(ADD_CARD_INTERACTIONS_QUERY, request, {
      withCredentials: true,
    });

    return await response.data;
  } catch (error) {
    return UNDEFINED_ERROR_RESPONSE;
  }
};

export const deleteCard = async (
  request: DeleteCardRequest
): Promise<DeleteCardResponse> => {
  try {
    const response = await api.delete(DELETE_CARD_QUERY, {
      params: request,
    });

    return await response.data;
  } catch (error) {
    return UNDEFINED_ERROR_RESPONSE;
  }
};

export const viewCard = async (
  request: ViewCardRequest
): Promise<ViewCardResponse> => {
  try {
    const response = await api.get(VIEW_CARD_QUERY, {
      params: request,
    });

    return await response.data;
  } catch (error) {
    return UNDEFINED_ERROR_RESPONSE;
  }
};

export const getAvailableCardPayments = async (
  request: AvailableCardPaymentRequest
): Promise<AvailableCardPaymentResponse> => {
  try {
    const response = await api.get(GET_AVAILABLE_CARD_PAYMENTS_QUERY, {
      params: request,
    });

    return await response.data;
  } catch (error) {
    return UNDEFINED_ERROR_RESPONSE;
  }
};

export const getExampleCards = async (
  request: ExampleCardsRequest
): Promise<GetExampleCardsResponse> => {
  try {
    const response = (
      await unauthorizedApi.get(GET_EXAMPLE_CARDS_QUERY, {
        params: request,
      })
    ).data;

    return { cards: response.cards, errors: undefined };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return { errors: [{ errorCode: -1, message: error.message }] };
    }
    return UNDEFINED_ERROR_RESPONSE;
  }
};

export const getCustomerByEmail = async (
  request: GetCustomerByEmailRequest
): Promise<GetCustomerResponse> => {
  try {
    const response = await api.get(GET_CUSTOMER_BY_EMAIL_QUERY, {
      params: { email: request.email },
      headers: {
        [USER_SCHEME_NAME]: request.userToken,
        [CUSTOMER_SCHEME_NAME]: request.customerToken,
      },
    });
    return await response.data;
  } catch (error) {
    return UNDEFINED_ERROR_RESPONSE;
  }
};

export const getCustomerCard = async (
  request: GetCustomerCardRequest
): Promise<GetCustomerCardResponse> => {
  const { userToken, customerToken, ...params } = request;
  try {
    const response = await api.get(GET_CUSTOMER_CARD_QUERY, {
      params: params,
      headers: {
        [USER_SCHEME_NAME]: userToken,
        [CUSTOMER_SCHEME_NAME]: customerToken,
      },
    });

    return await response.data;
  } catch (error) {
    return UNDEFINED_ERROR_RESPONSE;
  }
};

export const registerCustomer = async (
  request: RegisterCustomerRequest
): Promise<RegisterCustomerResponse> => {
  try {
    const response = (
      await api.post(REGISTER_CUSTOMER_QUERY, request, {
        headers: {
          [USER_SCHEME_NAME]: request.userToken,
        },
      })
    ).data;
    return response;
  } catch (error) {
    return UNDEFINED_ERROR_RESPONSE;
  }
};

export const publishNewCard = async (
  request: PublishNewCardRequest
): Promise<PublishNewCardResponse> => {
  try {
    const response = (
      await api.post(PUBLISH_NEW_CARD_QUERY, {
        ...request,
        logo: request.logo?.length !== 0 ? request.logo : null,
        recommendations: request.recommendations.map(c => ({
          ...c,
          image: c.image?.length !== 0 ? c.image : null
        }))
      }, {
        headers: {
          [USER_SCHEME_NAME]: request.token,
        },
      })
    ).data;
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === axios.HttpStatusCode.BadRequest) {
        return error.response?.data ?? UNDEFINED_ERROR_RESPONSE;
      }
    }

    return UNDEFINED_ERROR_RESPONSE;
  }
};

export const searchCustomersCards = async (
  request: SearchCustomersCardsRequest
): Promise<GetExampleCardsResponse> => {
  try {
    const response = (
      await api.get(SEARCH_CUSTOMERS_CARDS_QUERY, {
        headers: {
          [USER_SCHEME_NAME]: request.userToken,
        },
        params: request,
      })
    ).data;

    return response;
  } catch (error) {
    return UNDEFINED_ERROR_RESPONSE;
  }
};

export const updateCard = async (
  request: UpdateCardRequest
): Promise<UpdateCardResponse> => {
  try {
    const response = await api.post(UPDATE_CARD_QUERY, {
      ...request,
      logo: request.logo?.length !== 0 ? request.logo : null,
      recommendations: request.recommendations.map(c => ({
        ...c,
        image: c.image?.length !== 0 ? c.image : null
      }))
    }, {
      headers: {
        [CUSTOMER_SCHEME_NAME]: request.customerToken,
        [USER_SCHEME_NAME]: request.userToken,
      },
    });

    return await response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === axios.HttpStatusCode.BadRequest) {
        return error.response?.data ?? UNDEFINED_ERROR_RESPONSE;
      }
    }
    return UNDEFINED_ERROR_RESPONSE;
  }
};

export const loginCustomer = async (
  request: LoginCustomerRequest
): Promise<LoginCustomerResponse> => {
  try {
    const response = (await api.post(LOGIN_CUSTOMER_QUERY, request)).data;
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (!error.response?.status) return UNDEFINED_ERROR_RESPONSE;

      switch (error.response.status) {
        case axios.HttpStatusCode.Unauthorized:
          return UNAUTHORIZED_ERROR_RESPONSE;

        default:
          break;
      }

      return UNDEFINED_ERROR_RESPONSE;
    }
    return UNDEFINED_ERROR_RESPONSE;
  }
};

export const changePaymentTier = async (
  request: ChangePaymentTierRequest
): Promise<ChangePaymentTierResponse> => {
  try {
    const response = await api.post(CHANGE_PAYMENT_TIER_QUERY, request);
    return await response.data;
  } catch (error) {
    return UNDEFINED_ERROR_RESPONSE;
  }
};

export const giveContactInformation = async (
  request: GiveContactInformationRequest
): Promise<GiveContactInformationResponse> => {
  try {
    var response = await api.post(GIVE_CONTACT_INFORMATION_QUERY, request);
    return await response.data;
  } catch (error) {
    return UNDEFINED_ERROR_RESPONSE;
  }
};
