import {
  Button,
  Card,
  Divider,
  Group,
  NumberInput,
  Stack,
  Textarea,
  TextInput,
  Title,
} from "@mantine/core";
import { GetInputPropsReturnType } from "@mantine/form/lib/types";
import React from "react";
import { useTranslation } from "react-i18next";
import { FaTrash } from "react-icons/fa";
import { FileInputWithLoading } from "./FileInputWithLoading";

type Props = {
  onDeletionClick?: () => void;
  recommendationName: string;
  inputs: {
    name: {
      key: string;
      props: GetInputPropsReturnType;
    };
    company: {
      key: string;
      props: GetInputPropsReturnType;
    };
    image: {
      key: string;
      owner: string;
      onImageUploaded: (id?: string) => void
      props: GetInputPropsReturnType;
    };
    stars: {
      key: string;
      props: GetInputPropsReturnType;
    };
    comment: {
      key: string;
      props: GetInputPropsReturnType;
    };
  };
};

export const RecommendationForm = ({
  onDeletionClick,
  recommendationName,
  inputs
}: Props) => {
  const { t } = useTranslation();

  return (
    <Card withBorder m={"md"}>
      <Group justify="space-between">
        <Title order={6}>
          {t("recommendation")} - {recommendationName}
        </Title>
        <Button onClick={onDeletionClick} color="red" variant="transparent">
          <FaTrash />
        </Button>
      </Group>
      <Divider />
      <TextInput
        key={inputs.name.key}
        required
        label={t("name")}
        {...inputs.name.props}
      />
      <TextInput
        key={inputs.company.key}
        required
        label={t("company name")}
        {...inputs.company.props}
      />
      <Group mt={"md"}>
        <FileInputWithLoading
          key={inputs.image.key}
          owner={inputs.image.owner}
          imageUploaded={inputs.image.onImageUploaded}
          accept="image/*"
          label={t("image")}
          placeholder={t("image")}
          {...inputs.image.props}
        />
        <Stack>
          <NumberInput
            key={inputs.stars.key}
            label={t("stars out of ten")}
            {...inputs.stars.props}
          />
        </Stack>
      </Group>
      <Textarea
        key={inputs.comment.key}
        required
        label={t("comment")}
        {...inputs.comment.props}
      />
    </Card>
  );
};
