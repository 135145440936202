import { Box, getGradient, Group, MantineColor, MantineTheme, Title } from "@mantine/core";
import React from "react";

type Props = {
  color: MantineColor;
  title: string;
  theme: MantineTheme
};

export const DonaItaliaSymmetricTitle = ({ color, title, theme }: Props) => {
  const gradient2 = getGradient(
    {
      deg: 90,
      from: color,
      to: theme.white!,
    },
    theme
  );

  const gradient1 = getGradient(
    {
      deg: 270,
      from: color,
      to: theme.white!,
    },
    theme
  );

  return (
    <Group dir="ltr">
      <Box h={5} bg={gradient1} style={{ flexGrow: 1 }}></Box>
      <Title order={6}>{title}</Title>
      <Box h={5} bg={gradient2} style={{ flexGrow: 1 }}></Box>
    </Group>
  );
};
