import {
  Box,
  Card,
  Direction,
  getGradient,
  Group,
  MantineColor,
  MantineTheme,
  Paper,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { InformativeSectionProps } from "./InformativeSectionsProps";
import { DonaItaliaTitle } from "../Titles/DonaItaliaTitle";

type Props = {
  color: MantineColor;
};

export const DonaItaliaInformativeSection = (
  props: Props & InformativeSectionProps
) => {
  return (
    <Stack>
      {props.sections?.map((s) => (
        <Box
          key={s.id}
          onClick={() => props.onSectionOpened && props.onSectionOpened(s.id)}
        >
          <DonaItaliaTitle title={s.title} color={props.color} />
          <Card mt={"md"} bg={"#FFF"}>
            <Text>{s.text}</Text>
          </Card>
        </Box>
      ))}
    </Stack>
  );
};
