import { DirectionProvider } from "@mantine/core";
import { RoutingContext } from "./RoutingContext";

export const App = () => {
  return (
    <DirectionProvider>
      <RoutingContext />
    </DirectionProvider>
  );
};
