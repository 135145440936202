import React, { useEffect } from "react";
import { useGetExampleCards } from "../../../../Hooks/Sonso/CustomerHooks/useGetExampleCards";
import { Box, LoadingOverlay, Skeleton, Stack } from "@mantine/core";
import { DigitalCardFactory } from "../Templates/Factories/DigitalCardFactory";
import { sonsoImageProvider } from "../../../../Services/ImageProvider/Sonso/sonsoImageProvider";
import { Carousel } from "@mantine/carousel";
import { ShlomiCardLayout3 } from "../Templates/Layouts/Containers/ShlomiCardLayout3";

type Props = {};

export const ExampleCardDisplay = (props: Props) => {
  const [getExampleCards, loading, response] = useGetExampleCards();

  useEffect(() => {
    getExampleCards({});
  }, []);

  if (response?.errors) {
    return (
      <Stack bg={"dark"}>
        <Carousel  slideSize={"66%"} withControls={false}>
          {[1, 2, 3].map((i) => (
            <Carousel.Slide key={i}>
              <ShlomiCardLayout3
                profile={<Skeleton animate={false} radius={0} h={150} />}
                metadata={<Skeleton animate={false} h={25} />}
                socialLinks={<Skeleton animate={false} h={150} />}
                informativeSections={<Skeleton animate={false} h={25} />}
              />
            </Carousel.Slide>
          ))}
        </Carousel>
      </Stack>
    );
  }

  return (
    <Stack bg={"dark"}>
      <LoadingOverlay visible={loading} />
      {/* Digital Cards */}
      {response?.cards ? (
        <Carousel
          dir="ltr"
          initialSlide={1}
          withControls={false}
        >
          {response.cards.map((card) => (
            <Box key={card.id} p={"md"}>
              <DigitalCardFactory
                onlyDisplay
                companyName=""
                key={card.id}
                card={card}
                imageProvider={sonsoImageProvider}
                mah={850}
              />
            </Box>
          ))}
        </Carousel>
      ) : (
        <div>Failed</div>
      )}
    </Stack>
  );
};
