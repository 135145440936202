import {
  Card,
  Divider,
  Group,
  MantineColor,
  SimpleGrid,
  Stack,
  Table,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { IconContext } from "react-icons";
import { CiStar } from "react-icons/ci";
import { FaStar } from "react-icons/fa";

type Props = {
  name: string;
  changesAllowed: string | number;
  cardsAllowed: string | number;
  gradient1: MantineColor
  gradient2?: MantineColor
};

export const PaymentTierBadge = ({
  name,
  changesAllowed,
  cardsAllowed,
  gradient1: color,
  gradient2
}: Props) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();

  return (
    <Card
      shadow="lg"
      maw={400}
      bg={`linear-gradient(152deg, ${color} 30%, ${gradient2 ?? theme.white} 60%, ${gradient2 ?? theme.white} 70%,${color} 100%)`}
    >
      <Title c={theme.black} order={3} dir="ltr">
        {name} <FaStar color={theme.colors.yellow[2]} />
      </Title>

      <Divider c={theme.black} />
      <Table withColumnBorders withRowBorders={false}>
        <Table.Tbody>

          <Table.Tr>
            <Table.Td>
              <Text m={0} fw={"bold"}>
                {t("cards allowed")}{" "}
              </Text>
            </Table.Td>
            <Table.Td>
              <Text m={0}>{cardsAllowed}</Text>
            </Table.Td>
          </Table.Tr>

          <Table.Tr>
            <Table.Td>
              <Text m={0} fw={"bold"}>
                {t("changes allowed")}
              </Text>
            </Table.Td>
            <Table.Td>
              <Text m={0}>{changesAllowed}</Text>
            </Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>
    </Card>
  );
};
