import { deepFreeze } from "./objects";
import { Error, errors } from "./errors";
import { utils } from "./utils";

const FULL_NAME_MAX_LENGTH = 64;
const FULL_NAME_MIN_LENGTH = 2;

const COMPANY_NAME_MAX_LENGTH = 64;
const COMPANY_NAME_MIN_LENGTH = 2;

const PASSWORD_MAX_LENGTH = 64;
const PASSWORD_MIN_LENGTH = 9;

const USERNAME_MAX_LENGTH = 64;
const USERNAME_MIN_LENGTH = 9;

const CARD_NAME_MAX_LENGTH = 64;
const CARD_NAME_MIN_LENGTH = 2;

const CARD_METADATA_TITLE_MAX_LENGTH = 64;
const CARD_METADATA_TITLE_MIN_LENGTH = 2;

const CARD_METADATA_TEXT_MAX_LENGTH = 64;
const CARD_METADATA_TEXT_MIN_LENGTH = 2;

const CARD_INFORMATION_SECTIONS_TITLE_MAX_LENGTH = 300;
const CARD_INFORMATION_SECTIONS_TITLE_MIN_LENGTH = 2;

const CARD_INFORMATION_SECTIONS_TEXT_MAX_LENGTH = 500;
const CARD_INFORMATION_SECTIONS_TEXT_MIN_LENGTH = 2;

export default deepFreeze({
  customer: {
    fullName: {
      maxLength: FULL_NAME_MAX_LENGTH,
      minLength: FULL_NAME_MIN_LENGTH,
      validate: (val?: string): Error | undefined => {
        if (utils.common.isUndefined(val))
          return errors.customer.fullName.UNDEFINED;

        if (utils.common.isLessThan(val!.length, FULL_NAME_MIN_LENGTH))
          return errors.customer.fullName.TOO_SHORT;

        if (utils.common.isGreaterThan(val!.length, FULL_NAME_MAX_LENGTH))
          return errors.customer.fullName.TOO_LONG;

        if (utils.common.containSpecialCharacters(val!))
          return errors.customer.fullName.HAS_SPECIAL_CHARACTERS;

        return undefined;
      },
    },
    companyName: {
      maxLength: COMPANY_NAME_MAX_LENGTH,
      minLength: COMPANY_NAME_MIN_LENGTH,
      validate: (val?: string): Error | undefined => {
        if (utils.common.isUndefined(val))
          return errors.customer.companyName.UNDEFINED;

        if (utils.common.isLessThan(val!.length, COMPANY_NAME_MIN_LENGTH))
          return errors.customer.companyName.TOO_SHORT;

        if (utils.common.isGreaterThan(val!.length, COMPANY_NAME_MAX_LENGTH))
          return errors.customer.companyName.TOO_LONG;

        if (utils.common.containSpecialCharacters(val!))
          return errors.customer.companyName.HAS_SPECIAL_CHARACTERS;

        return undefined;
      },
    },
    password: {
      maxLength: PASSWORD_MAX_LENGTH,
      minLength: PASSWORD_MIN_LENGTH,
      validate: (val?: string): Error | undefined => {
        if (utils.common.isUndefined(val))
          return errors.customer.password.UNDEFINED;

        if (utils.common.isLessThan(val!.length, PASSWORD_MIN_LENGTH))
          return errors.customer.password.TOO_SHORT;

        if (utils.common.isGreaterThan(val!.length, PASSWORD_MAX_LENGTH))
          return errors.customer.password.TOO_LONG;

        if (!utils.common.containSpecialCharacters(val!))
          return errors.customer.password.NOT_INCLUDE_SPECIAL_CHARACTER;

        return undefined;
      },
    },
    phone: {
      maxLength: PASSWORD_MAX_LENGTH,
      minLength: PASSWORD_MIN_LENGTH,
      validate: (val?: string): Error | undefined => {
        if (utils.common.isUndefined(val))
          return errors.customer.phone.UNDEFINED;

        return undefined;
      },
    },
    email: {
      validate: (val?: string): Error | undefined => {
        if (utils.common.isUndefined(val))
          return errors.customer.email.UNDEFINED;

        if (!utils.common.isEmail(val!)) return errors.customer.email.INVALID;

        return undefined;
      },
    },
  },
  potentialCustomer: {
    fullName: {
      maxLength: FULL_NAME_MAX_LENGTH,
      minLength: FULL_NAME_MIN_LENGTH,
      validate: (val?: string): Error | undefined => {
        if (utils.common.isUndefined(val))
          return errors.potentialCustomer.fullName.UNDEFINED;

        if (utils.common.isLessThan(val!.length, FULL_NAME_MIN_LENGTH))
          return errors.potentialCustomer.fullName.TOO_SHORT;

        if (utils.common.isGreaterThan(val!.length, FULL_NAME_MAX_LENGTH))
          return errors.potentialCustomer.fullName.TOO_LONG;

        if (utils.common.containSpecialCharacters(val!))
          return errors.potentialCustomer.fullName.HAS_SPECIAL_CHARACTERS;

        return undefined;
      },
    },
  },
  user: {
    username: {
      maxLength: USERNAME_MAX_LENGTH,
      minLength: USERNAME_MIN_LENGTH,
      validate: (val?: string): Error | undefined => {
        if (utils.common.isUndefined(val))
          return errors.user.username.UNDEFINED;

        if (utils.common.isLessThan(val!.length, USERNAME_MIN_LENGTH))
          return errors.user.username.TOO_SHORT;

        if (utils.common.isGreaterThan(val!.length, USERNAME_MAX_LENGTH))
          return errors.user.username.TOO_LONG;
      },
    },
    password: {
      validate: (val?: string): Error | undefined => {
        if (utils.common.isUndefined(val))
          return errors.user.password.UNDEFINED;

        if (utils.common.isLessThan(val!.length, PASSWORD_MIN_LENGTH))
          return errors.user.password.TOO_SHORT;

        if (utils.common.isGreaterThan(val!.length, PASSWORD_MAX_LENGTH))
          return errors.user.password.TOO_LONG;
      },
    },
  },
  card: {
    name: {
      minLength: CARD_NAME_MIN_LENGTH,
      maxLength: CARD_NAME_MAX_LENGTH,
      validate: (val?: string): Error | undefined => {
        if (utils.common.isUndefined(val)) return errors.card.name.UNDEFINED;

        if (utils.common.containSpecialCharacters(val!))
          return errors.card.name.NO_SPECIAL_CHARACTERS;

        if (!utils.common.isOnlyEnglish(val!))
          return errors.card.name.ONLY_ENGLISH_CHARACTERS;

        if (utils.common.isLessThan(val!.length, CARD_NAME_MIN_LENGTH))
          return errors.card.name.TOO_SHORT;

        if (utils.common.isGreaterThan(val!.length, CARD_NAME_MAX_LENGTH))
          return errors.card.name.TOO_LONG;
      },
    },
    profile: {
      validate: (value: string) => {
        if(value.length === 0)
          return errors.card.profile.WAS_NOT_SET;
      }
    },
    template: {
      validate: (val?: {type: string | number}): Error | undefined => {
        if (utils.common.isUndefined(val))
          return errors.card.template.UNDEFINED;

        if (!utils.common.isNumber(val!.type))
          return errors.card.template.NOT_AN_INTEGER;

        const valAsNumber = Number.parseInt(val!.type.toString());

        if (utils.common.isLessThan(valAsNumber, 1))
          return errors.card.template.NEGATIVE_OR_ZERO;
      },
    },
    metadata: {
      title: {
        validate: (val: string): Error | undefined => {
          if (utils.common.isUndefined(val)) return errors.card.title.UNDEFINED;

          if (
            utils.common.isLessThan(val!.length, CARD_METADATA_TITLE_MIN_LENGTH)
          )
            return errors.card.title.TOO_SHORT;

          if (
            utils.common.isGreaterThan(
              val!.length,
              CARD_METADATA_TITLE_MAX_LENGTH
            )
          )
            return errors.card.title.TOO_LONG;
        },
      },
      about: {
        validate: (val: string): Error | undefined => {
          if (utils.common.isUndefined(val)) return errors.card.about.UNDEFINED;

          if (
            utils.common.isLessThan(val!.length, CARD_METADATA_TEXT_MIN_LENGTH)
          )
            return errors.card.about.TOO_SHORT;

          if (
            utils.common.isGreaterThan(
              val!.length,
              CARD_METADATA_TEXT_MAX_LENGTH
            )
          )
            return errors.card.about.TOO_LONG;
        },
      },
    },
    socialLinks: {
      validate: (val?: any[]): Error | undefined => {
        if (utils.common.isUndefined(val))
          return errors.card.socialLinks.UNDEFINED;

        if (utils.common.isLessThan(val!.length, 1))
          return errors.card.socialLinks.ONE_OR_MORE_LINK_IS_REQUIRED;
      },
      email: {
        validate: (val?: string): Error | undefined => {
          if (utils.common.isUndefined(val))
            return errors.card.socialLinks.EMAIL_IS_NOT_VALID;
        },
      },
    },
    colors: {
      primary: {
        validate: (val?: string): Error | undefined => {
          if (utils.common.isUndefined(val))
            return errors.card.colors.UNDEFINED;

          if (!utils.common.isColor(val!))
            return errors.card.colors.NOT_A_COLOR;
        },
      },
      secondary: {
        validate: (val?: string): Error | undefined => {
          if (utils.common.isUndefined(val))
            return errors.card.colors.UNDEFINED;

          if (!utils.common.isColor(val!))
            return errors.card.colors.NOT_A_COLOR;
        },
      },
      accent: {
        validate: (val?: string): Error | undefined => {
          if (!utils.common.isUndefined(val)) return;

          if (!utils.common.isColor(val!))
            return errors.card.colors.NOT_A_COLOR;
        },
      },
    },
    recommendations: {
      validate: (val: unknown[]): Error | undefined => {
        if (!utils.common.isUndefined(val)) return;
      },
    },
    informationSections: {
      sections: {
      }
    }
  },
});
