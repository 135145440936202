import React, { PropsWithChildren, ReactNode } from "react";
import { Box, Button, Card, Collapse, Group, Space } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { FaChevronDown, FaChevronUp, FaExternalLinkAlt } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";

export type Props = {
  topSection?: ReactNode;
  onEditClick: () => void;
  onGotoClick: () => void;
  onDeleteClick?: () => void;
};

export const EditableContainer: React.FC<PropsWithChildren<Props>> = (
  props
) => {
  const { t } = useTranslation();
  const [opened, { toggle }] = useDisclosure(false);

  return (
    <Card mb={"sm"} withBorder shadow={"sm"} pb={0}  onClick={toggle}>
      <Group justify="space-between">
        <Box>
          <Button variant="transparent" onClick={props.onEditClick}>
            {<FaEdit />}
          </Button>
          <Button variant="transparent" onClick={props.onGotoClick}>
            {<FaExternalLinkAlt />}
          </Button>
          {props.onDeleteClick && (
            <Button color="red" onClick={props.onDeleteClick}>
              {t("delete")}
            </Button>
          )}
        </Box>
        <Box>{props.topSection}</Box>
      </Group>
      <Space mt={"xl"} />
      <Collapse in={opened}>{props.children}</Collapse>
      <Button variant="transparent">
        {opened ? <FaChevronUp /> : <FaChevronDown />}
      </Button>
    </Card>
  );
};
