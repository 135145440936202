import React, { useState } from "react";
import { CustomerDto } from "../../../Services/Customers/customerContracts";
import { Box, Divider, Fieldset, TextInput } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { SearchCustomers } from "../Forms/SearchCustomers";

type Props = {};

export const CustomerDisplay = ({}: Props) => {
  const [customer, setCustomer] = useState<CustomerDto>();
  const { t } = useTranslation();

  return (
    <Box component="form">
      <SearchCustomers
        onCustomerSelected={val => {setCustomer(val);}}
        selectedCustomer={customer}
      />
      {customer && (
        <Box>
          <Divider mt={'md'} />
          <Fieldset mt={'md'} legend={t('customer')}>
            <TextInput label={t("name")} value={customer.name} readOnly />
            <TextInput label={t("phone number")} value={customer.phoneNumber} readOnly />
            <TextInput label={t("email")} value={customer.email} readOnly />
            <TextInput label={t("company name")} value={customer.companyName} readOnly />
            <TextInput label={t("payment tier")} value={customer.paymentTier.name} readOnly />
            <TextInput label={t("card changes")} value={customer.cardChangesMade} readOnly />
          </Fieldset>
        </Box>
      )}
    </Box>
  );
};
