import React from 'react'

type Props = {}

export const Marketing = (props: Props) => {
  return (
    <div>
      
    </div>
  )
}