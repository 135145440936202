import { Box, Card, Container, Image, Space, Text, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { StoryCard } from "../Molecules/Cards/StoryCard";
import ourHistory from "../../Assets/Images/our-history.png";

type Props = {};

export const AboutUs = (props: Props) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Space h={"xl"} />
      {/* About us */}
      <Card bg={"violet"} w={"100%"} p={"lg"}>
        <Box m={"auto"} w={"60%"} ta={"center"}>
          <Title c={"white"}>{t("about us")}</Title>
          <Space h={"lg"} />
          <Text c={"white"}>
            Welcome to our world, where creativity fuels digital innovation.
            We’re a dedicated team helping businesses thrive with tailored
            digital marketing and innovative business card solutions, driven by
            a commitment to excellence and client success.
          </Text>
        </Box>
      </Card>

      <Space h={"lg"} />

      <StoryCard
        legend={<Text c={"violet"}>{t("our history")}</Text>}
        title={
          <Title>
            Building a Legacy <br /> of Innovation
          </Title>
        }
        text="Our story began with a simple idea: to provide businesses with the tools they need to thrive in an increasingly digital world. Since our founding, we’ve grown from a small startup to a leader in the digital marketing space, consistently pushing the boundaries of what’s possible."
        rightSection={<Image h={"100%"} src={ourHistory} />}
      />
    </Container>
  );
};
