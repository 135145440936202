import { Box, Card, Center, Flex, Group, Space, Stack, Text, Title } from "@mantine/core";
import React from "react";
import { useLaptopAndAbove } from "../../../Hooks/Layout/useTabletAndAbove";

type Props = {
  legend: React.ReactNode;
  title: React.ReactNode;
  text: string;
  rightSection?: React.ReactNode;
};

export const StoryCard = (props: Props) => {
  return (
    <Card>
      <Flex justify={"space-between"}>
        <Stack maw={550}>
          {props.legend}
          {props.title}
          <Space h={"md"} />
          <Text>{props.text}</Text>
        </Stack>
        <Box pe={'xs'} ps={'xs'}>
          {props.rightSection}
        </Box>
      </Flex>
    </Card>
  );
};
