import React, { Fragment, PropsWithChildren } from "react";

export const FontProvider = (props: PropsWithChildren) => {
  const WebFont = require("webfontloader");

  WebFont.load({
    google: {
      families: ["Poppins"],
    },
  });
  return <Fragment>{props.children}</Fragment>;
};
