import { TreeNodeData } from "@mantine/core";
import {
  LandingPageRoute,
  DigitalCardsPageRoute,
  MarketingPageRoute,
  AboutUsPageRoute,
} from "../../App/Routes";
import { INDEPENDENT_SECTION_HASH, ORGANIZATION_SECTION_HASH, PERSONAL_SECTION_HASH } from "../../Pages/DigitalCards";

export const headerLinks: TreeNodeData[] = [
  {
    label: "logo",
    value: `/${LandingPageRoute}`,
    children: undefined,
  },
  {
    label: "marketing agency",
    value: `/${MarketingPageRoute}`,
  },
  {
    value: `/${DigitalCardsPageRoute}`,
    label: "digital cards",
    // children: [
    //   {
    //     value: `/${DigitalCardsPageRoute}/#${PERSONAL_SECTION_HASH}`,
    //     label: "personal",
    //     children: undefined,
    //   },
    //   {
    //     value: `/${DigitalCardsPageRoute}/#${INDEPENDENT_SECTION_HASH}`,
    //     label: "independent",
    //     children: undefined,
    //   },
    //   {
    //     value: `/${DigitalCardsPageRoute}/#${ORGANIZATION_SECTION_HASH}`,
    //     label: "organization",
    //     children: undefined,
    //   },
    // ],
  },
  {
    label: "about us",
    value: `/${AboutUsPageRoute}`,
  },
];
