import { PageWithHeader } from "../Pages/Pages";
import { Landing } from "../Pages/Landing";
import { RootState } from "../../Redux/store";
import { useSelector } from "react-redux";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { Login } from "../Pages/Login";
import { CustomerAdministration } from "../Pages/CustomerAdministration";
import { EditCard } from "../Pages/EditCard";
import { Administration } from "../Pages/Administration";
import { DigitalCards } from "../Pages/DigitalCards";
import { DigitalCardPayment } from "../Pages/DigitalCardPayment";
import { Marketing } from "../Pages/Marketing";
import { ViewCard } from "../Pages/ViewCard";
import { Error } from "../Pages/Error";
import {
  AdministrationPageRoute,
  AdministrationLoginPageRoute,
  CustomerAdministrationPageRoute,
  DigitalCardsPageRoute,
  DigitalCardsPaymentsPageRoute,
  EditCardPageRoute,
  ErrorPageRoute,
  LandingPageRoute,
  LoginPageRoute,
  MarketingPageRoute,
  PrivacyPolicyPageRoute,
  TermsOfUsePageRoute,
  ViewCardPageRoute,
  AboutUsPageRoute,
} from "./Routes";
import { TermsOfUse } from "../Pages/TermsOfUse";
import { PrivacyPolicy } from "../Pages/PrivacyPolicy";
import { ThemingContext } from "./ThemingContext";
import { createTheme, useDirection } from "@mantine/core";
import { mantineLight } from "../../Themes/mantineTheme";
import { useCustomerSelector } from "../../Redux/Selectors/useCustomerSelector";
import { AboutUs } from "../Pages/AboutUs";
import { useEffect } from "react";
import { AdministrationLogin } from "../Pages/AdministrationLogin";

export const RoutingContext = () => {
  const translation = useSelector((state: RootState) => state.translationSlice);
  const theme = createTheme(mantineLight);
  const customer = useCustomerSelector();
  const user = useSelector((state: RootState) => state.userSlice);
  const { setDirection } = useDirection()
  
  useEffect(() => {
    setDirection(translation.dir)
  }, [translation.dir])

  const router = createBrowserRouter([
    {
      children: [
        {
          path: AdministrationLoginPageRoute,
          element: (
            <ThemingContext theme={theme}>
              <PageWithHeader>
                <AdministrationLogin />
              </PageWithHeader>
            </ThemingContext>
          )
        },
        {
          path: AboutUsPageRoute,
          element: (
            <ThemingContext theme={theme}>
              <PageWithHeader>
                <AboutUs />
              </PageWithHeader>
            </ThemingContext>
          ),
        },
        {
          path: LandingPageRoute,
          element: (
            <ThemingContext theme={theme}>
              <PageWithHeader>
                <Landing />
              </PageWithHeader>
            </ThemingContext>
          ),
        },
        {
          path: LoginPageRoute,
          element: (
            <ThemingContext theme={theme}>
              <PageWithHeader>
                <Login />
              </PageWithHeader>
            </ThemingContext>
          ),
        },
        {
          path: CustomerAdministrationPageRoute,
          element: customer.token ? (
            <ThemingContext theme={theme}>
              <PageWithHeader>
                <CustomerAdministration />
              </PageWithHeader>
            </ThemingContext>
          ) : (
            <Navigate to={`/${LoginPageRoute}`} replace />
          ),
        },
        {
          path: `${customer.customer?.companyName}/${EditCardPageRoute}/:id`,
          element: customer.token ? (
            <ThemingContext theme={theme}>
              <PageWithHeader>
                <EditCard />
              </PageWithHeader>
            </ThemingContext>
          ) : (
            <Navigate to={`/${LoginPageRoute}`} replace />
          ),
        },
        {
          path: AdministrationPageRoute,
          element: user.token ? (
            <ThemingContext theme={theme}>
              <PageWithHeader>
                <Administration />
              </PageWithHeader>
            </ThemingContext>
          ) : (
            <Navigate to={`/${LoginPageRoute}`} replace />
          ),
        },
        {
          path: DigitalCardsPageRoute,
          element: (
            <ThemingContext theme={theme}>
              <PageWithHeader>
                <DigitalCards />
              </PageWithHeader>
            </ThemingContext>
          ),
        },
        {
          path: DigitalCardsPaymentsPageRoute,
          element: (
            <ThemingContext theme={theme}>
              <PageWithHeader>
                <DigitalCardPayment />
              </PageWithHeader>
            </ThemingContext>
          ),
        },
        {
          path: MarketingPageRoute,
          element: (
            <ThemingContext theme={theme}>
              <PageWithHeader>
                <Marketing />
              </PageWithHeader>
            </ThemingContext>
          ),
        },
        {
          path: ViewCardPageRoute,
          element: <ViewCard />,
        },
        {
          path: `${TermsOfUsePageRoute}/`,
          element: (
            <ThemingContext theme={theme}>
              <PageWithHeader>
                <TermsOfUse />
              </PageWithHeader>
            </ThemingContext>
          ),
        },
        {
          path: `${PrivacyPolicyPageRoute}/`,
          element: (
            <ThemingContext theme={theme}>
              <PageWithHeader>
                <PrivacyPolicy />
              </PageWithHeader>
            </ThemingContext>
          ),
        },
        {
          path: `${ErrorPageRoute}/`,
          element: (
            <ThemingContext theme={theme}>
              <PageWithHeader>
                <Error />
              </PageWithHeader>
            </ThemingContext>
          ),
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};
