import { useState } from "react";
import {
  GetCustomersResponse,
  SearchCustomersCardsRequest,
} from "../../../Services/Customers/customerContracts";
import { searchCustomersCards } from "../../../Services/Customers/customerRest";
import { useTranslation } from "react-i18next";
import { notifier } from "../../../Utilities/notifier";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { UNAUTHORIZED_ERROR_RESPONSE } from "../../../Services/Commons/commonResponses";

export const useSearchCustomer = (): [
  (request: Omit<SearchCustomersCardsRequest, "userToken">) => Promise<void>,
  boolean,
  GetCustomersResponse?
] => {
  const { t } = useTranslation();
  const token = useSelector((state: RootState) => state.userSlice).token
  const [response, setResponse] = useState<GetCustomersResponse>();
  const [loading, setLoading] = useState<boolean>(false);

  const fetch = async (request:  Omit<SearchCustomersCardsRequest, "userToken">) => {
    setLoading(true);
    setResponse(undefined);

    if(!token)
    {
      notifier.notifyErrors(UNAUTHORIZED_ERROR_RESPONSE.errors)
      return
    }

    const response = await searchCustomersCards({...request, userToken: token});

    setLoading(false);
    setResponse(response);

    if (response.errors)
      notifier.notifyErrors(
        response.errors.map((e) => ({ message: t(e.message) }))
      );
  };

  return [fetch, loading, response];
};
