import { Link } from "react-router-dom";
import { CiGlobe } from "react-icons/ci";
import { Button, ButtonProps } from "@mantine/core";

type Props = { link: string };

export const GlobeLink = ({ link, ...props }: Props & ButtonProps) => {
  return (
    <Button {...props} p={0} component={Link} to={link}>
      <CiGlobe>{link}</CiGlobe>
    </Button>
  );
};
