import { useState } from "react";
import { sonsoImageProvider } from "../../../Services/ImageProvider/Sonso/sonsoImageProvider";
import {
  ImageDto,
  UploadImageRequest,
  UploadImageResponse,
} from "../../../Services/ImageProvider/Core/contacts";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { notifier } from "../../../Utilities/notifier";
import { useCustomerSelector } from "../../../Redux/Selectors/useCustomerSelector";

export const useUploadImage = (as: "user" | "customer") : [
  (
    request: Omit<UploadImageRequest, "customerToken" | "userToken">
  ) => Promise<void>,
  number,
  boolean,
  UploadImageResponse?
] => {
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<UploadImageResponse>();
  const user = useSelector((state: RootState) => state.userSlice);
  const customer = useCustomerSelector();

  const upload = async (
    request: Omit<UploadImageRequest, "userToken" | "customerToken">
  ) => {

    if (loading) {
      notifier.notifyErrors([{ message: "can upload one image at a time" }]);
    }
    setLoading(true);
    setProgress(0);

    const response = await sonsoImageProvider.uploadImage(
      {
        ...request,
        userToken: user.token,
        customerToken: customer.token,
      },
      setProgress
    );

    setLoading(false);
    setResponse(response);
  };

  return [upload, progress, loading, response];
};
