import { Card, Space } from '@mantine/core';
import React, { ReactNode } from 'react'

type Props = {
  profile?: ReactNode;
  metadata?: ReactNode;
  gallery?: ReactNode;
  questionsAndAnswers?: ReactNode;
  informativeSections?: ReactNode;
  recommendations?: ReactNode;
  contactForm?: ReactNode;
  socialLinks?: ReactNode;
  contactUsLinks?: ReactNode;
  getContactUsButton?: ReactNode;
}

export const AvivCardLayout4 = ({
  profile,
  metadata,
  gallery,
  contactUsLinks,
  socialLinks,
  questionsAndAnswers,
  informativeSections,
  recommendations,
  contactForm,
  getContactUsButton
}: Props) => {
  return (
    <Card>
      {profile}
      <Space mt={'lg'} />
      {metadata}
      <Space mt={'lg'} />
      {informativeSections}
      {questionsAndAnswers}
      <Space mt={'lg'} />
      {recommendations}
      <Space mt={'lg'} />
      {gallery}
      <Space mt={'lg'} />
      {socialLinks}
      
    </Card>
  )
}