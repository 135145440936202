import { Box, Flex, Group, Stack, Text } from "@mantine/core";
import React from "react";
import { Logo } from "../../Atoms/Logo";
import { ColumnSocialLinks1 } from "../DigitalCards/Templates/Elements/SocialLinks/ColumnSocialLinks1";
import { RowSocialLinks2 } from "../DigitalCards/Templates/Elements/SocialLinks/RowSocialLinks2";

type Props = {};

export const Footer = (props: Props) => {
  return (
    <Box ps={'md'} pe={'md'}>
      <Flex p={"xl"} justify={"space-between"} wrap={"wrap"}>
        <Stack maw={350}>
          <Logo w={"5em"} />
          <Text fz={"sm"} c={"white"}>
            Amet minim mollit non deserunt ullamco est sit aliqua <br /> dolor
            do amet sint. Velit officia consequat <br /> duis enim velit mollit.
          </Text>
          <RowSocialLinks2
            variant="transparent"
            c={"white"}
            socialLinks={{
              facebook: "x",
              instagram: "x",
              web: "x",
            }}
          />
        </Stack>
        <Stack>
          <Group>
            <ColumnSocialLinks1
              withLabel
              buttonProps={{
                variant: "transparent",
                c: "white",
              }}
              socialLinks={{
                email: "sonso.central@gmail.com",
              }}
            />
          </Group>
          <Group></Group>
        </Stack>
      </Flex>
    </Box>
  );
};
