import { Divider, SimpleGrid, Skeleton, Stack, Text } from '@mantine/core'
import React from 'react'

type Props = {}

export const LoadingPaymentTier = (props: Props) => {
  return (
    <Stack>
      <Text>{}</Text>
      <Divider />
      <SimpleGrid cols={3}>
        <Skeleton ta={'center'}>
          <Text>{}</Text>
        </Skeleton>
        <Skeleton ta={'center'}>
          <Text>{}</Text>
        </Skeleton>
        <Skeleton ta={'center'} >
          {}
        </Skeleton>
      </SimpleGrid>
      <Skeleton mih={"1em"} >
        <Text ta={'center'} >
          {}
        </Text>
      </Skeleton>
    </Stack>  )
}