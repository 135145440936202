import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { useParams, useNavigate } from "react-router-dom";
import { Container, LoadingOverlay } from "@mantine/core";
import { CardForm } from "../Organizem/Forms/CardForm/CardForm";
import { useTranslation } from "react-i18next";
import { useEditCard } from "../../Hooks/Sonso/CustomerHooks/useEditCard";
import { useGetCustomerCard } from "../../Hooks/Sonso/CustomerHooks/useGetCustomerCard";
import { ErrorPageRoute } from "../App/Routes";

type Props = {};

export const EditCard = (props: Props) => {
  const [editCard, editCardLoading] = useEditCard();
  const [getCard, getCardLoading, getCardResponse] = useGetCustomerCard();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams() as any;
  const customer = useSelector(
    (state: RootState) => state.customerSlice.customer
  );

  useEffect(() => {
    if (customer === undefined) {
      navigate(`${ErrorPageRoute}/-1`);
      return;
    }

    getCard({
      cardId: Number.parseInt(params.id),
      email: customer.email,
    });
  }, []);

  if (customer === undefined) {
    return <Container></Container>;
  }

  const card = getCardResponse?.card;

  if (card === undefined) {
    return (
      <Container>
        <LoadingOverlay visible={getCardLoading} />
      </Container>
    );
  }

  return (
    <Container pos={'relative'}>
      <LoadingOverlay visible={getCardLoading || editCardLoading} />
      <CardForm
        onSubmit={(val) =>
          editCard({
            ...val,
            email: customer.email,
          })
        }
        owner={customer.email}
        isLoading={false}
        title={t("edit card")}
        card={card}
      />
    </Container>
  );
};
