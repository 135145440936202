import { useState } from "react";
import { LoginUserRequest, LoginUserResponse } from "../../../Services/Users/userContracts";
import { loginUser as rest } from "../../../Services/Users/usersRest";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { userActions } from "../../../Redux/features/User/userSlice";
import { notifier } from "../../../Utilities/notifier";

export const useLoginUser = (): [
  (request: LoginUserRequest) => Promise<void>,
  boolean,
  LoginUserResponse?
] => {
  const user = useSelector((state: RootState) => state.userSlice);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<LoginUserResponse>();

  const loginUser = async (request: LoginUserRequest) => {
    if (user.token){
      notifier.notifySuccess({message: 'already logged in'})
      return
    }

    setResponse(undefined);
    setLoading(true);
    
    const response = await rest(request);

    dispatch(userActions.loginUser(response));
    setLoading(false);
    setResponse(response)

    if (response.errors)
      notifier.notifyErrors(response.errors.filter((e) => e.message) as any);

    if (response.token) notifier.notifySuccess({ message: "login successful" });
  };

  return [loginUser, loading, response];
};
