import axios from "axios";
import {
  GetAllPotentialCustomersRequest,
  GetAllPotentialCustomersResponse,
  AddPotentialCustomerRequest,
  AddPotentialCustomerResponse,
  ChangeStageRequest,
  ChangeStageResponse,
} from "./potentialCustomerContracts";
import { UNDEFINED_ERROR_RESPONSE } from "../Commons/commonResponses";

const API_BASE = process.env.REACT_APP_SO_N_SO_ENDPOINT;
const GET_ALL_POTENTIAL_CUSTOMERS_QUERY =
  "/api/PotentialCustomer/get-all-potential-customers";
const ADD_POTENTIAL_CUSTOMER_QUERY =
  "/api/PotentialCustomer/add-potential-customer";
const CHANGE_STAGE_CUSTOMER_QUERY = "/api/PotentialCustomer/change-stage";

export const getAllPotentialCustomers = async (
  request: GetAllPotentialCustomersRequest
): Promise<GetAllPotentialCustomersResponse> => {
  try {
    const response = await axios.get(
      `${API_BASE}${GET_ALL_POTENTIAL_CUSTOMERS_QUERY}`,
      {
        headers: {
          "user-auth": request.userToken
        }
      }
    );
    return await response.data;
  } catch (error) {
    return UNDEFINED_ERROR_RESPONSE;
  }
};

export const addPotentialCustomer = async (
  request: AddPotentialCustomerRequest
): Promise<AddPotentialCustomerResponse> => {
  try {
    const response = await axios.post(
      `${API_BASE}${ADD_POTENTIAL_CUSTOMER_QUERY}`,
      request
    );
    return await response.data;
  } catch (error) {
    return UNDEFINED_ERROR_RESPONSE;
  }
};

export const changeStageCustomer = async (
  request: ChangeStageRequest
): Promise<ChangeStageResponse> => {
  try {
    const response = await axios.post(
      `${API_BASE}${CHANGE_STAGE_CUSTOMER_QUERY}`,
      request
    );
    return await response.data;
  } catch (error) {
    return UNDEFINED_ERROR_RESPONSE;
  }
};
