import React, { useEffect, useState } from "react";
import { CardDto } from "../../../../../Services/Customers/customerContracts";
import { ImageProvider } from "../../../../../Services/ImageProvider/Core/contacts";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Center,
  Group,
  Title,
  useDirection,
  Stack,
  useMantineTheme,
  Paper,
} from "@mantine/core";
import { DigitalCardTemplate1 } from "../Layouts/Containers/DigitalCardLayout1";
import { EditableContainer } from "../../../../Layouts/EditableContainer";
import { CardProps } from "../../CardProps";
import { AccordionInformativeSections } from "../Elements/InformativeSections/AccordionInformativeSections";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { GoQuestion } from "react-icons/go";
import { Metadata1 } from "../Elements/Metadata1";
import { Recommendations1 } from "../Elements/Recommendations/Recommendations1";
import { ContactForm1 } from "../../../Forms/ContactUsForm";
import { ClientInteractionDto } from "../../../../../Services/Application/contracts";
import { ClientInteractionTypesEnum } from "../../../../../Services/Application/sonsoApplicationRest";
import { useAddCardInteractions } from "../../../../../Hooks/Sonso/CustomerHooks/useAddCardInteractions";
import { useGiveContactInformation } from "../../../../../Hooks/Sonso/CustomerHooks/useGiveContactInformation";
import { DonaItaliaCardTemplate1 } from "../Layouts/Containers/DonaItaliaLayout2";
import { DonaItaliaInformativeSection } from "../Elements/InformativeSections/DonaItaliaInformativeSection";
import { ThemingContext } from "../../../../App/ThemingContext";
import { DonaItaliaProfile } from "../Elements/Profiles/DonaItaliaProfile";
import { DonaItaliaSocialLinks } from "../Elements/SocialLinks/DonaItaliaSocialLinks";
import { ButtonWithEndSection } from "../../../../Molecules/Form/ButtonAndEndSection";
import { LuMoveUpRight } from "react-icons/lu";
import { DonaItaliaCarousel } from "../Elements/Carousels/DonaItaliaCarousel";
import { useDisclosure } from "@mantine/hooks";
import { DonaItaliaContactUsLayout } from "../Layouts/Forms/DonaItaliaContactUsLayout";
import { DonaItaliaContactUsForm } from "../Elements/Forms/DonaItaliaContactUsForm";
import { ShlomiCardLayout3 } from "../Layouts/Containers/ShlomiCardLayout3";
import { ShlomiProfile } from "../Elements/Profiles/ShlomiProfile";
import { EditCardPageRoute } from "../../../../App/Routes";
import { ShlomiInformativeSection } from "../Elements/InformativeSections/ShlomiInformativeSection";
import { ShlomiMetadata } from "../Elements/Metadatas/ShlomiMetadata";
import { RowSocialLinks2 } from "../Elements/SocialLinks/RowSocialLinks2";
import { ShlomiSocialLinks } from "../Elements/SocialLinks/ShlomiSocialLinks";
import { ShlomiRecommendations } from "../Elements/Recommendations/ShlomiRecommendations";
import { AvivCardLayout4 } from "../Layouts/Containers/AvivCardLayout4";
import { DonaItaliaTitle } from "../Elements/Titles/DonaItaliaTitle";
import { CarouselImages } from "../../../../Molecules/Carousels/CarouselImages";
import { DonaItaliaSymmetricTitle } from "../Elements/Titles/DonaItaliaSymmetricTitle";

type Props = {
  card: CardDto;
  imageProvider: ImageProvider;
  mah?: number;
  editable?: boolean;
  onlyDisplay?: boolean;
  companyName: string;
};

export const DigitalCardFactory = ({
  card,
  mah,
  imageProvider,
  editable,
  companyName,
  onlyDisplay,
}: Props) => {
  const theme = useMantineTheme();
  const [interactions, setInteractions] = useState<ClientInteractionDto[]>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [addCardInteractions] = useAddCardInteractions();
  const [giveContact, giveContactInformationLoading] =
    useGiveContactInformation();
  const { dir, setDirection } = useDirection();
  const [drawerOpen, { open: drawOpen, close: drawClose }] = useDisclosure();

  useEffect(() => {
    const handleTabClose = (event: BeforeUnloadEvent) => {
      if (editable || onlyDisplay) return;

      event.preventDefault();

      addCardInteractions({
        cardId: card.id,
        companyName: companyName,
        viewerInteractions: interactions.map((i) => ({
          information: i.information,
          type: i.type,
        })),
      });

      return;
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  useEffect(() => {
    setDirection(card.template.leftToRight ? 'ltr' : 'rtl')
  }, [])

  const presenter: CardProps = {
    ...card,
    profile: {
      id: imageProvider.getImage({ imageName: card.profile.id }).uri!,
    },
    logo: card.logo && {
      id: imageProvider.getImage({ imageName: card.logo.id }).uri!,
    },
    gallery: card.gallery?.map((c) => ({
      id: imageProvider.getImage({ imageName: c.id }).uri!,
    })),
    company: card.company?.map((c) => ({
      id: imageProvider.getImage({ imageName: c.id }).uri!,
    })),
    recommendations: card.recommendations.map((r) => ({
      ...r,
      image: r.image
        ? imageProvider.getImage({ imageName: r.image }).uri!
        : undefined,
    })),
  };

  const metadata = (
    <Metadata1 about={card.metadata.about} title={card.metadata.title} />
  );

  const recommendations = (
    <Recommendations1
      recommendations={card.recommendations.map((r) => ({
        ...r,
        image: r.image
          ? imageProvider.getImage({ imageName: r.image }).uri!
          : undefined,
      }))}
    />
  );

  const contactForm = !(editable || onlyDisplay) && (
    <ContactForm1
      Layout={DonaItaliaContactUsLayout}
      isLoading={giveContactInformationLoading}
      onSubmit={(val) => {
        giveContact({
          cardId: card.id,
          companyName: companyName,
          name: val.name,
          phoneNumber: val.number,
        });
      }}
    />
  );

  let template;
  switch (card.template.type) {
    case 1:
      template = (
        <ShlomiCardLayout3
          cardProps={{ mah }}
          profile={
            <ShlomiProfile
              carousel={{
                sources: presenter.company?.map((c) => c.id),
              }}
              profile={{
                size: 100,
                source: presenter.profile.id,
              }}
              color={presenter.colors.primary}
              dir={dir}
            />
          }
          socialLinks={
            <ShlomiSocialLinks
              color={presenter.colors.primary}
              socialLinks={presenter.socialLinks}
            />
          }
          metadata={
            <ShlomiMetadata
              {...presenter.metadata}
              dir={dir}
              colors={presenter.colors}
              degree={-(dir === "rtl") * 180 + 90}
              theme={theme}
            />
          }
          questionsAndAnswers={
            card.questionsAndAnswersCards && (
              <ShlomiInformativeSection
                controlIcon={<GoQuestion />}
                sections={card.questionsAndAnswersCards.sections.map((s) => ({
                  ...s,
                  id: s.title,
                }))}
                onSectionOpened={(section) => {
                  if (!section) return;

                  setInteractions((prev) => [
                    ...prev,
                    {
                      type: {
                        value:
                          ClientInteractionTypesEnum.QUESTIONS_AND_ANSWERS_SECTION_OPENED,
                      },
                      information: section,
                    },
                  ]);
                }}
                color={card.colors.secondary}
              />
            )
          }
          informativeSections={
            card.informationCards && (
              <ShlomiInformativeSection
                controlIcon={<GoQuestion />}
                sections={card.informationCards.sections.map((s) => ({
                  ...s,
                  id: s.title,
                }))}
                onSectionOpened={(section) => {
                  if (!section) return;

                  setInteractions((prev) => [
                    ...prev,
                    {
                      type: {
                        value:
                          ClientInteractionTypesEnum.INFORMATIVE_SECTION_OPENED,
                      },
                      information: section,
                    },
                  ]);
                }}
                color={card.colors.secondary}
              />
            )
          }
          recommendations={
            <ShlomiRecommendations
              recommendations={presenter.recommendations}
            />
          }
          contactForm={contactForm}
        />
      );
      break;
    case 2:
      template = (
        <DonaItaliaCardTemplate1
          card={presenter}
          cardProps={{ style: { direction: dir } }}
          mah={mah}
          profile={
            <DonaItaliaProfile
              carousel={{
                size: 200,
                sources: presenter.company?.map((c) => c.id),
              }}
              profile={{
                size: 125,
                source: presenter.profile.id,
              }}
              radius={50}
              dir={dir}
            />
          }
          metadata={metadata}
          gallery={
            !!presenter.gallery?.length && (
              <Stack>
                <DonaItaliaSymmetricTitle
                  color={card.colors.secondary}
                  theme={theme}
                  title={t("gallery")}
                />
                <CarouselImages
                  sources={presenter.gallery.map((i) => i.id)}
                  slideSize={"70%"}
                  slideGap={"lg"}
                  slideBorderRadius="13px"
                />
              </Stack>
            )
          }
          contactForm={contactForm}
          questionsAndAnswers={
            card.questionsAndAnswersCards && (
              <DonaItaliaInformativeSection
                controlIcon={<GoQuestion />}
                sections={card.questionsAndAnswersCards.sections.map((s) => ({
                  ...s,
                  id: s.title,
                }))}
                onSectionOpened={(section) => {
                  if (!section) return;

                  setInteractions((prev) => [
                    ...prev,
                    {
                      type: {
                        value:
                          ClientInteractionTypesEnum.QUESTIONS_AND_ANSWERS_SECTION_OPENED,
                      },
                      information: section,
                    },
                  ]);
                }}
                color={card.colors.secondary}
              />
            )
          }
          informativeSections={
            card.informationCards && (
              <DonaItaliaInformativeSection
                controlIcon={<GoQuestion />}
                sections={card.informationCards.sections.map((s) => ({
                  ...s,
                  id: s.title,
                }))}
                onSectionOpened={(section) => {
                  if (!section) return;

                  setInteractions((prev) => [
                    ...prev,
                    {
                      type: {
                        value:
                          ClientInteractionTypesEnum.INFORMATIVE_SECTION_OPENED,
                      },
                      information: section,
                    },
                  ]);
                }}
                color={card.colors.secondary}
              />
            )
          }
          recommendations={
            <Box>
              <DonaItaliaTitle
                title={t("recommendations")}
                color={card.colors.secondary}
              />

              <Recommendations1
                recommendationContainer={{
                  bg: "#FFF",
                  radius: "xs",
                }}
                recommendations={card.recommendations.map((r) => ({
                  ...r,
                  image: r.image
                    ? imageProvider.getImage({ imageName: r.image }).uri!
                    : undefined,
                }))}
              />
            </Box>
          }
          getContactUsButton={
            <Paper bg={"#fff"}>
              <ButtonWithEndSection
                w={"100%"}
                variant="white"
                buttonChildren={t("get in touch with us")}
                endSection={<LuMoveUpRight />}
                onClick={drawOpen}
              />
            </Paper>
          }
          contactUsLinks={
            <DonaItaliaSocialLinks
              background={presenter.colors.primary}
              socialLinks={card.socialLinks}
              dir={dir === "rtl" ? "ltr" : "rtl"}
              radius={50}
            />
          }
          drawer={{
            node: (
              <DonaItaliaContactUsForm
                onSubmit={(val) =>
                  giveContact({
                    cardId: card.id,
                    companyName: companyName,
                    name: val.name,
                    phoneNumber: val.number,
                  })
                }
                theme={theme}
                color={presenter.colors.secondary}
                isLoading={giveContactInformationLoading}
              />
            ),
            props: {
              onClose: () => drawClose(),
              opened: drawerOpen,
            },
          }}
          socialLinks={
            <DonaItaliaSocialLinks
              background={presenter.colors.primary}
              socialLinks={card.socialLinks}
              dir={dir}
              radius={50}
              logo={presenter.logo?.id}
            />
          }
        />
      );
      break;
    case 3:
      template = (
        <AvivCardLayout4
          metadata={metadata}
          recommendations={recommendations}
          contactForm={contactForm}
        />
      );
      break;
    default:
      template = (
        <DigitalCardTemplate1
          card={presenter}
          mah={mah}
          metadata={metadata}
          questionsAndAnswers={
            <AccordionInformativeSections
              parentTitle={t("questions and answers")}
              sections={card.questionsAndAnswersCards?.sections.map((s) => ({
                ...s,
                id: s.title,
              }))}
              onSectionOpened={(section) => {
                if (!section) return;

                setInteractions((prev) => [
                  ...prev,
                  {
                    type: {
                      value:
                        ClientInteractionTypesEnum.QUESTIONS_AND_ANSWERS_SECTION_OPENED,
                    },
                    information: section,
                  },
                ]);
              }}
              controlIcon={<IoIosInformationCircleOutline />}
            />
          }
          informativeSections={
            <AccordionInformativeSections
              parentTitle={t("information sections")}
              controlIcon={<GoQuestion />}
              sections={presenter.informationCards?.sections.map((s) => ({
                ...s,
                id: s.title,
              }))}
              onSectionOpened={(section) => {
                if (!section) return;

                setInteractions((prev) => [
                  ...prev,
                  {
                    type: {
                      value:
                        ClientInteractionTypesEnum.INFORMATIVE_SECTION_OPENED,
                    },
                    information: section,
                  },
                ]);
              }}
            />
          }
          recommendations={recommendations}
          contactForm={contactForm}
        />
      );
      break;
  }

  return editable ?? false ? (
    <EditableContainer
      onEditClick={() => navigate(`/${companyName}/${EditCardPageRoute}/${card.id}`)}
      onGotoClick={() =>  
        window.open(`/@/${companyName}/${card.name.name}`, '_blank', 'noreferrer')
      }
      topSection={
        <Group justify="center">
          <Title order={3}>{card.name.name}</Title>
        </Group>
      }
    >
      <Center>{template}</Center>
    </EditableContainer>
  ) : (
    <ThemingContext theme={theme}>{template}</ThemingContext>
  );
};
