import { useEffect } from "react";
import {
  Accordion,
  Container,
  LoadingOverlay,
  Select,
  Table,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useGetApplicationManagement } from "../../../Hooks/Sonso/ApplicationHooks/useGetApplicationManagement";
import { useGetAllPotentialCustomers } from "../../../Hooks/Sonso/PotentialCustomers/useGetAllPotentialCustomers";

type Props = {};

export const Website = (props: Props) => {
  const { t } = useTranslation();
  const [
    getApplicationManagement,
    getApplicationManagementLoading,
    getApplicationManagementResponse,
  ] = useGetApplicationManagement();
  const [
    getAllPotentialCustomers,
    getAllPotentialCustomersLoading,
    getAllPotentialCustomersResponse,
  ] = useGetAllPotentialCustomers();

  useEffect(() => {
    getApplicationManagement({});
    getAllPotentialCustomers({});
  }, []);

  const viewers = getApplicationManagementResponse?.viewers?.map((v) => (
    <Table.Tr key={v.id}>
      <Table.Td>{v.id}</Table.Td>
      <Table.Td>{v.internetAddress}</Table.Td>
      <Table.Td>
        <Select
          defaultValue={`0: ${v.viewerInteractions[0].type.name} - ${v.viewerInteractions[0].dateTime}`}
          data={v.viewerInteractions.map(
            (i, index) => `${index}: ${i.type.name} - ${i.dateTime}`
          )}
        />
      </Table.Td>
    </Table.Tr>
  ));

  const potentialCustomers =
    getAllPotentialCustomersResponse?.potentialCustomers?.map((v) => (
      <Table.Tr key={v.email}>
        <Table.Td>{v.email}</Table.Td>
        <Table.Td>{v.name}</Table.Td>
        <Table.Td>{t(v.sellingStage.name)}</Table.Td>
      </Table.Tr>
    ));

  return (
    <Container>
      <Accordion>
        <Accordion.Item value="viewers">
          <Accordion.Control>{t("viewers")}</Accordion.Control>
          <Accordion.Panel>
            <LoadingOverlay visible={getApplicationManagementLoading} />
            <Table striped>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>{t("id")}</Table.Th>
                  <Table.Th>{t("ip")}</Table.Th>
                  <Table.Th>{t("interactions")}</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{viewers}</Table.Tbody>
              <Table.Caption>
                {viewers?.length} - {t("viewers")}
              </Table.Caption>
            </Table>
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value="potential customers">
          <Accordion.Control>{t("potential customers")}</Accordion.Control>
          <Accordion.Panel>
            <LoadingOverlay visible={getAllPotentialCustomersLoading} />
            <Table striped>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>{t("id")}</Table.Th>
                  <Table.Th>{t("ip")}</Table.Th>
                  <Table.Th>{t("interactions")}</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{potentialCustomers}</Table.Tbody>
              <Table.Caption>
                {potentialCustomers?.length} - {t("potential customers")}
              </Table.Caption>
            </Table>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
};
