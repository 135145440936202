import React from "react";
import { ColumnSocialLinks1 } from "./ColumnSocialLinks1";
import { SocialLinksProp } from "../../../CardProps";
import {
  Avatar,
  Box,
  Direction,
  Image,
  MantineColor,
  MantineSize,
  MantineSpacing,
  Paper,
  Stack,
  StackProps,
} from "@mantine/core";

type Props = {
  socialLinks: SocialLinksProp;
  withLabel?: boolean;
  gap?: MantineSpacing;
  dir: Direction;
  background?: MantineColor;
  radius: number;
  logo?: string;
  stackProps?: StackProps;
};

export const DonaItaliaSocialLinks = ({
  dir,
  socialLinks,
  gap,
  withLabel,
  background,
  radius,
  logo,
  stackProps,
}: Props) => {
  const buttonContainerSize = 52;
  const buttonSize = 24;
  const logoSize = 74;
  const borderRadius =
    dir === "ltr"
      ? `${radius}px 0 ${radius}px ${radius}px`
      : `0 ${radius}px ${radius}px ${radius}px`;

  return (
    <Stack
      {...stackProps}
      p={"sm"}
      align="center"
      style={{ borderRadius: borderRadius }}
      bg={background}
    >
      {logo && (
        <Paper
          shadow="md"
          style={{ overflow: "hidden", borderRadius: 900 }}
          mah={logoSize}
          maw={logoSize}
        >
          <Image w={"100%"} h={"auto"} src={logo} />
        </Paper>
      )}
      <ColumnSocialLinks1
        socialLinks={socialLinks}
        gap={gap}
        buttonProps={{
          variant: "subtle",
          c: "white",
          fz: buttonSize,
        }}
        buttonContainerProps={{
          style: { background: "#FFF3", borderRadius: 900 },
          w: buttonContainerSize,
          h: buttonContainerSize,
        }}
        withLabel={withLabel}
      />
    </Stack>
  );
};
