import { ButtonProps, Card, CardProps, CardSection, DrawerProps, Space } from "@mantine/core";
import React, { ReactNode } from "react";
import { Metadata1 } from "../../Elements/Metadata1";

type Props = {
  profile?: ReactNode;
  metadata?: ReactNode;
  gallery?: ReactNode;
  questionsAndAnswers?: ReactNode;
  informativeSections?: ReactNode;
  recommendations?: ReactNode;
  contactForm?: ReactNode;
  socialLinks?: ReactNode;
  contactUsLinks?: ReactNode;
  getContactUsButton?: ReactNode;
  space?: number;
  drawer?: {
    props: DrawerProps;
    node: ReactNode;
  };
  cardProps?: CardProps
};

export const ShlomiCardLayout3 = ({
  profile,
  metadata,
  socialLinks,
  informativeSections,
  questionsAndAnswers,
  gallery,
  recommendations,
  cardProps
}: Props) => {
  return (
    <Card shadow="lg"  maw={430} {...cardProps}>
      <Card.Section>

        {profile}

      </Card.Section>

      <Space h={"lg"} />

      {metadata}

      <Space h={"lg"} />

      {informativeSections}


      <Space h={"lg"} />

      {socialLinks}

      <Space h={"lg"} />

      {gallery}

      <Space h={"lg"} />

      {recommendations}

      <Space h={"lg"} />

      {questionsAndAnswers}

    </Card>
  );
};
