import { Button, Card, Image, Space, Text, Title } from "@mantine/core";
import React from "react";
import { Link } from "react-router-dom";

export type LearnMoreCardProps = {
  imageSrc: string;
  description: string;
  title: string;
  to: string;
  label: string;
};

export const LearnMoreCard = (props: LearnMoreCardProps) => {
  return (
    <Card radius={'sm'} maw={250} mah={"474px"} withBorder>
      <Image src={props.imageSrc} />
      <Space h={"sm"} />
      <Title order={4}>{props.title}</Title>
      <Space h={"md"} />
      <Text>{props.description}</Text>
      <Space h={"md"} />
      <Button variant="subtle" component={Link} to={props.to}>
        <Text size="lg" >
          {props.label} {' >'}
        </Text>
      </Button>
    </Card>
  );
};
