import { useEffect } from "react";
import { useViewCard } from "../../Hooks/Sonso/CustomerHooks/useViewCard";
import { Navigate, useParams } from "react-router-dom";
import { sonsoImageProvider } from "../../Services/ImageProvider/Sonso/sonsoImageProvider";
import {
  AppShell,
  Box,
  Center,
  colorsTuple,
  createTheme,
  Space,
} from "@mantine/core";
import { DigitalCardFactory } from "../Organizem/DigitalCards/Templates/Factories/DigitalCardFactory";
import { ThemingContext } from "../App/ThemingContext";
import { mantineLight } from "../../Themes/mantineTheme";
import { ErrorPageRoute } from "../App/Routes";
import { useLaptopAndAbove } from "../../Hooks/Layout/useTabletAndAbove";

type Props = {};

export const ViewCard = (props: Props) => {
  const [fetch, loading, response] = useViewCard();
  const laptopOrAbove = useLaptopAndAbove();
  const { company, card } = useParams() as any;

  useEffect(() => {
    fetch({
      cardName: card,
      companyName: company,
    });
  }, []);

  if (response && !response?.card?.colors)
    return (
      <Navigate
        to={`/${ErrorPageRoute}/${
          response?.errors?.map((error) => error.errorCode).join(",") ?? ""
        }`}
        replace
      />
    );

  if (!response?.card) return <></>;

  const theme = createTheme({
    ...mantineLight,
    colors: {
      violet: colorsTuple(response.card.colors.primary),
    },
  });

  return (
    <>
      <ThemingContext theme={theme}>
        <AppShell>
          <AppShell.Main bg={"black"}>
            {response?.card && (
              <>
                {laptopOrAbove && <Space h={"xl"} />}
                <Center>
                  {response?.card && (
                    <DigitalCardFactory
                      card={response.card}
                      companyName={company}
                      imageProvider={sonsoImageProvider}
                    />
                  )}
                </Center>
                <Space h={"xl"} />
              </>
            )}
          </AppShell.Main>
        </AppShell>
      </ThemingContext>
    </>
  );
};
