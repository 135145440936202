import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Fieldset,
  Group,
  LoadingOverlay,
  TextInput,
} from "@mantine/core";
import { isEmail, useForm } from "@mantine/form";
import rules from "../../../Utilities/rules";

export type CustomerRegisterSubmit = {
  name: string;
  companyName: string;
  email: string;
  password: string;
  phoneNumber: string;
};

type Props = {
  onSubmit: ({
    name,
    companyName,
    email,
    password,
    phoneNumber,
  }: CustomerRegisterSubmit) => void;
  isLoading: boolean;
  nameError?: string;
  companyNameError?: string;
  emailError?: string;
  passwordError?: string;
  phoneNumberError?: string;
  generalError?: string;
};

export const CustomerRegisterForm = (props: Props) => {
  const { t } = useTranslation();
  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      fullName: "",
      companyName: "",
      email: "",
      password: "",
      phone: "",
    },
    validate: {
      fullName: (val) => {
        const res = rules.customer.fullName.validate(val);
        return res !== undefined ? t(res.message) : undefined;
      },
      companyName: (val) => {
        const res = rules.customer.companyName.validate(val);
        return res !== undefined ? t(res.message) : undefined;
      },
      email: (val) => isEmail(t("email invalid"))(val),
      password: (val) => {
        const res = rules.customer.password.validate(val);
        return res !== undefined ? t(res.message) : undefined;
      },
      phone: (val) => {
        const res = rules.customer.phone.validate(val);
        return res !== undefined ? t(res.message) : undefined;
      },
    },
    validateInputOnBlur: true,
  });

  return (
    <Box
      component="form"
      pos={"relative"}
      onSubmit={form.onSubmit((values) =>
        props.onSubmit({
          name: values.fullName,
          companyName: values.companyName,
          email: values.email,
          password: values.password,
          phoneNumber: values.phone,
        })
      )}
    >
      <LoadingOverlay
        visible={props.isLoading}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />

      <Fieldset legend={t("customer register")}>
        <TextInput
          key={form.key("fullName")}
          placeholder={t("full name")}
          label={t("full name")}
          name="name"
          error={props.nameError}
          {...form.getInputProps("fullName")}
        />
        <TextInput
          key={form.key("companyName")}
          placeholder={t("company name")}
          label={t("company name")}
          name="companyName"
          error={props.companyNameError}
          {...form.getInputProps("companyName")}
        />
        <TextInput
          key={form.key("email")}
          placeholder={t("email")}
          label={t("email")}
          name="email"
          error={props.emailError}
          {...form.getInputProps("email")}
        />
        <TextInput
          key={form.key("password")}
          placeholder={t("password")}
          label={t("password")}
          name="password"
          error={props.passwordError}
          {...form.getInputProps("password")}
        />
        <TextInput
          key={form.key("phone")}
          placeholder={t("phone number")}
          label={t("phone number")}
          name="phoneNumber"
          error={props.phoneNumberError}
          {...form.getInputProps("phone")}
        />

        <Group justify={"flex-end"} mt={"md"}>
          <Button type="submit">{t("submit")}</Button>
        </Group>
      </Fieldset>
    </Box>
  );
};
