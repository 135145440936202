import React, { useState } from "react";
import {
  CardDto,
  ContactDto,
} from "../../Services/Customers/customerContracts";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  List,
  LoadingOverlay,
  Skeleton,
  Space,
  Text,
  Title,
} from "@mantine/core";
import { AreaChart, BarChart, LineChart } from "@mantine/charts";
import { ResponsiveContainer } from "recharts";
import { format, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";

type Props = {
  cards: CardDto[] | undefined;
};

export type CardItem = {
  cardName: string;
  contacts: ContactDto[];
  date: string;
};

export type ContactSummary = {
  date: string; // e.g., '2024-10-20'
  totalContacts: number;
};

export const PotentialCustomerAdministration = ({ cards }: Props) => {
  const { t } = useTranslation()
  const [filter, setFilter] = useState<"day" | "month" | "year">("day");

  const CardChart = ({ card }: { card: CardDto }) => {
    let summedContacts : any = {}
    let summedViewers : any = {}
    
    card.contacts.forEach((contact) => {
      let groupBy = "yyyy-MM-dd";

      switch (filter) {
        case "day":
          groupBy = "yyyy/MM/dd";
          break;
        case "month":
          groupBy = "yyyy/MM";
          break;
        case "year":
          groupBy = "yyyy";
          break;
      }

      const date = format(parseISO(contact.createdTimeInUtc), groupBy);

      summedContacts[date] = (summedContacts[date] || 0) + 1;
    });

    const chartData = Object.entries(summedContacts).map(e => ({
      date: e[0],
      count: e[1]
    }))

    return (
      <BarChart
        h={300}
        data={chartData}
        xAxisLabel={filter}
        yAxisLabel={t('count')}
        yAxisProps={{}}
        dataKey={'date'}
        series={[{ name: "count", color: "violet" }]}
      />
    );
  };

  return (
    <Box>
      <LoadingOverlay visible={!cards} />

      <Space h={"lg"} />

      <ButtonGroup>
        <Button onClick={() => setFilter("day")}>Day</Button>
        <Button onClick={() => setFilter("month")}>Month</Button>
        <Button onClick={() => setFilter("year")}>Year</Button>
      </ButtonGroup>

      <Space h={"lg"} />

      {cards?.map((c) => <CardChart card={c} />) ?? <Box></Box>}
    </Box>
  );
};
