import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../Packs/en.json";
import he from "../Packs/he.json";

export const initializeTranslations = () => {
    i18n.use(initReactI18next).init({
      resources: {
        he,
        en,
      },
      interpolation: {
        escapeValue: false,
      },
    });
};
