import axios from 'axios'
import { LoginUserRequest, LoginUserResponse } from './userContracts';
import { UNDEFINED_ERROR_RESPONSE } from '../Commons/commonResponses';

const API_BASE = process.env.REACT_APP_SO_N_SO_ENDPOINT;
const LOGIN_USER_QUERY = "/api/User/login-user";

export const loginUser = async (request: LoginUserRequest) : Promise<LoginUserResponse> => {
  try {
    const response = axios.post(`${API_BASE}${LOGIN_USER_QUERY}`, request);
    return (await response).data;
  } catch (error) {
    return UNDEFINED_ERROR_RESPONSE
  }
}
