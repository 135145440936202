import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { useGetAllInteractions } from "../../Hooks/Sonso/CustomerHooks/useGetAllInteractions";
import { Fragment, useEffect } from "react";
import { useGetCustomerByEmail } from "../../Hooks/Sonso/CustomerHooks/useGetCustomerByEmail";
import { LaptopCustomerAdministration } from "../Layouts/CustomerAdministration/LaptopCustomerAdministration";
import { TabletCustomerAdministration } from "../Layouts/CustomerAdministration/TabletCustomerAdministration";
import { useLaptopAndAbove } from "../../Hooks/Layout/useTabletAndAbove";
import { PotentialCustomerAdministration } from "../Organizem/PotentialCustomerAdministration";

type Props = {};

export const CustomerAdministration = (props: Props) => {
  const matches = useLaptopAndAbove();
  const customerSlice = useSelector(
    (state: RootState) => state.customerSlice.customer
  );
  const [
    getAllInteractions,
    getAllInteractionsLoading,
    getAllInteractionsResponse,
  ] = useGetAllInteractions("customer");

  const [getCustomerByEmail, customerByEmailLoading, customer] =
    useGetCustomerByEmail("customer");

  useEffect(() => {
    getAllInteractions({ customerEmail: customerSlice?.email! });
    getCustomerByEmail({ email: customerSlice?.email! });
  }, []);

  const potentialCustomerAdministration = <PotentialCustomerAdministration
    cards={customer?.customer?.cards}
  />;

  return (
    <Fragment>
      {matches ? (
        <LaptopCustomerAdministration
          potentialCustomerAdministration={potentialCustomerAdministration}
          customer={customer?.customer}
          customerIsLoading={customerByEmailLoading}
          interactions={getAllInteractionsResponse?.viewerInteractions}
          interactionsIsLoading={getAllInteractionsLoading}
        />
      ) : (
        <TabletCustomerAdministration
          potentialCustomerAdministration={potentialCustomerAdministration}
          customer={customer?.customer}
          customerIsLoading={customerByEmailLoading}
          interactions={getAllInteractionsResponse?.viewerInteractions}
          interactionsIsLoading={getAllInteractionsLoading}
        />
      )}
    </Fragment>
  );
};
