import React from "react";
import { InformativeSectionProps } from "./InformativeSectionsProps";
import { Box, Card, Group, rem, Stack, Text, Title } from "@mantine/core";

type Props = {};

export const ShlomiInformativeSection = ({
  sections,
  color,
}: Props & InformativeSectionProps) => {
  const circleSize = 35;

  const Section = ({
    id,
    title,
    text,
  }: {
    id: string;
    title: string;
    text: string;
  }) => (
    <Stack key={id} mb={"md"}>
      <Group
        pe={"xs"}
        bg={"dark.9"}
        w={"fit-content"}
        style={{
          borderRadius: `${rem(16)} ${rem(16)} ${rem(16)} ${rem(16)}`,
        }}
      >
        {/* Circle */}
        <Box w={circleSize} pos={"relative"} ms={-3}>
          <Box
            pos={"absolute"}
            top={rem(-circleSize / 2)}
            w={circleSize}
            h={circleSize}
            style={{ borderRadius: "50%", zIndex: 2 }}
            bg={color}
          ></Box>
        </Box>

        {/* Text Background */}
        <Title fz={"md"} ps={"xs"} c={"white"} p={2}>
          {title}
        </Title>
      </Group>
      <Card bg={"#FFF"}>
        <Text>{text}</Text>
      </Card>
    </Stack>
  );

  return (
    <Stack>
      {sections?.map((s) => (
        <Section key={s.id} {...s} />
      ))}
    </Stack>
  );
};
