import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import {
  customerReducers,
  sessionCustomerReducers,
} from "./features/Customer/customerSlice";
import { userReducers } from "./features/User/userSlice";
import { themeReducers } from "./features/Theming/themingSlice";
import {
  translationActions,
  translationReducers,
} from "./features/Translation/translationSlice";

const customerPersistConfig = {
  key: "sonso-customer-persist",
  storage,
};

const translationPersistConfig = {
  key: "sonso-language-persist",
  storage: storage,
};

const userPersistConfig = {
  key: "sonso-user-persist",
  storage,
};

const customerPersistedReducer = persistReducer(
  customerPersistConfig,
  customerReducers
);
const translationPersistedReducer = persistReducer(
  translationPersistConfig,
  translationReducers
);
const userPersistedReducer = persistReducer(userPersistConfig, userReducers);

const store = configureStore({
  reducer: {
    customerSlice: customerPersistedReducer,
    sessionCustomerSlice: sessionCustomerReducers,
    userSlice: userPersistedReducer,
    themeSlice: themeReducers,
    translationSlice: translationPersistedReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store, {}, () => {
  store.dispatch(
    translationActions.changeLanguage({
      language: store.getState().translationSlice.language,
    })
  );
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
