import {
  Box,
  Direction,
  Image,
  MantineColor,
  Paper,
  useMantineTheme,
} from "@mantine/core";
import { useRef } from "react";
import { CarouselImages } from "../../../../../Molecules/Carousels/CarouselImages";
import emblaCarouselAutoplay from "embla-carousel-autoplay";
import { ReactComponent as ClipShape } from "../../../../../../Assets/shlomi-crop.svg";

type Props = {
  carousel: {
    sources?: string[];
  };
  profile: {
    source: string;
    size: number;
  };
  color: MantineColor
  dir: Direction;
};

export const ShlomiProfile = ({ carousel, profile, dir, color }: Props) => {
  const theme = useMantineTheme();
  const autoplay = useRef(emblaCarouselAutoplay({ delay: 10_000 }));

  let position =
    dir === "ltr"
      ? {
          bottom: 0,
          left: 0,
        }
      : {
          bottom: 0,
          right: 0,
        };

  return (
    <Box>
      <Box pos={"relative"}>
        {/* Company */}
        {carousel.sources && (
          <CarouselImages
            sources={carousel.sources}
            maw={450}
            w={"100%"}
            plugins={[autoplay.current]}
            onMouseEnter={autoplay.current.stop}
            onMouseLeave={autoplay.current.reset}
            withControls={false}
            dir="ltr"
          />
        )}
        <Paper
          {...position}
          pos={"absolute"}
          style={{ overflow: "hidden" }}
          radius={"50%"}
          w={profile.size}
          h={profile.size}
          m={"4em"}
          mb={"2em"}
        >
          {/* Profile */}
          <Image src={profile.source} w={profile.size} h={profile.size} />
        </Paper>
        <ClipShape
          style={{
            position: "absolute",
            width: "102%",
            height: "auto",
            bottom: '5',
            left: '5',
            transform: dir === 'rtl' ? 'scale(1,1)' : 'scale(-1,1)',
            fill: color
          }}
        />
        <ClipShape
          style={{
            position: "absolute",
            width: "100%",
            height: "auto",
            bottom: '-1px',
            left: '0',
            transform: dir === 'rtl' ? 'scale(1,1)' : 'scale(-1,1)',
            fill: theme.white
          }}
        />
      </Box>
    </Box>
  );
};
