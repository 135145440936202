import { CustomerLoginForm } from "../Organizem/Forms/CustomerLoginForm";
import { useLoginCustomer } from "../../Hooks/Sonso/CustomerHooks/useLoginCustomer";
import { UserLoginForm } from "../Organizem/Forms/UserLoginForm";
import { SSLCertificate } from "../Atoms/SSLCertificate";
import {
  Accordion,
  Button,
  Center,
  Flex,
  Group,
  Image,
  Stack,
  Text,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import artBackground from "../../Assets/Images/art-background.png";
import { Link } from "react-router-dom";
import { AdministrationLoginPageRoute, AdministrationPageRoute } from "../App/Routes";

type Props = {};

export const Login = (props: Props) => {
  const [loginCustomer, customerLoading, customerResponse] = useLoginCustomer();

  return (
    <Stack>
      <Flex wrap={"wrap"}>
        <Image h={"100vh"} w={"30%"} src={artBackground} />
        <Center w={"70%"} p={"lg"}>
          <CustomerLoginForm
            isLoading={customerLoading}
            onSubmit={loginCustomer}
            emailError={customerResponse?.errors?.[0]?.message}
            passwordError={customerResponse?.errors?.[0]?.message}
          />
        </Center>
      </Flex>

      <Group justify="space-between">
        <Button component={Link} to={`/${AdministrationLoginPageRoute}`} variant="subtle">
          Administration
        </Button>

        <SSLCertificate  />
      </Group>
    </Stack>
  );
};
