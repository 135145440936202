import React from "react";
import { ContactUsLayoutProps } from "../../../../Forms/ContactUsForm";
import {
  Container,
  Paper,
  Space,
  Text,
} from "@mantine/core";

type Props = {};

export const DonaItaliaContactUsLayout = (
  props: ContactUsLayoutProps & Props
) => {
  return (
    <Container>
      <Paper p={"md"} bg={"#fff"} shadow="lg">
        <Text>Leave the details and we will get back to you</Text>
        <Space h={"md"} />
        {props.nameInput}
        <Space h={"md"} />
        {props.phoneNumberInput}
        <Space h={"md"} />
        {props.messageInput}
        <Space h={"md"} />
        {props.submitButton}
      </Paper>
    </Container>
  );
};
