import { useState } from "react";
import {
  UpdateCardRequest,
  UpdateCardResponse,
} from "../../../Services/Customers/customerContracts";
import { updateCard as rest } from "../../../Services/Customers/customerRest";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { customerActions } from "../../../Redux/features/Customer/customerSlice";
import { notifier } from "../../../Utilities/notifier";
import { useCustomerSelector } from "../../../Redux/Selectors/useCustomerSelector";
import { useTranslation } from "react-i18next";

export const useEditCard = (): [
  (
    request: Omit<UpdateCardRequest, "customerToken" | "userToken">
  ) => Promise<void>,
  boolean,
  UpdateCardResponse?
] => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.userSlice);
  const customer = useCustomerSelector();
  const [response, setResponse] = useState<UpdateCardResponse>();
  const [loading, setLoading] = useState<boolean>(false);

  const editCard = async (request: UpdateCardRequest) => {
    setResponse(undefined);
    setLoading(true);

    const response = await rest({
      ...request,
      customerToken: customer.token,
      userToken: user.token,
    });

    if (response.errors) notifier.notifyErrors(response.errors);
    else notifier.notifySuccess({ message: `${t("success")} - ${t('card is now available')}` });

    dispatch(customerActions.updateCard(response));

    setResponse(response);
    setLoading(false);
  };

  return [editCard, loading, response];
};
