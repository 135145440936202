import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoginUserResponse } from "../../../Services/Users/userContracts";

const initialState: LoginUserResponse = {
  user: undefined,
  errors: undefined,
  token: undefined,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginUser: (state, action: PayloadAction<LoginUserResponse>) => {
      state.user = action.payload.user;
      state.errors = action.payload.errors;
      state.token = action.payload.token;
    },
    logoutUser: (state) => {
      state.user = undefined;
      state.errors = undefined;
      state.token = undefined;
    },
  },
});

// Action creators are generated for each case reducer function
export const userActions = userSlice.actions;
export const userReducers = userSlice.reducer;
