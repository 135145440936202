import { Button, ButtonProps } from "@mantine/core";
import { FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";

type Props = { link: string };

export const FacebookLink = ({link, ...props}: Props & ButtonProps) => {
  return (
    <Button {...props} p={0} component={Link} to={link}>
      <FaInstagram>{link}</FaInstagram>
    </Button>
  );
};