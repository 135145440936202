import React, { useRef } from 'react'
import { RecommendationProp } from '../../../CardProps';
import { Avatar, Button, Container, ContainerProps, Group, Paper, PaperProps, Rating, Stack, Text, Title, useMantineTheme } from '@mantine/core';
import Autoplay from 'embla-carousel-autoplay';
import { Carousel } from '@mantine/carousel';
import { useDisclosure } from '@mantine/hooks';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

type Props = {
  recommendations?: RecommendationProp[];
  recommendationContainer?: PaperProps;
  containerProps?: ContainerProps,
};

export const ShlomiRecommendations = ({
  recommendationContainer,
  recommendations,
  containerProps
}: Props) => {
  const autoplay = useRef(Autoplay({ delay: 10_000 }));
  const theme = useMantineTheme()

  return (
    <Container {...containerProps} w={'100%'}>
      {recommendations && !!recommendations.length && (
        <Carousel
          plugins={[autoplay.current]}
          onMouseEnter={autoplay.current.stop}
          onMouseLeave={autoplay.current.reset}
          loop
          slideSize={'100%'}
          maw={'100%'}
          styles={{ control: { color: '#FFF', background: theme.colors.dark[7]} }}
          withControls={recommendations.length > 1}
        >
          {recommendations?.map((recommendation, i) => (
            <Carousel.Slide key={recommendation.companyName}>
              <Paper p={'xs'} {...recommendationContainer} bg={'#FFF'}>
                <Recommendation
                  key={recommendation.companyName}
                  {...recommendation}
                />
              </Paper>
            </Carousel.Slide>
          ))}
        </Carousel>
      )}
    </Container>
  )
}

const Recommendation = ({
  name,
  companyName,
  image,
  comment,
  starsOutOfTen,
}: RecommendationProp) => {
  const [isCommentExpanded, commentOperations] = useDisclosure(false);

  const MAXIMUM_STARS = 10;
  const NUMBER_OF_DISPLAYED_STARS = 5;

  const stars = starsOutOfTen / (MAXIMUM_STARS / NUMBER_OF_DISPLAYED_STARS);

  return (
    <Container>
      <Stack>
        <Group>
          <Avatar src={image} alt={name} />
          <Stack gap={0}>
            <Title fz={'md'}>{companyName}</Title>
            <Text fz={'sm'}>{name}</Text>
          </Stack>
        </Group>
        <Rating readOnly defaultValue={stars} />
      </Stack>

      <Text fz={'sm'} lineClamp={isCommentExpanded ? undefined : 2} size="sm">
        {comment}
      </Text>

      <Button
        variant="transparent"
        onClick={commentOperations.toggle}
      >
        {!isCommentExpanded ? <FaChevronDown /> : <FaChevronUp />}
      </Button>
    </Container>
  );
};