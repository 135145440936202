import { useState } from 'react'
import { ViewCardRequest, ViewCardResponse } from '../../../Services/Customers/customerContracts'
import { viewCard } from '../../../Services/Customers/customerRest'

export const useViewCard = () : [
  (request: ViewCardRequest) => Promise<void>,
  boolean,
  ViewCardResponse?,
] => {

  const [response, setResponse] = useState<ViewCardResponse>()
  const [loading, setLoading] = useState<boolean>(false)

  const fetch = async (request: ViewCardRequest) => {
    setLoading(true);
    setResponse(undefined);

    const cards = await viewCard(request);

    setLoading(false)
    setResponse(cards);
  }

  return [fetch, loading, response]
}
