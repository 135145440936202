import { ReactNode, useEffect, useRef, useState } from "react";
import {
  Box,
  Card,
  Drawer,
  DrawerProps,
  Grid,
  Space,
  Text,
  Title,
} from "@mantine/core";
import { useElementFollowMe } from "../../../../../../Hooks/Layout/useElementFollowMe";
import { CardProps } from "../../../CardProps";
import { CardProps as MantineCardProps } from "@mantine/core";

type Props = {
  card: CardProps;
  profile?: ReactNode;
  metadata?: ReactNode;
  gallery?: ReactNode;
  questionsAndAnswers?: ReactNode;
  informativeSections?: ReactNode;
  recommendations?: ReactNode;
  contactForm?: ReactNode;
  socialLinks?: ReactNode;
  contactUsLinks?: ReactNode;
  getContactUsButton?: ReactNode;
  mah?: number;
  space?: number;
  drawer?: {
    props: DrawerProps;
    node: ReactNode;
  };
  cardProps?: MantineCardProps;
};

export const DonaItaliaCardTemplate1 = ({
  card,
  space,
  cardProps,
  ...props
}: Props) => {

  const SIDE_BAR_SIZE = 2;
  const CONTENT_WIDTH = 12 - SIDE_BAR_SIZE;

  return (
    <Card p={"md"} shadow="lg" miw={320} maw={430} {...cardProps} styles={{root: {overflow: 'visible'}}}>
      <Grid gutter={'5px'}>
        <Grid.Col span={SIDE_BAR_SIZE}>
          <Box pos={"sticky"} top={'16px'}>
            {props.socialLinks}
          </Box>
        </Grid.Col>
        <Grid.Col span={CONTENT_WIDTH}>
          {props.profile}

          {/* Metadata */}
          <Title order={6} ta={"center"}>
            {card.metadata.title}
          </Title>

          <Text ta={"center"}>{card.metadata.about}</Text>

          <Space h={"lg"} />

          {/* Information Section */}
          {props.informativeSections}

          <Space h={"lg"} />

          {/* Recommendations */}
          {props.recommendations}

          <Space h={"lg"} />

          {props.contactForm}
        </Grid.Col>
      </Grid>
      <Card.Section>
        {/* Gallery */}
        {props.gallery}
      </Card.Section>
      <Grid>
        <Grid.Col span={CONTENT_WIDTH}>
          {/* TODO: Calender */}
          <Space h={"lg"} />

          {props.getContactUsButton}

          <Space h={"lg"} />

          {/* Questions And Answers Section */}
          {props.questionsAndAnswers}

          {/* TODO: Products */}
          <Space h={"lg"} />

          {/* Products */}
          {props.contactForm}
        </Grid.Col>
        <Grid.Col span={SIDE_BAR_SIZE}>
          <Box pos={"sticky"} top={'16px'}>{props.contactUsLinks}</Box>
        </Grid.Col>
      </Grid>
      {props.drawer && (
        <Drawer position="bottom" radius={"lg"} {...props.drawer.props}>
          {props.drawer.node}
        </Drawer>
      )}
    </Card>
  );
};
