import { ButtonProps, Group, Image, Stack } from '@mantine/core'
import React from 'react'
import { SocialLinksProp } from '../../../CardProps'
import { FacebookLink } from '../../../../../Atoms/Links/FacebookLink'
import { GlobeLink } from '../../../../../Atoms/Links/GlobeLink'
import { InstagramLink } from '../../../../../Atoms/Links/InstagramLink'
import { MailClipboardLink } from '../../../../../Atoms/Links/MailClipboardLink'


type Props = {
  socialLinks: SocialLinksProp
}

export const RowSocialLinks2 = ({socialLinks, ...buttonProps }: Props & ButtonProps) => {
  return (
    <Group>
      {socialLinks.facebook && <FacebookLink {...buttonProps} link={socialLinks.facebook} />}
      {socialLinks.web && <GlobeLink {...buttonProps} link={socialLinks.web} />}
      {socialLinks.instagram && <InstagramLink {...buttonProps} link={socialLinks.instagram} />}
      {socialLinks.email && <MailClipboardLink {...buttonProps} email={socialLinks.email} />}
    </Group>
  )
}