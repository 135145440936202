import { Box, getGradient, Group, MantineColor, MantineTheme, Title, useDirection, useMantineTheme } from "@mantine/core";
import React from "react";

type Props = {
  title: string;
  color: MantineColor;
};

export const DonaItaliaTitle = ({ color, title }: Props) => {
  const dir = useDirection()
  const theme = useMantineTheme()
  const gradient = getGradient(
    {
      deg: dir.dir === 'ltr' ? 90 : 270,
      from: color,
      to: theme.white!,
    },
    theme
  )

  return (
    <Group>
      <Title order={6}>{title}</Title>
      <Box h={5} bg={gradient} style={{ flexGrow: 1 }}></Box>
    </Group>
  );
};
