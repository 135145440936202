import {
  Box,
  Button,
  LoadingOverlay,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export type ContactUsLayoutProps = {
  loadingOverlay: ReactNode;
  nameInput: ReactNode;
  phoneNumberInput: ReactNode;
  messageInput: ReactNode;
  submitButton: ReactNode;
};

type Props = {
  isLoading: boolean;
  Layout: React.FC<ContactUsLayoutProps>;
  onSubmit: (values: ContactUsSubmitValues) => void;
};

export type ContactUsSubmitValues = {
  number: string;
  name: string;
  message: string;
};

export const ContactForm1 = (props: Props) => {
  const { t } = useTranslation();
  const form = useForm<ContactUsSubmitValues>({
    initialValues: {
      number: "",
      name: "",
      message: "",
    },
  });

  return (
    <Box
      onSubmit={form.onSubmit((values) => props.onSubmit(values))}
      pos={"relative"}
      component="form"
    >
      {
        <props.Layout
          loadingOverlay={
            <LoadingOverlay
              visible={props.isLoading}
              zIndex={1000}
              overlayProps={{ radius: "sm", blur: 2 }}
            />
          }
          nameInput={
            <TextInput
              radius={"xs"}
              placeholder={t("name")}
              key={form.key("name")}
              {...form.getInputProps("name")}
            />
          }
          phoneNumberInput={
            <TextInput
              radius={"xs"}
              placeholder={t("phone number")}
              key={form.key("number")}
              {...form.getInputProps("number")}
            />
          }
          messageInput={
            <Textarea
              autosize
              description={t('optional *')}
              minRows={4}
              radius={"xs"}
              placeholder={t("message")}
              key={form.key("message")}
              {...form.getInputProps("message")}
            />
          }
          submitButton={
            <Button
              radius={"xs"}
              w={"100%"}
              disabled={props.isLoading}
              type="submit"
            >
              {t("submit")}
            </Button>
          }
        />
      }
    </Box>
  );
};
