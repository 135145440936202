import { notifications } from "@mantine/notifications";
import i18next from "i18next";

type Error = {
  message: string
}

type Success = {
  message: string
}

export const notifier = {
  
  notifyErrors: (errors: Error[]) => {
    const t = i18next.t
    errors.forEach(e => {
      notifications.show({
        title: t('error'),
        message: t(e.message),
        color: 'red',
        position: 'bottom-right'
      })
    })
  },
  notifySuccess: (success: Success) => {
    const t = i18next.t
      notifications.show({
        title: t('success'),
        message: t(success.message),
        color: 'green',
        position: 'bottom-right'
      })
  }
}