import { Box, BoxProps, Button, ButtonProps, Group } from "@mantine/core";
import React, { PropsWithChildren, ReactNode } from "react";

type Props = {
  buttonChildren: ReactNode;
  endSection: ReactNode;
  onClick: () => void
};

export const ButtonWithEndSection = ({
  buttonChildren,
  endSection,
  ...buttonProps
}: Props & ButtonProps & BoxProps) => {
  return (
    <Button {...buttonProps}>
      <Group justify="space-between">
        {buttonChildren}
        {/* End */}
        <Box>{endSection}</Box>
      </Group>
    </Button>
  );
};
