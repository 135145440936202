import { useState } from "react";
import {
  GetAllInteractionsRequest,
  GetAllInteractionsResponse,
} from "../../../Services/Customers/customerContracts";
import { getAllInteractions as rest } from "../../../Services/Customers/customerRest";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { NOT_LOGGED_IN_ERROR_RESPONSE } from "../../../Services/Commons/commonResponses";
import { notifier } from "../../../Utilities/notifier";
import { useCustomerSelector } from "../../../Redux/Selectors/useCustomerSelector";

export const useGetAllInteractions = (
  as: "user" | "customer"
): [
  (
    request: Omit<GetAllInteractionsRequest, "userToken" | "customerToken">
  ) => Promise<void>,
  boolean,
  GetAllInteractionsResponse?
] => {
  const user = useSelector((state: RootState) => state.userSlice);
  const customer = useCustomerSelector();
  const [response, setResponse] = useState<GetAllInteractionsResponse>();
  const [loading, setLoading] = useState<boolean>(false);

  const getAllInteractions = async (request: GetAllInteractionsRequest) => {
    if (
      (!user.token && as === "user") ||
      (!customer.token && as === "customer")
    ) {
      setResponse(NOT_LOGGED_IN_ERROR_RESPONSE);
      notifier.notifyErrors(NOT_LOGGED_IN_ERROR_RESPONSE.errors);
      return;
    }

    setResponse(undefined);
    setLoading(true);

    let response;
    if (as === "customer") {
      response = await rest({
        ...request,
        customerToken: customer.token,
      });
    } else {
      response = await rest({
        ...request,
        userToken: user.token,
      });
    }

    setResponse(response);
    setLoading(false);
  };

  return [getAllInteractions, loading, response];
};
