import { Button, ButtonProps } from "@mantine/core";
import React from "react";
import { FaFacebook } from "react-icons/fa";
import { Link } from "react-router-dom";

type Props = { link: string };

export const InstagramLink = ({link, ...props}: Props & ButtonProps) => {
  return (
    <Button {...props} p={0} component={Link} to={link}>
      <FaFacebook>{link}</FaFacebook>
    </Button>
  );
};
