export const UNAUTHORIZED_ERROR_RESPONSE = {
  errors: [{ errorCode: 401, message: "unauthorized" }],
};

export const UNDEFINED_ERROR_RESPONSE = {
  errors: [{ errorCode: -1, message: "general error" }],
};

export const NOT_LOGGED_IN_ERROR_RESPONSE = {
  errors: [{ errorCode: -2, message: "not logged in error" }],
};