import { Carousel, CarouselProps } from "@mantine/carousel";
import { Box, Image } from "@mantine/core";
import { forwardRef } from "react";

type Props = {
  sources: string[];
  slideBorderRadius?: string;
};

export const CarouselImages = forwardRef<HTMLDivElement, Props & CarouselProps>(
  ({ sources, slideBorderRadius, ...carouselProps }, ref) => {
    return (
      <Carousel {...carouselProps} ref={ref}>
        {sources.map((s) => (
          <Carousel.Slide key={s}>
            <Box
              style={{ borderRadius: slideBorderRadius, overflow: "hidden" }}
            >
              <Image mah={carouselProps.h} src={s} />
            </Box>
          </Carousel.Slide>
        ))}
      </Carousel>
    );
  }
);
