import { useState } from 'react'
import { 
  ExampleCardsRequest, 
  GetExampleCardsResponse } from '../../../Services/Customers/customerContracts'
import { getExampleCards } from '../../../Services/Customers/customerRest'
import { notifications } from '@mantine/notifications'
import { useTranslation } from 'react-i18next'
import { notifier } from '../../../Utilities/notifier'

export const useGetExampleCards = () : [
  (request: ExampleCardsRequest) => Promise<void>,
  boolean,
  GetExampleCardsResponse?,
] => {
  const {t} = useTranslation();
  const [response, setResponse] = useState<ExampleCardsRequest>()
  const [loading, setLoading] = useState<boolean>(false)

  const fetch = async (request: ExampleCardsRequest) => {
    setLoading(true);
    setResponse(undefined);

    const response = await getExampleCards(request);

    setLoading(false)
    setResponse(response);

    if(response.errors)
      notifier.notifyErrors(response.errors.filter(e => e.message) as any)

  }

  return [fetch, loading, response]
}
