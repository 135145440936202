import React, { useEffect } from "react";
import { useGetApplicationRecommendations } from "../../../Hooks/Sonso/ApplicationHooks/useGetApplicationRecommendations";
import { LoadingOverlay, Skeleton, Stack, Title } from "@mantine/core";
import { Recommendations1 } from "../DigitalCards/Templates/Elements/Recommendations/Recommendations1";
import { Carousel } from "@mantine/carousel";
import { ShlomiCardLayout3 } from "../DigitalCards/Templates/Layouts/Containers/ShlomiCardLayout3";

type Props = {};

export const ExampleApplicationRecommendations = (props: Props) => {
  const [
    getAllRecommendationFetch,
    getAllRecommendationLoading,
    getAllRecommendationResponse,
  ] = useGetApplicationRecommendations();

  useEffect(() => {
    getAllRecommendationFetch({});
  }, []);

  if (getAllRecommendationResponse?.errors) {
    return (
      <Carousel dir="ltr" slideSize={"66%"}>
        {[1, 2, 3].map((i) => (
          <Carousel.Slide key={i}>
            <ShlomiCardLayout3
              profile={<Skeleton animate={false} radius={0} h={150} />}
              metadata={<Skeleton animate={false} h={25} />}
              socialLinks={<Skeleton animate={false} h={150} />}
              informativeSections={<Skeleton animate={false} h={25} />}
            />
          </Carousel.Slide>
        ))}
      </Carousel>
    );
  }

  return (
    <Stack>
      <LoadingOverlay visible={getAllRecommendationLoading} />

      <Stack ta={"center"}>
        <Title order={2}>
          See What Our{" "}
          <Title component={"span"} order={2} c="violet">
            Clients
          </Title>
          <br />
          Are Saying
        </Title>
      </Stack>

      <Recommendations1
        recommendations={getAllRecommendationResponse?.recommendations?.map(
          (r) => ({
            companyName: r.companyName,
            starsOutOfTen: r.starsOutOfTen,
            comment: r.comment,
            image: r.image,
            name: r.name,
          })
        )}
      />
    </Stack>
  );
};
