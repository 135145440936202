import {
  Box,
  BoxProps,
  Button,
  Center,
  FileButton,
  FileInput,
  Group,
  Image,
  Input,
  InputError,
  Loader,
  Paper,
  Progress,
  Stack,
  Text,
  TextInput,
  TextInputProps,
} from "@mantine/core";
import { useUploadImage } from "../../../Hooks/Sonso/ImageHooks/useUploadImage";
import { useEffect } from "react";
import { sonsoImageProvider } from "../../../Services/ImageProvider/Sonso/sonsoImageProvider";
import noImagePath from "../../../Assets/Images/no-image.jpg";
import { FaTrash } from "react-icons/fa";
import { FiUpload } from "react-icons/fi";

type Props = {
  owner: string;
  imageUploaded: (imageId?: string) => void;
  removePressed?: () => void;
  containerProps?: BoxProps;
};

export const FileInputWithLoading = ({
  owner,
  children,
  imageUploaded,
  removePressed,
  containerProps,
  ...textInputProps
}: Props & TextInputProps) => {
  const [upload, progress, loading, response] = useUploadImage("customer");

  const handleFileChange = (payload: File | null) => {
    if (!payload) return;
    upload({
      image: payload,
      owner: owner,
      alt: undefined,
    });
  };

  useEffect(() => {
    if (response?.errors || !response?.image) return;
    imageUploaded(response.image.id);
  }, [response]);

  return (
    <Stack miw={100} w={"fit-content"} gap={"xs"} {...containerProps}>
      <Input.Wrapper
        style={{
          border: textInputProps.error
            ? "1px solid var(--mantine-color-error)"
            : undefined,
        }}
        pos={"relative"}
      >
        <Image
          h={"auto"}
          w={200}
          src={
            textInputProps.value &&
            sonsoImageProvider.getImage({
              imageName: textInputProps.value.toString(),
            }).uri
          }
          fallbackSrc={noImagePath}
        />

        <FileButton onChange={handleFileChange} accept="image/png,image/jpeg">
          {(props) => (
            <Box pos={'absolute'} bottom={'1em'} w={'100%'}>
              <Center fz={"md"}>
                <Button w={'100'} {...props}>
                  <FiUpload />
                </Button>
              </Center>
            </Box>
          )}
        </FileButton>
        
        <Button
          top={"1em"}
          right={"1em"}
          pos={"absolute"}
          variant="light"
          c={"red"}
          onClick={() => {
            imageUploaded("");
            removePressed && removePressed();
          }}
        >
          <FaTrash />
        </Button>
      </Input.Wrapper>
      <InputError>{textInputProps.error}</InputError>
      <TextInput w={0} h={0} opacity={0} {...textInputProps} />
      <Group>
        <Progress w={loading ? undefined : "100%"} miw={90} value={progress} />
        {loading && <Loader size={10} />}
      </Group>
    </Stack>
  );
};
