import React, { ReactNode, useState } from "react";
import { CardForm } from "./CardForm/CardForm";
import { usePublishNewCard } from "../../../Hooks/Sonso/CustomerHooks/usePublishNewCard";
import { Box, Divider } from "@mantine/core";
import { SearchCustomers } from "./SearchCustomers";
import { CustomerDto } from "../../../Services/Customers/customerContracts";

type Props = {};

export const AdministrationPublishCardForm = (props: Props) => {
  const [publishCardCustomer, setPublishCardCustomer] = useState<CustomerDto>();
  const [publishCard, publishCardLoading, publishCardResponse] =
    usePublishNewCard();

  let content: ReactNode = null;

  if (publishCardCustomer)
    content = (
      <>
        <Divider mt={"md"} />
        <CardForm
          isLoading={publishCardLoading}
          onSubmit={(val) =>
            publishCard({
              ...val,
              email: publishCardCustomer.email,
            })
          }
          owner={publishCardCustomer.email}
        />
      </>
    );

  return (
    <Box pos={'relative'}>
      <SearchCustomers
        selectedCustomer={publishCardCustomer}
        onCustomerSelected={setPublishCardCustomer}
      />
      {content}
    </Box>
  );
};
