import {
  Avatar,
  Box,
  Center,
  Direction,
  Flex,
  Paper,
  rem,
} from "@mantine/core";
import React, { useEffect, useRef } from "react";
import { CarouselImages } from "../../../../../Molecules/Carousels/CarouselImages";
import emblaCarouselAutoplay from "embla-carousel-autoplay";

type Props = {
  carousel: {
    sources?: string[];
    size: number;
  };
  profile: {
    source: string;
    size: number;
  };
  radius: number;
  dir: Direction;
};

export const DonaItaliaProfile = ({
  carousel,
  profile,
  radius,
  dir,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const autoplay = useRef(emblaCarouselAutoplay({ delay: 10_000 }));
  const borderRadius =
    dir === "ltr" ? `0 ${radius}px 0 0` : `${radius}px 0 0 0`;

  let containerHeight = 0;

  useEffect(() => {
    if (containerRef.current)
      containerHeight = containerRef.current.getBoundingClientRect().height;
  }, [containerRef.current]);

  return (
    <Box pos={"relative"} mb={`${profile.size / 1.5}px`}>
      {/* Carousel */}
      <Box
        style={{
          overflow: "hidden",
          borderRadius: borderRadius,
        }}
      >
        {carousel.sources && (
          <CarouselImages
            ref={containerRef}
            sources={carousel.sources}
            maw={450}
            w={"100%"}
            h={"auto"}
            plugins={[autoplay.current]}
            onMouseEnter={autoplay.current.stop}
            onMouseLeave={autoplay.current.reset}
            withControls={false}
          />
        )}
      </Box>

      {/* Profile */}
      <Box
        pos={"absolute"}
        left={`calc(50% - ${profile.size / 2}px)`}
        top={`calc(100% - ${profile.size / 2}px)`}
      >
        <Paper
          style={{ borderRadius: 900 }}
          w={profile.size}
          h={profile.size}
          shadow={" "}
        >
          <Center h={"100%"} w={"100%"}>
            <Paper p={5} m={0} radius={900} shadow="md">
              <Avatar size={100} src={profile.source} />
            </Paper>
          </Center>
        </Paper>
      </Box>
    </Box>
  );
};
