import { Box, Divider, Group, Skeleton, Stack, Text } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  interactionsCount: number | undefined;
  viewersCount: number | undefined;
};

export const StatisticsPanel = (props: Props) => {
  const { t } = useTranslation();

  return (
    <Group justify="space-around">
      <Stack>
        <Skeleton visible={props.viewersCount === undefined}>
          <Text ta={"center"}>{t("viewers")}</Text>
        </Skeleton>
        <Skeleton visible={props.viewersCount === undefined}>
          <Text ta={"center"}>{props.viewersCount}</Text>
        </Skeleton>
      </Stack>

      <Divider orientation="vertical" />

      <Stack>
        <Skeleton visible={props.interactionsCount === undefined}>
          <Text ta={"center"}>{t("interactions")}</Text>
        </Skeleton>
        <Skeleton visible={props.interactionsCount === undefined}>
          <Text ta={"center"}>{props.interactionsCount}</Text>
        </Skeleton>
      </Stack>
    </Group>
  );
};
