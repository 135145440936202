import React from "react";
import { useLoginUser } from "../../Hooks/Sonso/UserHooks/useLoginUser";
import { UserLoginForm } from "../Organizem/Forms/UserLoginForm";

type Props = {};

export const AdministrationLogin = (props: Props) => {
  const [loginUser, userLoading, userResponse] = useLoginUser();

  return (
    <UserLoginForm
      isLoading={userLoading}
      onSubmit={loginUser}
      usernameError={userResponse?.errors?.[0]?.message}
      passwordError={userResponse?.errors?.[0]?.message}
    />
  );
};
