import { Box, Divider, LoadingOverlay } from "@mantine/core";
import React, { ReactNode, useState } from "react";
import {
  CardDto,
  CustomerDto,
} from "../../../Services/Customers/customerContracts";
import { SearchCustomersCards } from "./SearchCustomersCards";
import { CardForm } from "./CardForm/CardForm";
import { useEditCard } from "../../../Hooks/Sonso/CustomerHooks/useEditCard";

type Props = {};

export const AdministrationEditCardForm = (props: Props) => {
  const [selectedEditCard, setSelectedEditCard] = useState<{
    customer: CustomerDto;
    card: CardDto;
  }>();
  const [editCard, editCardLoading] = useEditCard();

  let content: ReactNode;

  if (selectedEditCard?.customer)
    content = (
      <>
        <Divider mt={"md"} />
        <CardForm
          isLoading={editCardLoading}
          onSubmit={(val) =>
            editCard({
              ...val,
              email: selectedEditCard.customer.email,
            })
          }
          owner={selectedEditCard.customer.email}
          card={selectedEditCard.card}
        />
      </>
    ); 

  return (
    <Box pos={'relative'}>
      <SearchCustomersCards
        selectedCard={selectedEditCard}
        onCardSelected={setSelectedEditCard}
      />

      {content}
    </Box>
  );
};
