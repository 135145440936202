import { generateColors } from "@mantine/colors-generator";
import { colorsTuple, MantineThemeOverride, rem } from "@mantine/core";

export const mantineLight : MantineThemeOverride = {
  primaryShade: 3,
  primaryColor: 'violet',
  fontSizes: {
    xl: 'min(9vw, 1.2em)',
    lg: 'min(7vw, 1em)',
    md: 'min(6vw, .9em)',
    sm: 'min(5vw, .8em)',
    xs: 'min(4vw, .6em)',
  },
  headings:{
    sizes:{
      h1: { fontSize: 'min(9vw, 2rem)' },
      h2: { fontSize: 'min(8vw, 1.8rem)' },
      h3: { fontSize: 'min(7vw, 1.6rem)' },
      h4: { fontSize: 'min(6vw, 1.4rem)' },
      h5: { fontSize: 'min(5vw, 1.2rem)' },
      h6: { fontSize: 'min(4vw, 1rem)' },
    }
  },
  radius: {
    md: '100px',
    sm: '20px',
    xs: '10px'
  },
  fontFamily: 'Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji',
  white: "#F5F5F5",
  black: "#212121",
  colors: {
    dark: colorsTuple('#2E2A33'),
    violet: generateColors("#B981F8"),
    green: [
      "#aed26a",
      "#a4cd58",
      "#9ac745",
      "#8bb33e",
      "#7b9f37",
      "#6c8b30",
      "#4d6423",
      "#3e501c",
      "#2e3c15",
      "#1f280e",
      "#0f1407"
    ],
    gray:[
      "#fafaf9",
      "#e5e4e2",
      "#cecdcb",
      "#b7b6b5",
      "#a0a09e",
      "#898988",
      "#737271",
      "#5c5b5a",
      "#2e2e2d",
      "#171717",
    ]
  }
}