import {
  Accordion,
  Container,
  Overlay,
  Tabs,
  TextInput,
  Title,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { CustomerRegisterForm } from "../Organizem/Forms/CustomerRegisterForm";
import { useRegisterCustomer } from "../../Hooks/Sonso/CustomerHooks/useRegisterCustomer";
import { FaRegUser } from "react-icons/fa";
import { CardForm } from "../Organizem/Forms/CardForm/CardForm";
import { usePublishNewCard } from "../../Hooks/Sonso/CustomerHooks/usePublishNewCard";
import { CiGlobe } from "react-icons/ci";
import { Website } from "../Organizem/Administration/Website";
import { useEditCard } from "../../Hooks/Sonso/CustomerHooks/useEditCard";
import { useState } from "react";
import {
  CardDto,
  CustomerDto,
} from "../../Services/Customers/customerContracts";
import { SearchCustomersCards } from "../Organizem/Forms/SearchCustomersCards";
import { SearchCustomers } from "../Organizem/Forms/SearchCustomers";
import { useLaptopAndAbove } from "../../Hooks/Layout/useTabletAndAbove";
import { AdministrationPublishCardForm } from "../Organizem/Forms/AdministrationPublishCardForm";
import { AdministrationEditCardForm } from "../Organizem/Forms/AdministrationEditCardForm";
import { CustomerDisplay } from "../Organizem/Customers/CustomerDisplay";

type Props = {};

const CUSTOMER_TAB_NAME = "customer";
const WEBSITE_TAB_NAME = "website";

export const Administration = (props: Props) => {
  const { t } = useTranslation();
  const isLaptopAndAbove = useLaptopAndAbove();
  const [selectedEditCard, setSelectedEditCard] = useState<{
    customer: CustomerDto;
    card: CardDto;
  }>();
  const [registerCustomer, customerRegisterLoading] = useRegisterCustomer();
  const [editCard, editCardLoading] = useEditCard();

  return (
    <Container>
      <Title order={2}>{t("administration")}</Title>
      <Tabs
        mt={"md"}
        variant="outline"
        orientation={isLaptopAndAbove ? "vertical" : "horizontal"}
        defaultValue={CUSTOMER_TAB_NAME}
      >
        <Tabs.List>
          <Tabs.Tab leftSection={<FaRegUser />} value={CUSTOMER_TAB_NAME}>
            {t("customer")}
          </Tabs.Tab>
          <Tabs.Tab leftSection={<CiGlobe />} value={WEBSITE_TAB_NAME}>
            {t("website")}
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value={CUSTOMER_TAB_NAME}>
          <Container>
            <Accordion >
              {/* Search Customer */}
              <Accordion.Item value="customer display">
                <Accordion.Control>
                  {t("customers search")}
                </Accordion.Control>
                <Accordion.Panel>
                  <CustomerDisplay />
                </Accordion.Panel>
              </Accordion.Item>

              {/* Customer Registration */}
              <Accordion.Item value="manual customer registration">
                <Accordion.Control>
                  {t("manual customer registration")}
                </Accordion.Control>
                <Accordion.Panel>
                  <CustomerRegisterForm
                    isLoading={customerRegisterLoading}
                    onSubmit={(val) =>
                      registerCustomer({
                        companyName: val.companyName,
                        email: val.email,
                        name: val.name,
                        password: val.password,
                        phoneNumber: val.phoneNumber,
                      })
                    }
                  />
                </Accordion.Panel>
              </Accordion.Item>

              {/* Edit Card For Customer*/}
              <Accordion.Item value="edit card for customer">
                <Accordion.Control>{t("edit card")}</Accordion.Control>
                <Accordion.Panel>
                  <AdministrationEditCardForm />
                </Accordion.Panel>
              </Accordion.Item>

              {/* Create Card For Customer*/}
              <Accordion.Item value="create card for customer">
                <Accordion.Control>{t("create card")}</Accordion.Control>
                <Accordion.Panel>
                  <AdministrationPublishCardForm />
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </Container>
        </Tabs.Panel>

        <Tabs.Panel value={WEBSITE_TAB_NAME}>
          <Website />
        </Tabs.Panel>
      </Tabs>
    </Container>
  );
};
