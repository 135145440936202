import axios from "axios";

const API_BASE = process.env.REACT_APP_SO_N_SO_ENDPOINT;

export const USER_SCHEME_NAME = "user-auth";
export const CUSTOMER_SCHEME_NAME = "customer-auth";

export const api = axios.create({
  withCredentials: true,
  baseURL: API_BASE,
});

export const unauthorizedApi = axios.create({
  baseURL: API_BASE,
});