import {
  Blockquote,
  Box,
  Button,
  Checkbox,
  ColorInput,
  Container,
  Divider,
  Fieldset,
  Flex,
  Group,
  LoadingOverlay,
  Modal,
  NumberInput,
  SimpleGrid,
  Space,
  Stack,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { isEmail, isNotEmpty, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import rules from "../../../../Utilities/rules";
import { errors } from "../../../../Utilities/errors";
import { FileInputWithLoading } from "../../../Molecules/Form/FileInputWithLoading";
import { useRef } from "react";
import { TitledCard } from "../../../Molecules/Cards/TitledCard";
import {
  CardDto,
  ColorsDto,
  InformativeCardsDto,
  MetadataDto,
  NameDto,
  RecommendationDto,
  SocialLinksDto,
  TemplateDto,
} from "../../../../Services/Customers/customerContracts";
import { RecommendationForm } from "../../../Molecules/Form/RecommendationForm";
import { InformativeSectionForm } from "../../../Molecules/Form/InformativeSectionForm";

type SubmitValues = {
  id: number;
  name: NameDto;
  template: TemplateDto;
  metadata: MetadataDto;
  colors: ColorsDto;
  profile: string;
  logo?: string;
  company: string[];
  gallery: string[];
  socialLinks: SocialLinksDto;
  informationSections: InformativeCardsDto;
  questionsAndAnswers: InformativeCardsDto;
  recommendations: RecommendationDto[];
};

type Props = {
  isLoading: boolean;
  onSubmit: (val: SubmitValues) => void;
  title?: string;
  card?: CardDto;
  owner: string;
};

const EMPTY_FORM: SubmitValues = {
  id: -1,
  name: { name: "" },
  logo: "",
  template: {
    leftToRight: false,
    darkOrLight: false,
    type: 1,
  },
  profile: "",
  metadata: {
    title: "",
    about: "",
  },
  colors: {
    primary: "",
    secondary: "",
    accent: "",
  },
  socialLinks: {
    email: "",
    web: "",
    facebook: "",
    instagram: "",
    phone: "",
    whatsapp: "",
  },
  gallery: [],
  company: [""],
  recommendations: [],
  informationSections: { variant: 0, sections: [] },
  questionsAndAnswers: { variant: 0, sections: [] },
};

export const CardForm = ({ card, ...props }: Props) => {
  const { t } = useTranslation();
  const [opened, { open, close }] = useDisclosure(false);
  const submitButton = useRef<HTMLButtonElement>(null);

  const form = useForm<SubmitValues>({
    initialValues: {
      id: card?.id ?? EMPTY_FORM.id,
      name: card?.name ?? EMPTY_FORM.name,
      metadata: card?.metadata ?? EMPTY_FORM.metadata,
      template: card?.template ?? EMPTY_FORM.template,
      profile: card?.profile.id ?? EMPTY_FORM.profile,
      socialLinks: {
        email: card?.socialLinks.email ?? EMPTY_FORM.socialLinks.email,
        facebook: card?.socialLinks.facebook ?? EMPTY_FORM.socialLinks.facebook,
        phone: card?.socialLinks.phone ?? EMPTY_FORM.socialLinks.phone,
        instagram:
          card?.socialLinks.instagram ?? EMPTY_FORM.socialLinks.instagram,
        web: card?.socialLinks.web ?? EMPTY_FORM.socialLinks.web,
        whatsapp: card?.socialLinks.whatsapp ?? EMPTY_FORM.socialLinks.whatsapp,
      },
      logo: card?.logo?.id ?? EMPTY_FORM.logo,
      company: card?.company.map((i) => i.id) ?? EMPTY_FORM.company,
      gallery: card?.gallery?.map((i) => i.id) ?? EMPTY_FORM.gallery,
      colors: {
        primary: card?.colors.primary ?? EMPTY_FORM.colors.primary,
        secondary: card?.colors.secondary ?? EMPTY_FORM.colors.secondary,
        accent: card?.colors.accent ?? EMPTY_FORM.colors.accent,
      },
      recommendations:
        card?.recommendations.map((r) => ({
          ...r,
          image: r.image ?? "",
        })) ?? EMPTY_FORM.recommendations,
      informationSections:
        card?.informationCards ?? EMPTY_FORM.informationSections,
      questionsAndAnswers:
        card?.questionsAndAnswersCards ?? EMPTY_FORM.questionsAndAnswers,
    },
    validate: {
      name: (val) => {
        const error = rules.card.name.validate(val.name);
        return error ? t(error.message) : undefined;
      },
      template: (val) => {
        const error = rules.card.template.validate(val);
        return error ? t(error.message) : undefined;
      },
      metadata: {
        title: (val) => {
          const error = rules.card.metadata.title.validate(val);
          return error ? t(error.message) : undefined;
        },
        about: (val) => {
          const error = rules.card.metadata.about.validate(val);
          return error ? t(error.message) : undefined;
        },
      },
      socialLinks: {
        email: (val) => {
          const error = isEmail(
            errors.card.socialLinks.EMAIL_IS_NOT_VALID.message
          )(val);
          return error ? t((error as string)!) : undefined;
        },
      },
      // informationSections: (values) => {
      //   const errors = values.sections.map(val => rules.card.. val.title )
      // },
      profile: (val) => isNotEmpty(t("profile image not set"))(val),
      company: (values) =>
        values.length === 0 ? t("at least one company image") : undefined,
      colors: {
        primary: (val) => {
          const error = rules.card.colors.primary.validate(val);
          return error ? t(error.message) : undefined;
        },
        secondary: (val) => {
          const error = rules.card.colors.secondary.validate(val);
          return error ? t(error.message) : undefined;
        },
        accent: (val) => {
          const error = rules.card.colors.accent.validate(val);
          return error ? t(error.message) : undefined;
        },
      },
    },
  });

  const recommendationItems = form
    .getValues()
    .recommendations?.map((recommendation, index) => (
      <RecommendationForm
        key={index}
        recommendationName={index.toString()}
        onDeletionClick={() => form.removeListItem("recommendations", index)}
        inputs={{
          name: {
            key: form.key(`recommendations.${index}.name`),
            props: form.getInputProps(`recommendations.${index}.name`),
          },
          company: {
            key: form.key(`recommendations.${index}.companyName`),
            props: form.getInputProps(`recommendations.${index}.companyName`),
          },
          image: {
            key: form.key(`recommendations.${index}.image`),
            owner: props.owner,
            onImageUploaded: (id) =>
              form.setFieldValue(`recommendations.${index}.image`, id),
            props: form.getInputProps(`recommendations.${index}.image`),
          },
          stars: {
            key: form.key(`recommendations.${index}.starsOutOfTen`),
            props: form.getInputProps(`recommendations.${index}.starsOutOfTen`),
          },
          comment: {
            key: form.key(`recommendations.${index}.comment`),
            props: form.getInputProps(`recommendations.${index}.comment`),
          },
        }}
      />
    ));

  const informationItems = form
    .getValues()
    .informationSections?.sections?.map((section, index) => (
      <InformativeSectionForm
        key={index}
        name={`${t("information section")} - ${index}`}
        onDeletionClick={() =>
          form.removeListItem("informationSections.sections", index)
        }
        inputs={{
          title: {
            key: form.key(`informationSections.sections.${index}.title`),
            props: form.getInputProps(
              `informationSections.sections.${index}.title`
            ),
          },
          text: {
            key: form.key(`informationSections.sections.${index}.text`),
            props: form.getInputProps(
              `informationSections.sections.${index}.text`
            ),
          },
        }}
      />
    ));

  const questionsAndAnswersItems = form
    .getValues()
    .questionsAndAnswers.sections.map((qna, index) => (
      <InformativeSectionForm
        key={index}
        name={`${t("questions and answers")} - ${index}`}
        onDeletionClick={() =>
          form.removeListItem("questionsAndAnswers.sections", index)
        }
        inputs={{
          title: {
            key: form.key(`questionsAndAnswers.sections.${index}.title`),
            props: form.getInputProps(
              `questionsAndAnswers.sections.${index}.title`
            ),
          },
          text: {
            key: form.key(`questionsAndAnswers.sections.${index}.text`),
            props: form.getInputProps(
              `questionsAndAnswers.sections.${index}.text`
            ),
          },
        }}
      />
    ));

  const company = form.getValues().company.map((image, index) => (
    <TitledCard
      key={image}
      title={{
        titleProps: { order: 6 },
        text: `${t("company image")} - ${index === 0 ? t("required") : index}`,
      }}
    >
      <Group align="end">
        <FileInputWithLoading
          key={form.key(`company.${index}`)}
          imageUploaded={(id) => form.setFieldValue(`company.${index}`, id)}
          accept="image/*"
          owner={props.owner}
          placeholder={t("upload")}
          label={t("upload")}
          removePressed={() => {
            form.getValues().company?.length !== 1 &&
              form.removeListItem("company", index);
          }}
          {...form.getInputProps(`company.${index}`)}
        ></FileInputWithLoading>
      </Group>
    </TitledCard>
  ));

  return (
    <Box
      pos={"relative"}
      component="form"
      onSubmit={form.onSubmit(
        (val) => {
          props.onSubmit(val);
        },
        (e) => {
          document
            .getElementsByClassName("mantine-InputWrapper-error")
            .item(0)
            ?.scrollIntoView({ block: "center", behavior: "smooth" });
        }
      )}
    >
      <LoadingOverlay visible={props.isLoading} />

      <TextInput
        key={form.key("id")}
        type="hidden"
        {...form.getInputProps("id")}
      />

      <Fieldset mt={"md"} legend={t("metadata")}>
        <Group>
          <TextInput
            required
            key={form.key("name.name")}
            label={t("name")}
            description={t("card name will be shown in url")}
            {...form.getInputProps("name.name")}
          />
        </Group>

        <Group align='center'>
          <NumberInput
            label={t("template")}
            key={form.key("template.type")}
            min={1}
            {...form.getInputProps("template.type")}
          />

          <Checkbox
            label={t("leftToRight")}
            key={form.key("template.leftToRight")}
            {...form.getInputProps("template.leftToRight", {
              type: "checkbox",
            })}
          />

          <Checkbox
            label={t("darkOrLight")}
            key={form.key("template.darkOrLight")}
            {...form.getInputProps("template.darkOrLight", {
              type: "checkbox",
            })}
          />
        </Group>

        <Group align="top">
          <TextInput
            required
            label={t("card title")}
            key={form.key("metadata.title")}
            {...form.getInputProps("metadata.title")}
          />
        </Group>
        <Textarea
          required
          autosize={true}
          rows={3}
          label={t("card about")}
          key={form.key("metadata.about")}
          {...form.getInputProps("metadata.about")}
        />
      </Fieldset>

      <Fieldset mt={"md"} legend={t("images")}>
        <Stack>
          <TitledCard
            title={{
              text: t("profile"),
              titleProps: { order: 6 },
            }}
          >
            <Stack justify="bottom">
              <FileInputWithLoading
                key={form.key("profile")}
                owner={props.owner}
                accept="image/*"
                imageUploaded={(id) => form.setFieldValue("profile", id ?? "")}
                placeholder={t("upload")}
                label={t("upload")}
                {...form.getInputProps("profile")}
              >
                {t("choose")}
              </FileInputWithLoading>
            </Stack>
          </TitledCard>

          {company}
          <Group>
            <Button
              onClick={() => {
                form.insertListItem("company", {
                  file: undefined,
                });
              }}
            >
              {t("add company")}
            </Button>
          </Group>
        </Stack>
      </Fieldset>

      <Fieldset mt={"md"} legend={t("colors")}>
        <Flex gap={"md"} wrap={"wrap"}>
          <ColorInput
            label={t("primary color")}
            required
            key={form.key("colors.primary")}
            {...form.getInputProps("colors.primary")}
          />
          <ColorInput
            label={t("secondary color")}
            required
            key={form.key("colors.secondary")}
            {...form.getInputProps("colors.secondary")}
          />
          <ColorInput
            label={t("accent color")}
            key={form.key("colors.accent")}
            {...form.getInputProps("colors.accent")}
          />
        </Flex>
      </Fieldset>

      <Fieldset mt={"md"} legend={t("social links")}>
        <Group mt={"md"}>
          <Blockquote p={"sm"}>
            {t("at least one social link required")}
          </Blockquote>
        </Group>
        <SimpleGrid cols={2}>
          <TextInput
            label={t("email")}
            key={form.key("socialLinks.email")}
            {...form.getInputProps("socialLinks.email")}
          />

          <TextInput
            label={t("website")}
            key={form.key("socialLinks.web")}
            {...form.getInputProps("socialLinks.web")}
          />
          <TextInput
            label={t("facebook")}
            key={form.key("socialLinks.facebook")}
            {...form.getInputProps("socialLinks.facebook")}
          />
          <TextInput
            label={t("instagram")}
            key={form.key("socialLinks.instagram")}
            {...form.getInputProps("socialLinks.instagram")}
          />
          <TextInput
            label={t("phone number")}
            key={form.key("socialLinks.phone")}
            {...form.getInputProps("socialLinks.phone")}
          />
          <TextInput
            label={t("whatsapp")}
            key={form.key("socialLinks.whatsapp")}
            {...form.getInputProps("socialLinks.whatsapp")}
          />
        </SimpleGrid>
      </Fieldset>

      <Fieldset legend={t("recommendations")}>
        <Flex wrap={"wrap"}>{recommendationItems}</Flex>

        <Button
          onClick={() =>
            form.insertListItem("recommendations", {
              name: "",
              companyName: "",
              image: "",
              comment: "",
              starsOutOfTen: 10,
            })
          }
        >
          {t("add recommendation")}
        </Button>
      </Fieldset>

      <Fieldset legend={t("information sections")}>
        <Group align="end">
          <NumberInput
            key={form.key("informationSections.variant")}
            min={0}
            radius={"xs"}
            label={t("variant")}
            {...form.getInputProps("informationSections.variant")}
          />
          <Button
            onClick={() =>
              form.insertListItem("informationSections.sections", {
                title: "",
                text: "",
              })
            }
          >
            {t("add information section")}
          </Button>
        </Group>
        <Divider mt={"md"} />
        <Flex wrap={"wrap"}>{informationItems}</Flex>
      </Fieldset>

      <Fieldset legend={t("questions and answers")}>
        <Group align="end">
          <NumberInput
            key={form.key("questionsAndAnswers.variant")}
            min={0}
            radius={"xs"}
            label={t("variant")}
            {...form.getInputProps("questionsAndAnswers.variant")}
          />

          <Button
            onClick={() =>
              form.insertListItem("questionsAndAnswers.sections", {
                title: "",
                text: "",
              })
            }
          >
            {t("add question and answer")}
          </Button>
        </Group>

        <Flex wrap={"wrap"}>{questionsAndAnswersItems}</Flex>
      </Fieldset>

      <Group mt={"md"} justify="center">
        <Button
          onClick={() => {
            if (!form.validate().hasErrors) open();
          }}
        >
          {t("submit")}
        </Button>
        <Button ref={submitButton} opacity={0} type="submit"></Button>
        <Modal
          opened={opened}
          onClose={close}
          ta={"center"}
          title={"Are You Sure?"}
        >
          <Container>
            <Text pt="xl" mih={400}>
              This will Cost you 1 Update
            </Text>
            <Group justify="space-between">
              <Button variant="transparent" c={"red"} onClick={close}>
                {t("reject")}
              </Button>
              <Button
                onClick={() => {
                  submitButton.current?.click();
                  close();
                }}
              >
                {t("accept")}
              </Button>
            </Group>
          </Container>
        </Modal>
      </Group>
      <Space h={"lg"} />
    </Box>
  );
};
