import {
  Button,
  Card,
  Divider,
  Group,
  Textarea,
  TextInput,
  Title,
} from "@mantine/core";
import { GetInputPropsReturnType } from "@mantine/form/lib/types";
import { useTranslation } from "react-i18next";
import { FaTrash } from "react-icons/fa";
import { TitledCard } from "../Cards/TitledCard";

type Props = {
  name: string;
  onDeletionClick?: () => void;
  inputs: {
    title: {
      key: string;
      props: GetInputPropsReturnType;
    };
    text: {
      key: string;
      props: GetInputPropsReturnType;
    };
  };
};

export const InformativeSectionForm = ({
  name,
  onDeletionClick,
  inputs,
}: Props) => {
  const { t } = useTranslation();

  return (
    <TitledCard
      withBorder
      m={"md"}
      title={{
        text: name,
        titleProps: { order: 6 },
      }}
      leftSection={
        <Button onClick={onDeletionClick} color="red" variant="transparent">
          <FaTrash />
        </Button>
      }
    >
      <TextInput
        key={inputs.title.key}
        label={t("title")}
        {...inputs.title.props}
      />
      <Textarea
        key={inputs.text.key}
        label={t("text")}
        {...inputs.text.props}
      />
    </TitledCard>
  );
};
