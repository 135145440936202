import { useState } from 'react'
import { GetViewersRequest, GetViewersResponse } from '../../../Services/Application/contracts'
import { sonsoApplicationRest } from '../../../Services/Application/sonsoApplicationRest'
import { useSelector } from 'react-redux'
import { RootState } from '../../../Redux/store'

export const useGetApplicationManagement = () : [
  (request: Omit<GetViewersRequest, "userToken">) => Promise<void>,
  boolean,
  GetViewersResponse?,
] => {
  const user = useSelector((state: RootState) => state.userSlice)
  const [response, setResponse] = useState<GetViewersResponse>()
  const [loading, setLoading] = useState<boolean>(false)

  const fetch = async (request: Omit<GetViewersRequest, "userToken">) => {
    setLoading(true);
    setResponse(undefined);
    
    if(!user.token)
      return

    const customer = await sonsoApplicationRest.getViewers({
      ...request,
      userToken: user.token
    });

    setLoading(false)
    setResponse(customer);
  }

  return [fetch, loading, response]
}