import { useState } from 'react'
import { GiveContactInformationRequest, GiveContactInformationResponse } from '../../../Services/Customers/customerContracts'
import { giveContactInformation } from '../../../Services/Customers/customerRest'

export const useGiveContactInformation = () : [
  (request: GiveContactInformationRequest) => Promise<void>,
  boolean,
  GiveContactInformationResponse?,
] => {

  const [response, setResponse] = useState<GiveContactInformationResponse>()
  const [loading, setLoading] = useState<boolean>(false)

  const fetch = async (request: GiveContactInformationRequest) => {
    setLoading(true);
    setResponse(undefined);

    const response = await giveContactInformation(request);

    setLoading(false)
    setResponse(response);
  }

  return [fetch, loading, response]
}
