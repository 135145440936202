import { useTranslation } from "react-i18next";
import {
  Anchor,
  Box,
  BoxProps,
  Button,
  Checkbox,
  Fieldset,
  LoadingOverlay,
  PasswordInput,
  Space,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { isEmail, useForm } from "@mantine/form";
import { Link } from "react-router-dom";
import { PrivacyPolicyPageRoute, TermsOfUsePageRoute } from "../../App/Routes";

export type SubmitValue = {
  email: string;
  password: string;
  rememberMe: boolean;
};

type Props = {
  onSubmit: (values: SubmitValue) => void;
  isLoading: boolean;
  emailError?: string;
  passwordError?: string;
};

export const CustomerLoginForm = ({
  onSubmit,
  isLoading,
  emailError,
  passwordError,
  ...boxProps
}: Props & BoxProps) => {
  const { t } = useTranslation();

  const form = useForm<SubmitValue>({
    mode: "uncontrolled",
    initialValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
    validate: {
      email: (val) => isEmail(t("invalid email"))(val),
    },
    initialErrors: {
      email: emailError,
      password: passwordError,
    },
    validateInputOnBlur: true,
  });

  return (
    <Box
      component="form"
      pos={"relative"}
      {...boxProps}
      onSubmit={form.onSubmit((values) => onSubmit(values))}
    >
      <LoadingOverlay
        visible={isLoading}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />

      <Fieldset
        variant="unstyled"
        legend={<Title order={4}>{t("customer login")}</Title>}
      >
        <TextInput
          radius={"xs"}
          key={form.key("email")}
          label={t("email")}
          placeholder={t("email")}
          {...form.getInputProps("email")}
        />
        <PasswordInput
          radius={"xs"}
          key={form.key("password")}
          label={t("password")}
          placeholder={t("password")}
          {...form.getInputProps("password")}
        />
        <Checkbox
          mt={"xs"}
          radius={"xs"}
          label={t("remember me")}
          {...form.getInputProps("rememberMe")}
        ></Checkbox>

        <Space h={"xl"} />

        <Text fz={"sm"}>
          By continuing you agree to the{" "}
          <Anchor
            fz={"sm"}
            underline="always"
            to={`/${TermsOfUsePageRoute}`}
            component={Link}
          >
            Terms of use
          </Anchor>{" "}
          and{" "}
          <Anchor
            fz={"sm"}
            underline="always"
            to={`/${PrivacyPolicyPageRoute}`}
            component={Link}
          >
            Privacy Policy
          </Anchor>
        </Text>

        <Button mt={"xs"} w={"100%"} type="submit">
          {t("customer login")}
        </Button>
      </Fieldset>
    </Box>
  );
};
