import { useState } from 'react'
import { AddCardInteractionsRequest, AddCardInteractionsResponse} from '../../../Services/Customers/customerContracts'
import { addCardInteractions as rest } from '../../../Services/Customers/customerRest'

export const useAddCardInteractions = () : [
  (request: AddCardInteractionsRequest) => Promise<void>,
  boolean,
  AddCardInteractionsResponse?,
] => {

  const [response, setResponse] = useState<AddCardInteractionsResponse>()
  const [loading, setLoading] = useState<boolean>(false)

  const addCardInteractions = async (request: AddCardInteractionsRequest) => {
    setResponse(undefined)
    setLoading(true)

    const response = await rest(request)

    setResponse(response)
    setLoading(false)
  }

  return [addCardInteractions, loading, response]
}