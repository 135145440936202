import { Stack, Text, Title } from '@mantine/core'

type Props = {
  title: string,
  about: string
}

export const Metadata1 = (props: Props) => {
  return (
    <Stack>
      <Title order={3}>{props.title}</Title>
      <Text>{props.about}</Text>
    </Stack>
  )
}