import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";

export const SSLCertificate = () => {
  const theme = useSelector((state: RootState) => state.themeSlice);

  switch (theme.style) {
    case "light":
      return (
        <span id="siteseal">
          <script
            async
            type="text/javascript"
            src="https://seal.godaddy.com/getSeal?sealID=EEAnDJJjRgLfKNalEVW9uZWHHT9tQ0Xo5aAx2ev4PRq80b6ufhxkHJ9mnn33"
          ></script>
        </span>
      );
    case "dark":
      return (
        <span id="siteseal">
          <script
            async
            type="text/javascript"
            src="https://seal.godaddy.com/getSeal?sealID=EEAnDJJjRgLfKNalEVW9uZWHHT9tQ0Xo5aAx2ev4PRq80b6ufhxkHJ9mnn33"
          ></script>
        </span>
      );
    case "system":
      break;
  }

  return <div>SSLCertificate</div>;
};
