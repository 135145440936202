import { deepFreeze } from "./objects";

export type Error = {
  errorCode: number;
  message: string;
};

const FULL_NAME_MAX_LENGTH_MESSAGE = "fullname is too long";

export const errors = deepFreeze({
  customer: {
    fullName: {
      UNDEFINED: {
        message: "fullname is undefined",
        errorCode: 1,
      },
      TOO_SHORT: {
        message: "fullname is too short",
        errorCode: 2,
      },
      TOO_LONG: {
        message: FULL_NAME_MAX_LENGTH_MESSAGE,
        errorCode: 3,
      },
      HAS_SPECIAL_CHARACTERS: {
        message: "fullname has special characters",
        errorCode: 4,
      },
    },
    companyName: {
      UNDEFINED: {
        message: "company name is undefined",
        errorCode: 5,
      },
      TOO_SHORT: {
        message: "company name is too short",
        errorCode: 6,
      },
      TOO_LONG: {
        message: "company name is too long",
        errorCode: 7,
      },
      HAS_SPECIAL_CHARACTERS: {
        message: "company name has special characters",
        errorCode: 8,
      },
    },
    password: {
      UNDEFINED: {
        message: "password undefined",
        errorCode: 9,
      },
      TOO_SHORT: {
        message: "password too short",
        errorCode: 10,
      },
      TOO_LONG: {
        message: "password too long",
        errorCode: 11,
      },
      NOT_INCLUDE_SPECIAL_CHARACTER: {
        message: "password does not include special char",
        errorCode: 12,
      },
    },
    phone: {
      UNDEFINED: {
        message: "phone is undefined",
        errorCode: 12,
      },
      INVALID: {
        message: "phone is invalid",
        errorCode: 13,
      },
    },
    email: {
      UNDEFINED: {
        message: "email is undefined",
        errorCode: 41,
      },
      INVALID: {
        message: "invalid email",
        errorCode: 42,
      },
    },
    login: {
      UNAUTHORIZED: {
        message: "email or password is wrong",
        errorCode: 401
      }
    }
  },
  potentialCustomer: {
    fullName: {
      UNDEFINED: {
        message: "fullname is undefined",
        errorCode: 14,
      },
      TOO_SHORT: {
        message: "fullname is too short",
        errorCode: 15,
      },
      TOO_LONG: {
        message: "fullname is too long",
        errorCode: 16,
      },
      HAS_SPECIAL_CHARACTERS: {
        message: "fullname has special characters",
        errorCode: 17,
      },
    },
    email: {
      UNDEFINED: {
        message: "email is undefined",
        errorCode: 46,
      },
      INVALID: {
        message: "invalid email",
        errorCode: 47,
      },
    }
  },
  user: {
    username: {
      UNDEFINED: {
        message: "username is undefined",
        errorCode: 18,
      },
      TOO_SHORT: {
        message: "username is too short",
        errorCode: 19,
      },
      TOO_LONG: {
        message: "username is too long",
        errorCode: 20,
      },
    },
    password: {
      UNDEFINED: {
        message: "password is undefined",
        errorCode: 21,
      },
      TOO_SHORT: {
        message: "password is too short",
        errorCode: 22,
      },
      TOO_LONG: {
        message: "password is too long",
        errorCode: 23,
      },
    },
  },
  card: {
    name: {
      UNDEFINED: {
        message: "card name is undefined",
        errorCode: 24,
      },
      ONLY_ENGLISH_CHARACTERS: {
        message: "card name must include only english characters",
        errorCode: 39,
      },
      NO_SPECIAL_CHARACTERS: {
        message: "card name cannot include special characters",
        errorCode: 40,
      },
      TOO_SHORT: {
        message: "card name is too short",
        errorCode: 25,
      },
      TOO_LONG: {
        message: "card name is too long",
        errorCode: 26,
      },
    },
    template: {
      UNDEFINED: {
        message: "card template is undefined",
        errorCode: 27,
      },
      NOT_AN_INTEGER: {
        message: "card template is not an integer",
        errorCode: 39,
      },
      NEGATIVE_OR_ZERO: {
        message: "card template cannot be negative or zero",
        errorCode: 28,
      },
    },
    image: {
      UNDEFINED: {
        message: "card image is undefined",
        errorCode: 29,
      },
    },
    profile: {
      WAS_NOT_SET: {
        message: 'profile image was not set',
        errorCode: 48
      }
    },
    color: {
      UNDEFINED: {
        message: "card color is undefined",
        errorCode: 30,
      },
    },
    title: {
      UNDEFINED: {
        message: "card title is undefined",
        errorCode: 31,
      },
      TOO_SHORT: {
        message: "card title is too short",
        errorCode: 32,
      },
      TOO_LONG: {
        message: "card title is too long",
        errorCode: 33,
      },
    },
    about: {
      UNDEFINED: {
        message: "card about is undefined",
        errorCode: 34,
      },
      TOO_SHORT: {
        message: "card about is too short",
        errorCode: 35,
      },
      TOO_LONG: {
        message: "card about is too long",
        errorCode: 36,
      },
    },
    socialLinks: {
      UNDEFINED: {
        message: "card social links is undefined",
        errorCode: 37,
      },
      ONE_OR_MORE_LINK_IS_REQUIRED: {
        message: "card social links must have one or more link",
        errorCode: 38,
      },
      EMAIL_IS_NOT_VALID: {
        message: "card email social link is invalid",
        errorCode: 39,
      }
    },
    colors: {
      UNDEFINED: {
        message: "card color is undefined",
        errorCode: 40,
      },
      NOT_A_COLOR: {
        message: "card color is invalid",
        errorCode: 41,
      },
    },
    recommendations: {
      UNDEFINED: {
        message: "card recommendations is undefined",
        errorCode: 44,
      },
      NOT_A_COLOR: {
        message: "card recommendations must have one or more link",
        errorCode: 45,
      },
    }
  },
});
