import {
  Box,
  BoxProps,
  ButtonProps,
  Center,
  Flex,
  FlexProps,
  Group,
  GroupProps,
  Image,
  MantineSpacing,
  Stack,
  Text,
} from "@mantine/core";
import React, { PropsWithChildren } from "react";
import { SocialLinksProp } from "../../../CardProps";
import { FacebookLink } from "../../../../../Atoms/Links/FacebookLink";
import { GlobeLink } from "../../../../../Atoms/Links/GlobeLink";
import { InstagramLink } from "../../../../../Atoms/Links/InstagramLink";
import { MailClipboardLink } from "../../../../../Atoms/Links/MailClipboardLink";

type Props = {
  socialLinks: SocialLinksProp;
  withLabel?: boolean;
  gap?: MantineSpacing;
  buttonProps?: ButtonProps;
  buttonContainerProps?: BoxProps;
};

const LinkContainer = ({
  children,
  ...container
}: PropsWithChildren<FlexProps>) => {
  return (
    <Flex justify={'center'} align={'center'} {...container}>
      {children}
    </Flex>
  );
};

export const ColumnSocialLinks1 = ({
  socialLinks,
  withLabel,
  gap,
  buttonProps,
  buttonContainerProps
}: Props) => {
  return (
    <Stack gap={gap}>
      {socialLinks.facebook && (
        <LinkContainer {...buttonContainerProps}>
          <FacebookLink {...buttonProps} link={socialLinks.facebook} />
          {withLabel && (
            <Text fz={"sm"} c={"white"}>
              {socialLinks.facebook}
            </Text>
          )}
        </LinkContainer>
      )}
      {socialLinks.web && (
        <LinkContainer {...buttonContainerProps}>
          <GlobeLink {...buttonProps} link={socialLinks.web} />
          {withLabel && (
            <Text fz={"sm"} c={"white"}>
              {socialLinks.web}
            </Text>
          )}
        </LinkContainer>
      )}
      {socialLinks.instagram && (
        <LinkContainer {...buttonContainerProps}>
          <InstagramLink {...buttonProps} link={socialLinks.instagram} />
          {withLabel && (
            <Text fz={"sm"} c={"white"}>
              {socialLinks.instagram}
            </Text>
          )}
        </LinkContainer>
      )}
      {socialLinks.email && (
        <LinkContainer {...buttonContainerProps}>
          <MailClipboardLink {...buttonProps} email={socialLinks.email} />
          {withLabel && (
            <Text fz={"sm"} c={"white"}>
              {socialLinks.email}
            </Text>
          )}
        </LinkContainer>
      )}
    </Stack>
  );
};
