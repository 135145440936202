import React, { useState } from 'react'
import { PublishNewCardRequest, PublishNewCardResponse } from '../../../Services/Customers/customerContracts'
import { publishNewCard as rest } from '../../../Services/Customers/customerRest'
import { useSelector } from 'react-redux'
import { RootState } from '../../../Redux/store'
import { NOT_LOGGED_IN_ERROR_RESPONSE } from '../../../Services/Commons/commonResponses'
import { notifier } from '../../../Utilities/notifier'

export const usePublishNewCard = () : [
  (request: Omit<PublishNewCardRequest, "token">) => Promise<void>,
  boolean,
  PublishNewCardResponse?,
] => {
  const user = useSelector((state: RootState) => state.userSlice)
  const [response, setResponse] = useState<PublishNewCardResponse>()
  const [loading, setLoading] = useState<boolean>(false)

  const postNewCard = async (request: Omit<PublishNewCardRequest, "token">) => {
    
    if(!user.token){
      setResponse(NOT_LOGGED_IN_ERROR_RESPONSE)
      notifier.notifyErrors(NOT_LOGGED_IN_ERROR_RESPONSE.errors)
      return 
    }

    setResponse(undefined)
    setLoading(true)

    const response = await rest({...request, token: user.token})

    if(response.errors)
      notifier.notifyErrors(response.errors)
    if(response.card)
      notifier.notifySuccess({message: 'success'})

    setResponse(response)
    setLoading(false)
  }

  return [postNewCard, loading, response]
}