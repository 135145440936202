import {
  Box,
  Button,
  Flex,
  Group,
} from "@mantine/core";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { LoginLinks } from "../../Molecules/Links/LoginLinks";
import { LandingPageRoute } from "../../App/Routes";
import { Logo } from "../../Atoms/Logo";

type Props = {
  opened: boolean, 
  toggle: () => void,
  close: () => void,
};

export const TabletHeader = ({opened, toggle, close}: Props) => {
  const location = useLocation();

  useEffect(() => {
    close();
  }, [location]);

  return (
    <Flex align={'center'} justify={"flex-end"} h={"100%"}>
      <Button
        component={Link}
        to={`/${LandingPageRoute}`}
        h={"5em"}
        p={0}
        variant="transparent"
      >
        <Logo h={"100%"} w={"auto"} />
      </Button>
      <Box m={'auto'} ></Box>
      <Group>
        <LoginLinks />
      </Group>
    </Flex>
  );
};
