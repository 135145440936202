import {
  Combobox,
  Group,
  Input,
  InputBase,
  Stack,
  Text,
  TextInput,
  useCombobox,
} from "@mantine/core";
import { ReactNode, useEffect, useState } from "react";
import { useSearchCustomerCards } from "../../../Hooks/Sonso/CustomerHooks/useSearchCustomerCards";
import { useThrottledCallback } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { CustomerDto } from "../../../Services/Customers/customerContracts";
import { useThrottledSearchCustomer } from "../../../Hooks/Sonso/CustomerHooks/useThrottledSearchCustomer";

type Props = {
  onCustomerSelected?: (value: CustomerDto) => void;
  selectedCustomer?: CustomerDto;
};

export const SearchCustomers = ({
  selectedCustomer,
  onCustomerSelected,
}: Props) => {
  const { t } = useTranslation();
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });
  const [search, isLoading, response] = useThrottledSearchCustomer();

  const innerCardSelected = (val: string) => {
    combobox.closeDropdown();
    const customer = response?.customers?.filter((c) => c.email === val);

    if (customer && customer.length)
      onCustomerSelected && onCustomerSelected(customer[0]);
  };

  let data: ReactNode[] = [];

  if (response?.customers)
    data = response.customers.map((customer) => (
      <Combobox.Option value={customer.email} key={customer.email}>
        {customer.email}
      </Combobox.Option>
    ));
  if (isLoading)
    data = [<Combobox.Empty key={"loading"}>{t("loading")}</Combobox.Empty>];
  if (response?.errors)
    data = [<Combobox.Empty key={"errors"}>{t("errors")}</Combobox.Empty>];

  return (
    <Stack gap={"xs"}>
      <Text fs={"italic"} fz={"xs"} c={"gray"}>
        {t("write three or more characters to start searching")}
      </Text>
      <Group>
        <TextInput
          onChange={(input) => {
            search(input.target.value);
            combobox.openDropdown();
          }}
        />
        <Combobox
          width={250}
          store={combobox}
          onOptionSubmit={innerCardSelected}
        >
          <Combobox.Target>
            <InputBase
              component="button"
              type="button"
              pointer
              rightSection={<Combobox.Chevron />}
              rightSectionPointerEvents="none"
              onClick={() => combobox.toggleDropdown()}
            >
              {selectedCustomer?.email || (
                <Input.Placeholder>{t("select")}</Input.Placeholder>
              )}
            </InputBase>
          </Combobox.Target>
          <Combobox.Dropdown>
            <Combobox.Options>
              {data.length > 0 ? (
                data
              ) : (
                <Combobox.Empty key={"not found"}>
                  {t("not found")}
                </Combobox.Empty>
              )}
            </Combobox.Options>
          </Combobox.Dropdown>
        </Combobox>
      </Group>
    </Stack>
  );
};
