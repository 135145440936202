import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Fieldset,
  Group,
  LoadingOverlay,
  PasswordInput,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import rules from "../../../Utilities/rules";

type SubmitValues = {
  userName: string;
  password: string;
};

type Props = {
  onSubmit: ({ userName, password }: SubmitValues) => void;
  isLoading: boolean;
  usernameError?: string;
  passwordError?: string;
};

export const UserLoginForm = (props: Props) => {
  const { t } = useTranslation();
  const form = useForm({
    mode: "uncontrolled",
    validateInputOnBlur: true,
    initialValues: {
      username: "",
      password: "",
    },
    validate: {
      username: (val) => {
        const msg = rules.user.username.validate(val);
        if (msg) return t(msg.message);
      },
      password: (val) => {
        const msg = rules.user.password.validate(val);
        if (msg) return t(msg.message);
      },
    },
  });

  return (
    <Box
      component="form"
      pos={"relative"}
      onSubmit={form.onSubmit((val) =>
        props.onSubmit({ userName: val.username, password: val.password })
      )}
    >
      <LoadingOverlay
        visible={props.isLoading}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />

      <Fieldset legend={t("customer administration")}>
        <TextInput
          key={form.key("username")}
          label={t("username")}
          placeholder={t("username")}
          error={props.usernameError}
          {...form.getInputProps("username")}
        />
        <PasswordInput
          key={form.key("password")}
          label={t("password")}
          placeholder={t("password")}
          error={props.passwordError}
          {...form.getInputProps("password")}
        />
        <Group justify={"flex-end"} mt={"md"}>
          <Button type="submit">{t("submit")}</Button>
        </Group>
      </Fieldset>
    </Box>
  );
};
