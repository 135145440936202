import { Box, Button, Card, CardProps, Text, Title } from "@mantine/core";
import React from "react";

export type PaymentCardProps = {
  contrasted: boolean;
  title: string;
  payment: {
    price: string;
    currency: string;
    per: string;
  };
  targetAudience: string;
  description: string;
  button: {
    text: string;
    onClick?: () => void;
  };
};

export const PaymentCard = ({
  contrasted,
  title,
  targetAudience,
  description,
  payment,
  button,
  ...cardProps
}: PaymentCardProps & CardProps) => {
  return (
    <Card
      p={"lg"}
      shadow="lg"
      bg={contrasted ? "violet" : "white"}
      c={contrasted ? "white" : "dark"}
      maw={300}
      {...cardProps}
    >
      <Title order={5}>{title}</Title>
      <Title mt={"md"} order={3}>
        {payment.currency}
        {payment.price}
        <Text component={"span"}>/{payment.per}</Text>
      </Title>
      <Title mt={"md"} order={6}>
        {targetAudience}
      </Title>
      <Text mt={"xl"}>{description}</Text>
      <Box m={"auto"} />
      <Button
        variant={contrasted ? "white" : "filled"}
        onClick={button.onClick}
      >
        {button.text}
      </Button>
    </Card>
  );
};
