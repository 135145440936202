import {
  Box,
  Container,
  getGradient,
  Group,
  MantineColor,
  MantineTheme,
  Space,
  Stack,
  Title,
} from "@mantine/core";
import React from "react";
import {
  ContactForm1,
  ContactUsSubmitValues,
} from "../../../../Forms/ContactUsForm";
import { DonaItaliaContactUsLayout } from "../../Layouts/Forms/DonaItaliaContactUsLayout";

type Props = {
  isLoading: boolean;
  color: MantineColor;
  onSubmit: (request: ContactUsSubmitValues) => void;
  theme: MantineTheme;
};

export const DonaItaliaContactUsForm = ({
  color,
  onSubmit,
  theme,
  isLoading,
}: Props) => {
  const gradientStart = getGradient(
    {
      deg: 270,
      from: color,
      to: theme.white!,
    },
    theme
  );

  const gradientEnd = getGradient(
    {
      deg: 90,
      from: color,
      to: theme.white!,
    },
    theme
  );

  return (
    <Stack>
      <Container>
        <Group>
          <Box h={5} bg={gradientStart} style={{ flexGrow: 1 }}></Box>
          <Title order={6}>{"Contact Us"}</Title>
          <Box h={5} bg={gradientEnd} style={{ flexGrow: 1 }}></Box>
        </Group>

        <Space h={'md'} />

        <ContactForm1
          Layout={DonaItaliaContactUsLayout}
          isLoading={isLoading}
          onSubmit={onSubmit}
        />
      </Container>
    </Stack>
  );
};
