import {
  Box,
  Direction,
  getGradient,
  MantineTheme,
  Text,
  Title,
} from "@mantine/core";
import React from "react";

type Props = {
  title: string;
  about: string;
  colors: {
    primary: string;
    secondary: string;
  };
  theme: MantineTheme;
  dir: Direction;
  degree: number;
};

export const ShlomiMetadata = (props: Props) => {
  const titleColor = getGradient(
    {
      deg: props.degree,
      from: props.colors.primary,
      to: props.theme.white!,
    },
    props.theme
  );

  const textColor = getGradient(
    {
      deg: props.degree,
      from: props.theme.colors.dark[9],
      to: props.theme.white!,
    },
    props.theme
  );

  const borderRadius = props.dir === 'rtl' ? `0 ${"5em"} ${"5em"} 0` : `${"5em"} 0 0 ${"5em"}`;

  return (
    <Box>
      <Box p={"sm"} bg={titleColor} style={{ borderRadius: borderRadius }}>
        <Title>{props.title}</Title>
      </Box>

      <Box
        ms={"md"}
        p={"sm"}
        c={props.theme.white}
        bg={textColor}
        style={{ borderRadius: borderRadius }}
      >
        <Text>{props.about}</Text>
      </Box>
    </Box>
  );
};
