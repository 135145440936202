import { useEffect } from "react";
import { DigitalCardsPageRoute, LandingPageRoute } from "../App/Routes";
import { useAddPotentialCustomer } from "../../Hooks/Sonso/PotentialCustomers/useAddPotentialCustomer";
import { PotentialCustomerForm } from "../Organizem/Forms/PotentialCustomerForm";
import { useTranslation } from "react-i18next";
import { Logo } from "../Atoms/Logo";
import {
  BackgroundImage,
  Box,
  Button,
  Container,
  Flex,
  Group,
  Image,
  LoadingOverlay,
  Space,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { useScrollIntoView } from "@mantine/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import cover from "../../Assets/Background/slanted-dark.svg";
import {
  LearnMoreCard,
  LearnMoreCardProps,
} from "../Molecules/Cards/LearnMoreCard";
import seoImage from "../../Assets/Images/seo-image.png";
import socialMedia from "../../Assets/Images/social-media-image.png";
import payPerClick from "../../Assets/Images/pay-per-click-image.png";
import aboutUsImage from "../../Assets/Images/about-us-image.png";
import { StoryCard } from "../Molecules/Cards/StoryCard";
import { ExampleCardDisplay } from "../Organizem/DigitalCards/Displays/ExampleCardDisplay";
import { useLaptopAndAbove } from "../../Hooks/Layout/useTabletAndAbove";
import { useGetApplicationRecommendations } from "../../Hooks/Sonso/ApplicationHooks/useGetApplicationRecommendations";
import { Recommendations1 } from "../Organizem/DigitalCards/Templates/Elements/Recommendations/Recommendations1";
import { ExampleApplicationRecommendations } from "../Organizem/Recommendations/ExampleApplicationRecommendations";

const TOP_TITLE_ORDER = 1;
const SECONDARY_TITLES_ORDER = 2;

type Props = {};

const learnMoreCards: LearnMoreCardProps[] = [
  {
    description:
      "Boost your search engine rankings with tailored SEO strategies that drive organic traffic and increase visibility.",
    label: "Learn more",
    to: `/${DigitalCardsPageRoute}`,
    title: "SEO Optimization",
    imageSrc: seoImage,
  },
  {
    description:
      "Engage your audience and grow your brand’s presence with expertly managed social media campaigns.",
    label: "Learn more",
    to: `/${DigitalCardsPageRoute}`,
    title: "Social Media Management",
    imageSrc: socialMedia,
  },
  {
    description:
      "Maximize your ROI with targeted PPC campaigns that deliver quick, measurable results.",
    label: "Learn more",
    to: `/${DigitalCardsPageRoute}`,
    title: "Pay-Per-Click Advertising",
    imageSrc: payPerClick,
  },
];

export const Landing = (props: Props) => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const { scrollIntoView: aboutScroll, targetRef: aboutRef } =
    useScrollIntoView<HTMLDivElement>();
  const { scrollIntoView: cardsScroll, targetRef: cardsRef } =
    useScrollIntoView<HTMLDivElement>();
  const { scrollIntoView: marketingScroll, targetRef: marketingRef } =
    useScrollIntoView<HTMLDivElement>();
  const location = useLocation();
  const { t } = useTranslation();

  const isLaptop = useLaptopAndAbove();

  useEffect(() => {
    switch (location.hash) {
      case "#card-tiers":
        cardsScroll({ alignment: "center" });
        break;
      case "#marketing-plans":
        marketingScroll({ alignment: "center" });
        break;
      case "#about-us":
        aboutScroll({ alignment: "center" });
        break;
      default:
        break;
    }
  }, [location.hash]);

  return (
    <>
      <BackgroundImage src={cover}>
        <Container>
          <Space h="lg" />

          {/* So&So Landing Text */}
          <Stack ta={"center"}>
            <Box>
              <Title display={"inline"} order={TOP_TITLE_ORDER} c="white">
                Elevate Your Brand with{" "}
              </Title>
              <Title
                display={"inline"}
                order={TOP_TITLE_ORDER}
                c={theme.colors.violet[3]}
              >
                Cutting-Edge
              </Title>
            </Box>

            <Box>
              <Title
                display={"inline"}
                order={TOP_TITLE_ORDER}
                c={theme.colors.violet[3]}
              >
                Digital Marketing{" "}
              </Title>
              <Title display={"inline"} order={TOP_TITLE_ORDER} c={"white"}>
                Solutions
              </Title>
            </Box>

            <Text c={"white"} fz={"sm"} opacity={0.6}>
              Unlock Your Business’s Potential With Our Innovative Digital
              Marketing Strategies And <br /> Personalized Digital Business
              Cards. Transform The Way You Connect With Your <br />{" "}
              Audience—Start Your Journey Today.
            </Text>

            <Group justify="center">
              <Button
                radius={"md"}
                onClick={() => navigate(`${LandingPageRoute}/#about-us`)}
              >
                Get Started
              </Button>
            </Group>

            <Space h="lg" />
          </Stack>
        </Container>
      </BackgroundImage>

      {/* Marketing Plans */}
      <Container size={"xl"} mt={"lg"}>
        <Stack ta="center">
          <Box>
            <Title display={"inline"} order={SECONDARY_TITLES_ORDER}>
              Transform Your{" "}
            </Title>
            <Title
              display={"inline"}
              order={SECONDARY_TITLES_ORDER}
              c={theme.colors.violet[3]}
            >
              Online Presence{" "}
            </Title>
            <Title display={'inline'} order={SECONDARY_TITLES_ORDER}>with Expert</Title>
          </Box>

          <Box>
            <Title order={SECONDARY_TITLES_ORDER}>Digital Marketing</Title>
          </Box>

          <Group justify="center">
            {learnMoreCards.map((p) => (
              <LearnMoreCard key={p.title} {...p} />
            ))}
          </Group>
        </Stack>

        <Space h={"md"} />

        {/* About Us */}
        <StoryCard
          legend="About Us"
          title={
            <Title ref={aboutRef} order={SECONDARY_TITLES_ORDER}>
              Our Story Committed to
              <br />
              <Title
                component={"span"}
                order={SECONDARY_TITLES_ORDER}
                c={theme.colors.violet[3]}
              >
                Your Success
              </Title>
            </Title>
          }
          text={
            "Founded with a passion for innovation and a commitment to excellence," +
            " our company has grown into a leading provider of digital marketing and " +
            " business card solutions. We believe in the power of technology to transform" +
            " businesses and are dedicated to helping our clients succeed in the digital age."
          }
          rightSection={isLaptop && <Image src={aboutUsImage} />}
        />

        <Space h={"md"} />

        <Stack ta={"center"}>
          <Title order={SECONDARY_TITLES_ORDER}>
            Design Your Perfect{" "}
            <Title
              component={"span"}
              order={SECONDARY_TITLES_ORDER}
              c={theme.colors.violet[3]}
            >
              Digital
            </Title>
            <br />
            Business Card
          </Title>
        </Stack>
      </Container>
      <Space h={"md"} />

      {/* Example Cards Digital */}
      <ExampleCardDisplay />

      <Space h={"md"} />

      <ExampleApplicationRecommendations />
    </>
  );
};
