import { Button, ButtonProps } from "@mantine/core";
import React from "react";
import { CiMail } from "react-icons/ci";

type Props = { email: string };

export const MailClipboardLink = ({ email, ...props }: Props & ButtonProps) => {
  return (
    <Button {...props} p={0}>
      <CiMail
        onClick={() => {
          navigator.clipboard.writeText(email);
        }}
      />
    </Button>
  );
};
