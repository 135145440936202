import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { supportedLanguages } from "../../../Translations/Core/supportedLanguages";
import { deepFreeze } from "../../../Utilities/objects";
import i18next from "i18next";

export type TranslationState = {
  dir: "ltr" | "rtl";
  regionKey: string;
} & ChangeLanguagePayload;

type ChangeLanguagePayload = {
  language: keyof typeof supportedLanguages;
};

export const LANGUAGE_PACKS: { [id: string]: TranslationState } = deepFreeze({
  he: {
    language: "he",
    dir: "rtl",
    regionKey: "il",
  },
  en: {
    language: "en",
    dir: "ltr",
    regionKey: "us",
  },
});

const DEFAULT_LANGUAGE = LANGUAGE_PACKS["en"];

const initialState: TranslationState = DEFAULT_LANGUAGE;

export const translationSlice = createSlice({
  name: "translation",
  initialState,
  reducers: {
    changeLanguage: (state, action: PayloadAction<ChangeLanguagePayload>) => {
      state.language = action.payload.language;
      state.dir = supportedLanguages[action.payload.language].dir;
      const regionKey = supportedLanguages[action.payload.language].regionKey;
      state.regionKey = regionKey;
      i18next.changeLanguage(action.payload.language as string);
    },
  },
});

// Action creators are generated for each case reducer function
export const translationActions = translationSlice.actions;
export const translationReducers = translationSlice.reducer;
