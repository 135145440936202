import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { useTranslation } from "react-i18next";
import { Link, useMatches } from "react-router-dom";
import { customerActions } from "../../../Redux/features/Customer/customerSlice";
import { Box, BoxProps, Button, Menu } from "@mantine/core";
import { FaRegUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { userActions } from "../../../Redux/features/User/userSlice";
import { AdministrationPageRoute, CustomerAdministrationPageRoute, LoginPageRoute } from "../../App/Routes";
import { useCustomerSelector } from "../../../Redux/Selectors/useCustomerSelector";

type Props = {

}

export const LoginLinks = ({...buttonProps}: Props & BoxProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const matches = useMatches();
  const customer = useCustomerSelector();
  const user = useSelector((state: RootState) => state.userSlice);

  const dispatch = useDispatch();
  const matchLogin = matches.some((p) => p.pathname === `/${LoginPageRoute}`);

  return (
    <Box c={"white"} {...buttonProps}>
      {customer.token || user.token ? (
        <Menu trigger="click-hover">
          <Menu.Target>
            <Box>
              <FaRegUser />
            </Box>
          </Menu.Target>
          <Menu.Dropdown>
            {/* Customer Operations */}
            {customer.token && (
              <>
                <Menu.Item
                  component={Link}
                  to={`/${CustomerAdministrationPageRoute}`}
                >
                  {t("customer administration")}
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    dispatch(customerActions.logoutCustomer());
                  }}
                >
                  {t("logout")}
                </Menu.Item>
              </>
            )}

            {/* User Operations */}
            {user.token && (
              <>
                <Menu.Item
                  component={Link}
                  to={`/${AdministrationPageRoute}`}
                >
                  {t("administration")} ({t("user")})
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    dispatch(userActions.logoutUser());
                  }}
                >
                  {t("logout")} ({t("user")})
                </Menu.Item>
              </>
            )}
          </Menu.Dropdown>
        </Menu>
      ) : (
        !matchLogin && (
          <Button onClick={() => navigate(`/${LoginPageRoute}`)}>
            {t("login")}
          </Button>
        )
      )}
    </Box>
  );
};
