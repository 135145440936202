import { Accordion, Text } from "@mantine/core";
import { ReactNode } from "react";
import { InformativeSectionProps } from "./InformativeSectionsProps";



export const AccordionInformativeSections = ({
  controlIcon,
  parentTitle,
  sections,
  ...props
}: InformativeSectionProps) => {
  if (!sections) return <></>;

  return (
    <>
      {!!sections.length && parentTitle && <Text>{parentTitle}</Text>}
      <Accordion
        onChange={(val) =>
          val && props.onSectionOpened && props.onSectionOpened(val)
        }
      >
        {sections.map((section) => (
          <Accordion.Item key={section.id} value={section.title}>
            <Accordion.Control icon={controlIcon}>
              {section.title}
            </Accordion.Control>
            <Accordion.Panel>{section.text}</Accordion.Panel>
          </Accordion.Item>
        ))}
      </Accordion>
    </>
  );
};
