import { Container, SimpleGrid, Stack, Text, Title } from "@mantine/core";
import { useLocation, useNavigate } from "react-router-dom";
import { PaymentCard, PaymentCardProps } from "../Molecules/Cards/PaymentCard";
import { DigitalCardsPaymentsPageRoute } from "../App/Routes";
import { useTranslation } from "react-i18next";

const TOP_TITLE_ORDER = 2;

type Props = {};

export const PERSONAL_SECTION_HASH = "personal";
export const INDEPENDENT_SECTION_HASH = "independent";
export const ORGANIZATION_SECTION_HASH = "organization";

export const DigitalCards = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  const paymentCards: PaymentCardProps[] = [
    {
      contrasted: false,
      title: t("free"),
      payment: {
        price: "9.99",
        per: t("month"),
        currency: "$",
      },
      targetAudience: t("ideal for small businesses"),
      description: t("free offer"),
      button: {
        text: t("learn more"),
        onClick: () => navigate(`/${DigitalCardsPaymentsPageRoute}`),
      },
    },
    {
      contrasted: true,
      title: t("standard"),
      payment: {
        price: "19.99",
        per: t("month"),
        currency: "$",
      },
      targetAudience: t("ideal for medium businesses"),
      description: t("standard offer"),
      button: {
        text: t("learn more"),
        onClick: () => navigate(`/${DigitalCardsPaymentsPageRoute}`),
      },
    },
    {
      contrasted: false,
      title: t("deluxe"),
      payment: {
        price: "29.99",
        per: t("month"),
        currency: "$",
      },
      targetAudience: t("ideal for large businesses"),
      description: t("deluxe offer"),
      button: {
        text: t("learn more"),
        onClick: () => navigate(`/${DigitalCardsPaymentsPageRoute}`),
      },
    },
  ];

  return (
    <Container>
      <Stack mt={"lg"} mb={"lg"} ta={"center"}>
        {/* Overview */}
        <Title order={TOP_TITLE_ORDER}>
          Find the Perfect{" "}
          <Title c={"violet"} order={TOP_TITLE_ORDER} component={"span"}>
            DigitalCard{" "}
          </Title>{" "}
          Package <br /> for Your Needs
        </Title>
        <Text>
          Discover our DigitalCard packages tailored for businesses of all
          sizes. Whether you're starting out or need a full-featured solution,
          we offer the perfect package with features to enhance your branding
          and networking.
        </Text>
      </Stack>

      {/* Digital Card Examples */}
      <SimpleGrid cols={3}>
        {paymentCards.map((cardProps) => (
          <PaymentCard key={cardProps.title} {...cardProps} mih={420} />
        ))}
      </SimpleGrid>
    </Container>
  );
};
