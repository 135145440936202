import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Drawer,
  Group,
  RenderTreeNodePayload,
  Stack,
  Tree,
  TreeProps,
  useTree,
} from "@mantine/core";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Logo } from "../../Atoms/Logo";
import {
  FaChevronDown,
  FaChevronUp,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";
import { headerLinks } from "../../Organizem/App/headerLinks";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ButtonGroupLanguages } from "../../Molecules/Language/ButtonGroupLanguages";
import { translationActions } from "../../../Redux/features/Translation/translationSlice";

type Props = {
  opened: boolean;
  toggle: () => void;
};

export const Sidebar = ({ opened, toggle }: Props) => {
  const tree = useTree();
  const { t } = useTranslation();
  const dispatcher = useDispatch();

  const Leaf = ({
    node,
    expanded,
    hasChildren,
    elementProps,
  }: RenderTreeNodePayload) => {
    if (node.label === "logo")
      return (
        <Group justify="center">
          <Button
            {...elementProps}
            component={Link}
            to={node.value}
            h={"5em"}
            variant="transparent"
          >
            <Logo theme="dark" h={"100%"} w={"auto"} />
          </Button>
        </Group>
      );

    return (
      <Group {...elementProps}>
        <Button p={0} variant="subtle" component={Link} to={node.value}>
          <Group wrap="nowrap">{t(node.label as string)}</Group>
        </Button>
        {hasChildren && (
          <Button
            p={0}
            variant="transparent"
            onClick={() => tree.toggleExpanded(node.value)}
          >
            {expanded ? <FaChevronUp /> : <FaChevronDown />}
          </Button>
        )}
      </Group>
    );
  };

  return (
    <Fragment>
      <Stack h="50%" justify="center" align="center">
        <Button radius={"0"} p={0} h={100} w={30} onClick={toggle}>
          {opened ? <FaChevronLeft /> : <FaChevronRight />}
        </Button>
      </Stack>

      <Drawer opened={opened} onClose={toggle}>
        <Stack h={"100%"} justify="space-between">
          <Tree expandOnClick={false} renderNode={Leaf} data={headerLinks} />

          <Group justify="center">
            <ButtonGroupLanguages
              onClick={(key) =>
                dispatcher(
                  translationActions.changeLanguage({
                    language: key,
                  })
                )
              }
            />
          </Group>
        </Stack>
      </Drawer>
    </Fragment>
  );
};
