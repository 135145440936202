import React, { useEffect, useState } from 'react'
import { useSearchCustomer } from './useSearchCustomer';
import { useThrottledCallback } from '@mantine/hooks';
import { GetCustomersResponse } from '../../../Services/Customers/customerContracts';

export const useThrottledSearchCustomer = () : [
  (val: string) => void,
  boolean,
  GetCustomersResponse?
] => {
  const [searchText, setSearchText] = useState<string>('')
  const [search, isLoading, response] = useSearchCustomer();

  const throttledSearchText = useThrottledCallback(
    (val) => setSearchText(val),
    1000
  );

  useEffect(() => {
    if (searchText.length >= 3) {
      search({ search: searchText });
    }

  }, [searchText]);

  return [throttledSearchText, isLoading, response]
}