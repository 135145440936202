import {
  ClientInteractionDto,
  ViewerInteractionDto,
} from "../Application/contracts";
import { ErrorDto } from "../Commons/commonContracts";
import { ImageDto } from "../ImageProvider/Core/contacts";


// get all interactions
export type GetAllInteractionsRequest = {
  customerEmail: string;
  userToken?: string;
  customerToken?: string;
};

export type GetAllInteractionsResponse = {
  viewerInteractions?: ViewerInteractionDto[];
  errors?: ErrorDto[];
};

// add card interactions
export type AddCardInteractionsRequest = {
  cardId: number;
  companyName: string;
  viewerInteractions: ClientInteractionDto[];
};

export type AddCardInteractionsResponse = {
  errors?: ErrorDto[];
};

// delete card
export type DeleteCardRequest = {
  userToken: string;
  id: number;
};

export type DeleteCardResponse = {
  errors?: ErrorDto[];
};

// view card
export type ViewCardRequest = {
  companyName: string;
  cardName: string;
};

// view card
export type ViewCardResponse = {
  card?: CardDto;
  errors?: ErrorDto[];
};

// available card payment tiers
export type AvailableCardPaymentRequest = {
  coupon?: string;
};

export type AvailableCardPaymentResponse = {
  errors?: ErrorDto[];
  paymentTiers?: CardPaymentTierDto[];
};

export type SearchCustomerRequest = {
  customerEmail: string,
}

export type SearchCustomerResponse = {
  errors: ErrorDto[],
  customers: CustomerDto[]
}

export type SearchCustomersCardsRequest = {
  search: string,
  userToken: string
}

// example cards
export type ExampleCardsRequest = {};


export type GetCustomersResponse = {
  errors?: ErrorDto[];
  customers?: CustomerDto[];
};

export type GetExampleCardsResponse = {
  errors?: ErrorDto[];
  cards?: CardDto[];
};

// get customer by email
export type GetCustomerByEmailRequest = {
  email: string;
  userToken?: string;
  customerToken?: string;
};

export type GetCustomerResponse = {
  errors?: ErrorDto[];
  customer?: CustomerDto;
};

// get customer card
export type GetCustomerCardRequest = {
  email: string;
  cardId: number;
  customerToken?: string;
  userToken?: string;
};

export type GetCustomerCardResponse = {
  errors?: ErrorDto[];
  card?: CardDto;
};

// register customer
export type RegisterCustomerRequest = {
  name: string;
  email: string;
  password: string;
  phoneNumber: string;
  companyName: string;
  userToken: string;
};

export type RegisterCustomerResponse = {
  token?: string;
  customer?: CustomerDto;
  errors?: ErrorDto[];
};

// post new card
export type PublishNewCardRequest = {
  token: string;
  email: string;
  name: NameDto;
  template: TemplateDto;
  metadata: MetadataDto;
  colors: ColorsDto;
  profile: string;
  logo?: string;
  company: string[];
  gallery: string[];
  socialLinks: SocialLinksDto;
  informationSections: InformativeCardsDto;
  questionsAndAnswers: InformativeCardsDto;
  recommendations: RecommendationDto[];
};

export type PublishNewCardResponse = {
  card?: CardDto;
  errors?: ErrorDto[];
};

// update card
export type UpdateCardRequest = {
  userToken?: string;
  customerToken?: string;
  email: string;
  id: number;
  name: NameDto;
  template: TemplateDto;
  metadata: MetadataDto;
  colors: ColorsDto;
  profile: string;
  logo?: string;
  company: string[];
  gallery: string[];
  socialLinks: SocialLinksDto;
  informationSections: InformativeCardsDto;
  questionsAndAnswers: InformativeCardsDto;
  recommendations: RecommendationDto[];
};

export type UpdateCardResponse = {
  card?: CardDto;
  errors?: ErrorDto[];
};

// login customer
export type LoginCustomerRequest = {
  email: string;
  password: string;
  rememberMe: boolean;
};

export type LoginCustomerResponse = {
  errors?: ErrorDto[];
  token?: string;
  customer?: CustomerDto;
  loginAttemptTimeInUtc?: string;
  rememberMe?: boolean;
};

// change payment tier
export type ChangePaymentTierRequest = {
  email: string;
  paymentTier: number;
};

export type ChangePaymentTierResponse = {
  errors?: ErrorDto[];
};

// give contact information
export type GiveContactInformationRequest = {
  name: string;
  phoneNumber: string;
  companyName: string;
  cardId: number;
};

export type GiveContactInformationResponse = {
  errors?: ErrorDto[];
};

// dtos

export type CardPaymentTierDto = {
  name: string;
  value: PaymentTierTypes;
  priceInShekels: number;
  changesAllowed: number;
  unlimitedChanges: boolean;
  cardsAllowed: number
};

export enum PaymentTierTypes {
  Undefined = 0,
  Free = 1,
  Standard = 2,
  StandardPlus = 3,
  Premium = 4,
  Deluxe = 5,
}

export type MetadataDto = {
  title: string;
  about: string;
};

export type SocialLinksDto = {
  email?: string;
  web?: string;
  facebook?: string;
  instagram?: string;
  phone?: string;
  whatsapp?: string;
};

export type InformationSectionDto = {
  title: string;
  text: string;
};

export type ContactDto = {
  phoneNumber: string;
  name: string;
  createdTimeInUtc: string;
  sellingStage: number;
};

export type CardDto = {
  id: number;
  name: NameDto;
  template: TemplateDto;
  customer: string;
  viewers: number;
  colors: ColorsDto;
  metadata: MetadataDto;
  profile: { id: string };
  logo?: { id: string };
  gallery?: { id: string }[];
  company: { id: string }[];
  socialLinks: SocialLinksDto;
  recommendations: RecommendationDto[];
  informationCards?: InformativeCardsDto;
  questionsAndAnswersCards?: InformativeCardsDto;
  contacts: ContactDto[];
};

export type RecommendationDto = {
  name?: string;
  companyName: string;
  image?: string;
  comment?: string;
  starsOutOfTen: number;
};

export type TemplateDto = {
  type: number;
  leftToRight: boolean,
  darkOrLight: boolean
};

export type InformativeCardsDto = {
  variant: number,
  sections: InformationSectionDto[]
}

export type NameDto = {
  name: string;
};

export type ColorsDto = {
  primary: string;
  secondary: string;
  accent?: string;
};

export type CustomerDto = {
  name: string;
  phoneNumber: string;
  email: string;
  companyName: string;
  cards: CardDto[];
  cardChangesMade: number;
  paymentTier: CardPaymentTierDto;
};
