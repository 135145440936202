import {
  Button,
  Combobox,
  Group,
  Input,
  InputBase,
  Stack,
  Text,
  TextInput,
  useCombobox,
} from "@mantine/core";
import { ReactNode, useEffect, useState } from "react";
import { useSearchCustomerCards } from "../../../Hooks/Sonso/CustomerHooks/useSearchCustomerCards";
import { useThrottledCallback } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import {
  CardDto,
  CustomerDto,
} from "../../../Services/Customers/customerContracts";

type SubmitValue = {
  card: CardDto;
  customer: CustomerDto;
};

type Props = {
  onCardSelected?: ({ customer, card }: SubmitValue) => void;
  selectedCard?: SubmitValue;
};

export const SearchCustomersCards = ({
  selectedCard,
  onCardSelected,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });
  const [searchText, setSearchText] = useState("");
  const [search, isLoading, response] = useSearchCustomerCards();

  const throttledSearchText = useThrottledCallback(
    (val) => setSearchText(val),
    1000
  );

  useEffect(() => {
    if (searchText.length >= 3) {
      search({ search: searchText });
      combobox.openDropdown();
    } else combobox.closeDropdown();
  }, [searchText]);

  const innerCardSelected = (val: string) => {
    combobox.closeDropdown();
    let customer;
    let card;

    response?.customers?.forEach((c) => {
      if ((card = c.cards.find((c) => c.id.toString() === val))) customer = c;
    });

    if (customer && card)
      onCardSelected && onCardSelected({ customer: customer, card: card });
  };

  let data : ReactNode[] = [];

  if (response?.customers)
    data = response.customers
      .flatMap((c) => c.cards)
      .map((card) => (
        <Combobox.Option value={card.id.toString()} key={card.id}>
          {card.name.name}
        </Combobox.Option>
      ));

  if (isLoading)
    data = [<Combobox.Empty key={"loading"}>{t("loading")}</Combobox.Empty>];
  if (response?.errors)
    data = [<Combobox.Empty key={"errors"}>{t("errors")}</Combobox.Empty>];

  return (
    <Stack>
      <Text fs={"italic"} fz={"xs"} c={"gray"}>
        {t("write three or more characters to start searching")}
      </Text>

      <Group>
        <TextInput
          onChange={(input) => throttledSearchText(input.target.value)}
        />
        <Combobox
          width={250}
          store={combobox}
          onOptionSubmit={innerCardSelected}
        >
          <Combobox.Target>
            <InputBase
              component="button"
              type="button"
              pointer
              rightSection={<Combobox.Chevron />}
              rightSectionPointerEvents="none"
              onClick={() => combobox.toggleDropdown()}
            >
              {selectedCard?.card.name.name || (
                <Input.Placeholder>{t("select")}</Input.Placeholder>
              )}
            </InputBase>
          </Combobox.Target>
          <Combobox.Dropdown>
            <Combobox.Options>
              {data.length > 0 ? (
                data
              ) : (
                <Combobox.Empty key={"not found"}>
                  {t("not found")}
                </Combobox.Empty>
              )}
            </Combobox.Options>
          </Combobox.Dropdown>
        </Combobox>
      </Group>
    </Stack>
  );
};
