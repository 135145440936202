import { Button, ButtonGroup } from "@mantine/core";
import React from "react";
import { supportedLanguages } from "../../../Translations/Core/supportedLanguages";

type Props = { onClick?: (clicked: keyof typeof supportedLanguages) => void };

export const ButtonGroupLanguages = (props: Props) => {
  return (
    <ButtonGroup>
      {Object.keys(supportedLanguages).map((key) => (
        <Button
          key={key}
          onClick={() => {
            props.onClick &&
              props.onClick(key as keyof typeof supportedLanguages);
          }}
        >
          {supportedLanguages[key].name}
        </Button>
      ))}
    </ButtonGroup>
  );
};
