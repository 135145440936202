import {
  ImageProvider,
} from "../Core/contacts";
import { UNDEFINED_ERROR_RESPONSE } from "../../Commons/commonResponses";
import {
  api,
  CUSTOMER_SCHEME_NAME,
  USER_SCHEME_NAME,
} from "../../Rest/sonsoAxios";

const API_BASE = process.env.REACT_APP_SO_N_SO_ENDPOINT;

const GET_IMAGES_QUERY = "/api/Images/by-guid/";
const GET_OWNER_IMAGES_QUERY = "/api/Images/get-images-by-owner/";
const UPLOAD_IMAGE_QUERY = "/api/Images/upload/";

export const sonsoImageProvider: ImageProvider = {
  getImageAsync: (request) => {
    return Promise.resolve({
      uri: `${API_BASE}${GET_IMAGES_QUERY}${request.imageName}`,
    });
  },
  getImage: (request) => {
    return {
      uri: `${API_BASE}${GET_IMAGES_QUERY}${request.imageName}`,
    };
  },
  getImagesByOwner: async (request) => {
    try {
      var data = await api.get(GET_OWNER_IMAGES_QUERY, {
        params: request,
        headers: {
          [USER_SCHEME_NAME]: request.userToken,
          [CUSTOMER_SCHEME_NAME]: request.customerToken,
        },
      });

      return await data.data;
    } catch (error) {
      console.log(error);
      return UNDEFINED_ERROR_RESPONSE;
    }
  },
  uploadImage: async (request, onUploadProgress) => {
    try {
      const formData = new FormData();
      formData.append("image", request.image);
      formData.append("owner", request.owner);
      request.alt && formData.append("alt", request.alt);

      var response = await api.post(UPLOAD_IMAGE_QUERY, formData, {
        headers: {
          [USER_SCHEME_NAME]: request.userToken,
          [CUSTOMER_SCHEME_NAME]: request.customerToken,
        },
        onUploadProgress: (event) => {
          if (!event.total || !onUploadProgress) return;

          onUploadProgress(Math.round((event.loaded * 100) / event.total));
        },
      });

      return await response.data;
    } catch (error) {
      return UNDEFINED_ERROR_RESPONSE;
    }
  },
};
