import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";

export const useCustomerSelector = () => {
  const customer = useSelector((state: RootState) => state.customerSlice);
  const sessionCustomer = useSelector(
    (state: RootState) => state.sessionCustomerSlice
  );

  return sessionCustomer.token ? sessionCustomer : customer
};
