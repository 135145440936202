import { useState } from "react";
import {
  RegisterCustomerRequest,
  RegisterCustomerResponse,
} from "../../../Services/Customers/customerContracts";
import { registerCustomer as rest } from "../../../Services/Customers/customerRest";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { notifier } from "../../../Utilities/notifier";
import { NOT_LOGGED_IN_ERROR_RESPONSE } from "../../../Services/Commons/commonResponses";

export const useRegisterCustomer = (): [
  (request: Omit<RegisterCustomerRequest, "userToken">) => Promise<void>,
  boolean,
  RegisterCustomerResponse?
] => {
  const user = useSelector((state: RootState) => state.userSlice);
  const [response, setResponse] = useState<RegisterCustomerResponse>();
  const [loading, setLoading] = useState<boolean>(false);

  const registerCustomer = async (
    request: Omit<RegisterCustomerRequest, "userToken">
  ) => {
    setResponse(undefined);
    setLoading(true);

    if (!user.token) {
      notifier.notifyErrors(NOT_LOGGED_IN_ERROR_RESPONSE.errors);
      return;
    }

    const response = await rest({ ...request, userToken: user.token });

    if (response.errors)
      notifier.notifyErrors([{message: 'already exists'}]); // support response error
    else notifier.notifySuccess({ message: "success" });

    setResponse(response);
    setLoading(false);
  };

  return [registerCustomer, loading, response];
};
