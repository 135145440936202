import { Image, ImageProps, useMantineColorScheme } from "@mantine/core";
import logoLight from "../../Assets/logo-white-16.07.2024.svg";
import logoDark from "../../Assets/logo-16.07.2024.svg";

type Props = {
  theme?: "light" | "dark";
};

export const Logo = ({theme, ...imageProps}: Props & ImageProps) => {
  const scheme = useMantineColorScheme();
  
  if (theme === undefined)
    return <Image {...imageProps} src={scheme.colorScheme === "dark" ? logoDark : logoLight} />;

  return <Image {...imageProps} src={theme === "dark" ? logoDark : logoLight} />;
};
