import { useState } from "react";
import {
  LoginCustomerRequest,
  LoginCustomerResponse,
} from "../../../Services/Customers/customerContracts";
import { loginCustomer as rest } from "../../../Services/Customers/customerRest";
import { useDispatch } from "react-redux";
import {
  customerActions,
  sessionCustomerActions,
} from "../../../Redux/features/Customer/customerSlice";
import { notifier } from "../../../Utilities/notifier";
import { errors } from "../../../Utilities/errors";
import { useCustomerSelector } from "../../../Redux/Selectors/useCustomerSelector";

export const useLoginCustomer = (): [
  (request: LoginCustomerRequest) => Promise<void>,
  boolean,
  LoginCustomerResponse?
] => {
  const customer = useCustomerSelector();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<LoginCustomerResponse>();

  const loginCustomer = async (request: LoginCustomerRequest) => {
    if (customer.token) {
      notifier.notifySuccess({ message: "already logged in" });
      return;
    }

    setLoading(true);
    setResponse(undefined);

    const response = await rest(request);

    if (response.errors) {
      switch (response.errors[0].errorCode) {
        case errors.customer.login.UNAUTHORIZED.errorCode: // unauthorized
          notifier.notifyErrors([errors.customer.login.UNAUTHORIZED]);
          break;

        default:
          notifier.notifyErrors(
            response.errors.filter((e) => e.message) as any
          );
          break;
      }
    }

    if (response.token) notifier.notifySuccess({ message: "login successful" });

    if (request.rememberMe)
      dispatch(customerActions.loginCustomer({ ...response }));
    else 
      dispatch(sessionCustomerActions.loginCustomer({ ...response }));

    setLoading(false);
    setResponse(response);
  };

  return [loginCustomer, loading, response];
};
