import { SonsoApplicationContract } from "./contracts";
import { UNDEFINED_ERROR_RESPONSE } from "../Commons/commonResponses";
import { deepFreeze } from "../../Utilities/objects";
import { api, USER_SCHEME_NAME } from "../Rest/sonsoAxios";

const GET_APPLICATION_VIEWERS_QUERY = "/api/Application/get-viewers";
const GET_APPLICATION_RECOMMENDATIONS_QUERY =
  "/api/Application/get-all-recommendations";
const ADD_APPLICATION_RECOMMENDATION_QUERY =
  "/api/Application/get-all-recommendations";

export const sonsoApplicationRest: SonsoApplicationContract = {
  getViewers: async (request) => {
    try {
      return await (
        await api.get(GET_APPLICATION_VIEWERS_QUERY, {
          params: request,
          headers: {
            [USER_SCHEME_NAME]: request.userToken,
          },
        })
      ).data;
    } catch (error) {
      return UNDEFINED_ERROR_RESPONSE;
    }
  },
  addRecommendation: async (request) => {
    try {
      return await (
        await api.post(ADD_APPLICATION_RECOMMENDATION_QUERY, request, {
          headers: {
            [USER_SCHEME_NAME]: request.userToken,
          },
        })
      ).data;
    } catch (error) {
      return UNDEFINED_ERROR_RESPONSE;
    }
  },
  getAllRecommendations: async (request) => {
    try {
      return await (
        await api.get(GET_APPLICATION_RECOMMENDATIONS_QUERY, {
          params: request,
        })
      ).data;
    } catch (error) {
      return UNDEFINED_ERROR_RESPONSE;
    }
  },
};

export const ClientInteractionTypesEnum = deepFreeze({
  VIEW: 1,
  LINK_CLICKED: 2,
  QUESTIONS_AND_ANSWERS_SECTION_OPENED: 3,
  INFORMATIVE_SECTION_OPENED: 4,
});

export const ClientSellingStageEnum = deepFreeze({
  UNHANDLED: 1,
  IN_PROGRESS: 2,
  DONE: 3,
});
