import React, { ReactNode } from "react";
import {
  Box,
  Card,
  Container,
  Divider,
  Grid,
  GridCol,
  LoadingOverlay,
  Stack,
  Tabs,
  Title,
} from "@mantine/core";
import { StatisticsPanel } from "../../Molecules/Panels/StatisticsPanel";
import { ContactsPanel } from "../../Molecules/Panels/ContactsPanel";
import { PaymentTierBody } from "../../Molecules/Panels/PaymentTiers.tsx/PaymentTierBody";
import { CardAdministration } from "../../Organizem/CardAdministration";
import { PotentialCustomerAdministration } from "../../Organizem/PotentialCustomerAdministration";
import { ViewerInteractionDto } from "../../../Services/Application/contracts";
import { CustomerDto } from "../../../Services/Customers/customerContracts";
import { ClientInteractionTypesEnum } from "../../../Services/Application/sonsoApplicationRest";
import { useTranslation } from "react-i18next";
import { PaymentTiersFactory } from "../../Molecules/Panels/PaymentTiers.tsx/PaymentTiersFactory";

const DIGITAL_CARDS_TAB = "digital cards";
const POTENTIAL_CUSTOMER_TAB = "potential customers";

type Props = {
  interactions: ViewerInteractionDto[] | undefined;
  interactionsIsLoading: boolean;
  customer: CustomerDto | undefined;
  customerIsLoading: boolean;
  potentialCustomerAdministration?: ReactNode
};

export const LaptopCustomerAdministration = ({
  customer,
  customerIsLoading,
  interactions,
  potentialCustomerAdministration,
  interactionsIsLoading,
}: Props) => {
  const { t } = useTranslation();
  const customerInteractions = customer?.cards
    .flatMap((c) => c.contacts)
    .map((c) => ({
      ...c,
      sellingStage: {
        name: c.name,
        color: "green",
        value: c.sellingStage,
      },
    }));

  return (
    <Container>
      <LoadingOverlay visible={customerIsLoading} />
      <Title mt={"xl"} order={2}>
        {t("customer administration")}
      </Title>

      <Divider />

      <Grid mt={"lg"}>
        {/* Sidebar */}
        <Grid.Col span={4}>
          <Stack gap={"lg"}>
            <StatisticsPanel
              viewersCount={
                interactions?.filter(
                  (v) => v.type.value === ClientInteractionTypesEnum.VIEW
                ).length
              }
              interactionsCount={
                interactions?.filter(
                  (v) => v.type.value !== ClientInteractionTypesEnum.VIEW
                ).length
              }
            />

            <PaymentTiersFactory paymentTier={customer?.paymentTier} />

          </Stack>
        </Grid.Col>

        <Grid.Col span={8}>
          {/* Body */}
          <Tabs defaultValue={DIGITAL_CARDS_TAB}>
            <Tabs.List>
              <Tabs.Tab value={DIGITAL_CARDS_TAB}>
                {t("digital cards")}
              </Tabs.Tab>
              <Tabs.Tab value={POTENTIAL_CUSTOMER_TAB}>
                {t("potential customers")}
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="digital cards">
              <CardAdministration
                mt={"lg"}
                isLoading={customerIsLoading}
                customer={customer}
                as="customer"
              />
            </Tabs.Panel>

            <Tabs.Panel value="potential customers">
              {potentialCustomerAdministration}
            </Tabs.Panel>
          </Tabs>
        </Grid.Col>
      </Grid>
    </Container>
  );
};
